import { numberWithCommas } from 'functions/helpers';

export const series = [{
  name: 'Amount',
  data: []
}]

export const options = {
  chart: {
    height: 300,
    type: 'bar',
    toolbar: {
      show: false
    }
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      dataLabels: {
        position: 'top', // top, center, bottom
      },
    }
  },
  colors: ['#EEF769'],
  dataLabels: {
    enabled: true,
    formatter: function (val) {
      return val;
    },
    offsetY: -20,
    style: {
      fontSize: '12px',
      colors: ["#fff"]
    }
  },
  xaxis: {
    labels: {
      show: true,
      style: {
        colors: "#7F7F94",
        fontSize: '12px'
      },
    },
    categories: [''],
    position: 'bottom',
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false
    },
    crosshairs: {
      fill: {
        type: 'gradient',
        gradient: {
          colorFrom: '#fff',
          colorTo: '#fff',
          stops: [0, 100],
          opacityFrom: 0.4,
          opacityTo: 0.5,
        }
      }
    }
  },
  yaxis: {
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false,
    },
    show: true,
    labels: {
      formatter: function(val) {
        return numberWithCommas(val.toFixed(0))
      },
      // show: true,
      style: {
        colors: "#7F7F94",
        fontSize: '12px'
      }
    },
  }
}