import { Switch } from 'antd';
import React from 'react'
import s from './styles.module.css'

interface IProps {
    checked:boolean,
    onChange(checked: boolean): void
}

const Switcher: React.FC<IProps> = ({checked,onChange}) =>{
    return <Switch className={s.container} checked={checked} onChange={(checked,e)=>onChange(checked)}/>
}

export default Switcher;
