import React from 'react';
import * as Antd from 'antd';
import { ModalProps } from 'antd/lib/modal';

import LedgerDerivationPathModal from 'components/ledger-deriviation-path-modal';

import s from './styles.module.css';

export type DestinationChaineModalProps = ModalProps & {
	chains: any;
	setChainArrays: Function;
};

const DestinationChaineModal: React.FunctionComponent<DestinationChaineModalProps> = (
	props
) => {
	const { chains, setChainArrays, ...modalProps } = props;

	const [ledgerModal, setLedgerModal] = React.useState<boolean>(false);

	return (
		<>
			<Antd.Modal
				className={s.component}
				centered
				closable
				footer={[]}
				{...modalProps}>
				<div className={s.headerLabel}>Select Chain</div>
				<div className={s.headerNote}>Please select available chain</div>
				<div className={s.connectorList}>
					{chains!.map(
						(chain: any) =>
							!chain.current && (
								<Antd.Button
									key={chain.id}
									type='ghost'
									disabled={
										chain.selected ||
										chain.label === 'Polygon'
									}
									className={s.connectorBtn}
									onClick={() => setChainArrays(chain.id)}>
									{chain.icon}
									<span className={s.connectorName}>{chain.label}</span>
								</Antd.Button>
							)
					)}
				</div>
			</Antd.Modal>
			<LedgerDerivationPathModal
				visible={ledgerModal}
				onCancel={() => setLedgerModal(false)}
			/>
		</>
	);
};

export default DestinationChaineModal;
