import React from 'react';
import cx from 'classnames';
import BigNumber from 'bignumber.js';
import { numberWithCommas } from 'functions/helpers';

import { SWAPPTokenMeta } from 'web3/contracts/swapp';
import InfoTooltip from 'components/info-tooltip';

import k from './styles.module.css';
import s from '../../../pools/components/pool-token-row/styles.module.css';

interface BridgeInfoType {
	extraFee: string;
	dayLimit: string;
	minAmount: string;
	maxAmount: string;
	networkId: number;
	ethGasValues: any;
	usedDayLimit: string;
	currentFeeAmount: number;
	maxAvailableAmount: number;
	amount: BigNumber | undefined;
}

const BridgeInfo: React.FunctionComponent<BridgeInfoType> = ({
	amount,
	extraFee,
	dayLimit,
	networkId,
	minAmount,
	maxAmount,
	ethGasValues,
	usedDayLimit,
	currentFeeAmount,
	maxAvailableAmount,
}) => {
	const networkChecker =
		+ethGasValues.standard! > +process.env.REACT_APP_STOP_GAS_PRICE! &&
		networkId === 1;

	const mainConditionRender = () => {
		if (
			amount &&
			+amount >= +minAmount! &&
			+amount <= +maxAmount! &&
			+amount <= maxAvailableAmount &&
			!networkChecker
		) {
			return (
				<>
					<li>
						<div className={cx(k.fee, k.percentCount)}>
							<span className={k.title}>Bridge fee </span>
							<div className={k.amount}>
								<span className={k.icon}>{SWAPPTokenMeta.icon}</span>
								{numberWithCommas(
									(currentFeeAmount + (extraFee ? +extraFee : 0)).toFixed(0)
								)}
							</div>
							<InfoTooltip
								className={s.tooltip}
								title={
									<ul>
										<li>Fee for bridge transaction</li>
										{networkId === 1 && (
											<li>
												If gas price beetween{' '}
												{process.env.REACT_APP_CRITICAL_GAS_PRICE} -{' '}
												{process.env.REACT_APP_STOP_GAS_PRICE} You will be
												charged extra fee -{' '}
												{process.env.REACT_APP_EXTRA_FEE_GAS_PRICE}
											</li>
										)}
									</ul>
								}
							/>
						</div>
					</li>
					<li>
						<div className={cx(k.fee, k.main)}>
							<span className={k.title}>You will get </span>
							<div className={k.amount}>
								<span className={k.icon}>{SWAPPTokenMeta.icon}</span>
								{numberWithCommas(
									(
										+amount! -
										currentFeeAmount -
										(extraFee ? +extraFee : 0)
									).toFixed(0)
								)}
							</div>
							<InfoTooltip className={s.tooltip} title={'You will get'} />
						</div>
					</li>
				</>
			);
		}
		return null;
	};

	return (
		<div className={cx(k.col, k.info)}>
			<ul className={k.feeList}>
				<li>
					<div className={k.fee}>
						<span className={k.title}>Minimum per trx </span>
						<div className={k.amount}>
							<span className={k.icon}>{SWAPPTokenMeta.icon}</span>
							{numberWithCommas(minAmount)}
						</div>
						<InfoTooltip
							className={s.tooltip}
							title={'Minimum allowed amount per transaction'}
						/>
					</div>
				</li>
				<li>
					<div className={k.fee}>
						<span className={k.title}>Maximum per trx </span>
						<div className={k.amount}>
							<span className={k.icon}>{SWAPPTokenMeta.icon}</span>
							{numberWithCommas(+maxAmount!)}
						</div>
						<InfoTooltip
							className={s.tooltip}
							title={'Maximum allowed amount per transaction'}
						/>
					</div>
				</li>
				<li>
					<div className={k.fee}>
						<span className={k.title}>Daily limit </span>
						<div className={k.amount}>
							<span className={k.icon}>{SWAPPTokenMeta.icon}</span>
							{numberWithCommas(+dayLimit!)}
						</div>
						<InfoTooltip
							className={s.tooltip}
							title={'Daily limit per network'}
						/>
					</div>
				</li>
				<li>
					<div className={k.fee}>
						<span className={k.title}>Used daily limit </span>
						<div className={k.amount}>
							<span className={k.icon}>{SWAPPTokenMeta.icon}</span>
							{numberWithCommas(+usedDayLimit!)}
						</div>
						<InfoTooltip
							className={s.tooltip}
							title={'Already used limit by all users per network'}
						/>
					</div>
				</li>
				<li>
					<div className={k.fee}>
						<span className={k.title}>Maximum allowed amount </span>
						<div className={k.amount}>
							<span className={k.icon}>{SWAPPTokenMeta.icon}</span>
							{numberWithCommas(maxAvailableAmount)}
						</div>
						<InfoTooltip
							className={s.tooltip}
							title={'Maximum allowed amount to transfer right now'}
						/>
					</div>
				</li>
				{mainConditionRender()}
				{amount && +amount > +maxAmount! && +amount < maxAvailableAmount && (
					<li>
						<div className={cx(k.fee, k.percentCount)}>
							<span className={k.title}>
								Amount exceeds the available maximum
							</span>
						</div>
						<InfoTooltip
							className={s.tooltip}
							title={'Amount exceeds the available maximum'}
						/>
					</li>
				)}
				{amount && +amount > +maxAmount && (
					<li>
						<div className={cx(k.fee, k.percentCount)}>
							<span className={cx(k.title, k.wide)}>
								Overflow of maximum amount per trx{' '}
							</span>
							<InfoTooltip
								className={s.tooltip}
								title={'Overflow of maximum amount per trx'}
							/>
						</div>
					</li>
				)}
				{amount && +amount > +maxAvailableAmount && (
					<li>
						<div className={cx(k.fee, k.percentCount)}>
							<span className={cx(k.title, k.wide)}>
								Overflow of maximum amount per day
							</span>
							<InfoTooltip
								className={s.tooltip}
								title={'Daily limit already exceeded'}
							/>
						</div>
					</li>
				)}
				{networkChecker && (
					<li>
						<div className={cx(k.fee, k.percentCount)}>
							<span className={k.title}>Gas price is too high </span>
						</div>
					</li>
				)}
			</ul>
		</div>
	);
};

export default BridgeInfo;
