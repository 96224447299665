import React from 'react';
// import * as Antd from 'antd';
//
// import InfoTooltip from 'components/info-tooltip';

import s from './styles.module.css';

export type StatWidgetProps = {
  label: string;
  value?: string;
  hint1?: React.ReactNode | string;
  hint2?: React.ReactNode | string;
  help?: React.ReactNode;
  widget?: any;
};

const StatisticWidget: React.FunctionComponent<StatWidgetProps> = props => {
  return (
    <div className={s.component}>
        <div className={s.body}>
            <div className={s.header}>
                <div className={s.label}>{props.label}</div>
            </div>
            {props.value && (
                <div className={s.value} text-elipsis="true">{props.value}</div>
            )}
            {(props.hint1 || props.hint2)  && (
                <div className={s.hint} text-elipsis="true">
                    {props.hint1}
                    {props.hint2 && <br />}
                    {props.hint2 && props.hint2}
                </div>
            )}
        </div>
        <div className={s.widget}>
            {props.widget}
        </div>
    </div>
  );
};

export default StatisticWidget;
