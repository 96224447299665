import React, { useState } from 'react';
import * as Antd from 'antd';
import cx from 'classnames';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';
import { useWeb3Contracts } from 'web3/contracts';
import { useHistory } from 'react-router';

import ExternalLink from 'components/externalLink';

import { useWallet } from 'wallets/wallet';
import { getEtherscanAddressUrl, shortenAddr } from 'web3/utils';
import { formatSWAPPValue } from 'web3/utils';
import { useAsyncEffect } from 'hooks/useAsyncEffect';
import { getHumanValue } from 'web3/utils';
import { getWSRpcUrl, getHttpsRpcUrl } from 'web3/utils';
import { SWAPPTokenMeta } from 'web3/contracts/swapp';
import { WBTCTokenMeta } from 'web3/contracts/wbtc';

import { ReactComponent as ChevronTopSvg } from 'resources/svg/icons/chevron-top.svg';
import { ReactComponent as BellSvg } from 'resources/svg/wallets/Notification.svg';
import { ReactComponent as RadioSvg } from 'resources/svg/icons/radio.svg';
import { ReactComponent as PolygonArrowSvg } from 'resources/svg/icons/Polygon2.svg';
import { ReactComponent as CreditCardSvg } from 'resources/svg/icons/credit-card.svg';
import { ReactComponent as GlobekSvg } from 'resources/svg/icons/globe.svg';
import { ReactComponent as EtherumSvg } from 'resources/svg/icons/ethereum.svg';
import { ReactComponent as BinanceSvg } from 'resources/svg/icons/binance.svg';
import { ReactComponent as CronosSvg } from 'resources/svg/icons/cronos.svg';
import { ReactComponent as PolygonSvg } from 'resources/svg/icons/polygon.svg';
import { ReactComponent as SwappLogoSvg } from 'resources/svg/icons/swapp-logo.svg';
import { ReactComponent as WalletLogoSvg } from 'resources/svg/wallets/Wallet.svg';
import { ReactComponent as ArrowSvg } from 'resources/svg/wallets/Arrow - Down 2.svg';
import { ReactComponent as UserSvg } from 'resources/svg/icons/user.svg';

import { chainArrays } from '../../constants';

import s from './styles.module.css';

export type ConnectedWalletProps = {
  setShowLinkModal: Function;
  hideHeader: boolean;
};

declare let window: any;

const ConnectedWallet: React.FunctionComponent<ConnectedWalletProps> = ({
  setShowLinkModal,
  hideHeader
}) => {
  const history = useHistory();
  const { swapp } = useWeb3Contracts();
  const wallet = useWallet();

  const [ethBalance, setEthBalance] = useState(0);
  const [chains, setChainArrays] = useState([...chainArrays]);

  let web3 = new Web3(new Web3.providers.WebsocketProvider(getWSRpcUrl()));

  useAsyncEffect(async () => {
    web3 = new Web3(
      new Web3.providers.HttpProvider(getHttpsRpcUrl(wallet.networkId))
    );

    if (wallet.account) {
      try {
        const balance = getHumanValue(
          new BigNumber(await web3.eth.getBalance(wallet.account)),
          18
        );
        if (balance) {
          setEthBalance(parseFloat(balance.toString()));
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, [wallet.networkId, wallet.provider, wallet.account]);

  function handleWalletConnect() {
    wallet.showWalletsModal();
  }

  function handleWalletDisconnect() {
    wallet.disconnect();
  }

  const addNewChain = (chain: any) => {
    const params = [
      {
        chainId: chain.chainIdHex,
        chainName: chain.label,
        nativeCurrency: {
          name: chain.symbol,
          symbol: chain.symbol,
          decimals: chain.decimals
        },
        rpcUrls: [chain.rpcUrls],
        blockExplorerUrls: [chain.blockExplorerUrls]
      }
    ];

    window.ethereum
      .request({ method: 'wallet_addEthereumChain', params })
      .then(() => console.log('Success'))
      .catch((error: Error) => console.log('Error', error.message));
  };

  if (wallet.connecting) {
    return (
      <div className={s.component}>
        <Antd.Popover
          overlayClassName={s.overlay}
          placement="bottomRight"
          content={
            <div>
              <div className={s.stats}>
                <Antd.Row className={s.statRow}>
                  <Antd.Col>
                    <RadioSvg className={s.statIcon} />
                  </Antd.Col>
                  <Antd.Col>
                    <span className={s.statName}>Status</span>
                  </Antd.Col>
                  <Antd.Col>
                    <Antd.Tag className={s.statTag}>Connecting...</Antd.Tag>
                  </Antd.Col>
                </Antd.Row>
                <Antd.Row className={s.statRow}>
                  <Antd.Col>
                    <CreditCardSvg className={s.statIcon} />
                  </Antd.Col>
                  <Antd.Col>
                    <span className={s.statName}>Wallet</span>
                  </Antd.Col>
                  <Antd.Col>
                    <span className={s.statValue}>
                      {wallet.connecting?.name}
                    </span>
                  </Antd.Col>
                </Antd.Row>
              </div>
              <Antd.Row className={s.disconnectBtnRow}>
                <Antd.Button
                  type="ghost"
                  className={s.disconnectBtn}
                  onClick={handleWalletDisconnect}
                >
                  Disconnect
                </Antd.Button>
              </Antd.Row>
            </div>
          }
          trigger="click"
        >
          <Antd.Badge
            className={cx(s.notificationBtn, s.hidden)}
            dot
            count={0}
            showZero={false}
          >
            <BellSvg />
          </Antd.Badge>
          <Antd.Button type="primary" size="large" className={s.connectBtn}>
            Connecting...
          </Antd.Button>
        </Antd.Popover>
      </div>
    );
  }

  if (!wallet.isActive) {
    return (
      <div className={s.component}>
        <Antd.Badge
          className={cx(s.notificationBtn, s.hidden)}
          dot
          count={0}
          showZero={false}
        >
          <BellSvg />
        </Antd.Badge>
        <Antd.Button
          type="primary"
          size="large"
          className={s.connectBtn}
          onClick={handleWalletConnect}
        >
          Connect Wallet
        </Antd.Button>
      </div>
    );
  }

  const getChainIcon = () => {
    switch (wallet.networkId) {
      case 1:
      case 3: {
        return <EtherumSvg className={s.walletIconCoin} />;
      }
      case 25: {
        return <CronosSvg className={s.walletIconCoin} />;
      }
      case 56:
      case 97: {
        return <BinanceSvg className={s.walletIconCoin} />;
      }
      case 137:
      case 80001: {
        return <PolygonSvg className={s.walletIconCoin} />;
      }
      case 338: {
        return <CronosSvg className={s.walletIconCoin} />;
      }
    }
  };

  return (
    <div className={s.component}>
      <div className={s.walletBox}>
        <Antd.Popover
          overlayClassName={s.overlay}
          placement="bottomRight"
          content={
            <div>
              <Antd.Row className={s.notificationHeader}>
                Notifications
              </Antd.Row>
              <Antd.Row className={s.notificationBody}>
                <div className={s.notificationZero}>
                  <span>There are no notifications to show</span>
                </div>
              </Antd.Row>
            </div>
          }
          trigger="click"
        >
          {!hideHeader && (
            <Antd.Badge
              className={s.notificationBtn}
              dot
              count={0}
              showZero={false}
            >
              <BellSvg />
            </Antd.Badge>
          )}
        </Antd.Popover>
        {/* <div className={s.divider} /> */}
        <Antd.Popover
          placement="bottomRight"
          content={
            <div className={s.margin}>
              <>
                <div className={s.overlay}>
                  <div className={s.walletHeader}>
                    <Antd.Row className={s.statRow}>
                      <Antd.Row className={s.inerRow}>
                        <Antd.Col className={s.statCol}>
                          {getChainIcon()}
                        </Antd.Col>
                        <Antd.Col className={s.statCol}>
                          <span className={s.etherumCoin}>
                            &nbsp;{ethBalance.toFixed(3)}
                          </span>
                        </Antd.Col>
                      </Antd.Row>
                      {/* <Antd.Col className={s.swappCoinCol}>
                      <div className={s.divider} />
                    </Antd.Col> */}
                      <Antd.Row className={s.inerRow}>
                        <Antd.Col className={s.swappCoinCol}>
                          <SwappLogoSvg className={s.walletIcon} />
                        </Antd.Col>
                        <Antd.Col className={s.statCol}>
                          <span className={s.swappCoin}>
                            <ExternalLink
                              href={getEtherscanAddressUrl(
                                wallet.account ?? ''
                              )}
                              className={s.walletPreviewHash}
                            >
                              {swapp?.balance
                                ? formatSWAPPValue(swapp?.balance!)
                                : '0'}
                            </ExternalLink>
                          </span>
                        </Antd.Col>
                      </Antd.Row>
                    </Antd.Row>
                  </div>
                </div>
              </>
              <div className={s.overlay}>
                <div className={s.walletStatus}>
                  <Antd.Row className={s.statRow}>
                    <Antd.Col>
                      <RadioSvg className={s.statIcon} />
                    </Antd.Col>
                    <Antd.Col>
                      <span className={s.statName}>Status</span>
                    </Antd.Col>
                    <Antd.Col>
                      <span className={s.statTag}>Connected</span>
                    </Antd.Col>
                  </Antd.Row>
                  <Antd.Row className={s.statRow}>
                    <Antd.Col>
                      <CreditCardSvg className={s.statIcon} />
                    </Antd.Col>
                    <Antd.Col>
                      <span className={s.statName}>Wallet</span>
                    </Antd.Col>
                    <Antd.Col>
                      <span className={s.statValue}>
                        {wallet.connector?.name}
                      </span>
                    </Antd.Col>
                  </Antd.Row>
                  <Antd.Row className={s.statRow}>
                    <Antd.Col>
                      <GlobekSvg className={s.statIcon} />
                    </Antd.Col>
                    <Antd.Col>
                      <span className={s.statName}>Network</span>
                    </Antd.Col>
                    <Antd.Col>
                      <span className={s.statValue}>{wallet.networkName}</span>
                    </Antd.Col>
                  </Antd.Row>
                </div>
              </div>
              <div className={s.overlay}>
                <div className={s.walletNavegation}>
                  <Antd.Row className={s.statRow}>
                    <Antd.Col>
                      <ExternalLink
                        href={`https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=${SWAPPTokenMeta.address}&use=V2`}
                      >
                        Buy Swapp Token
                      </ExternalLink>
                    </Antd.Col>
                    <Antd.Col className={s.iconCol}>
                      <PolygonArrowSvg className={s.poligonIcon} />
                    </Antd.Col>
                  </Antd.Row>
                  <Antd.Row className={s.statRow}>
                    <Antd.Col>
                      <span
                        className={s.navegationName}
                        onClick={() =>
                          setShowLinkModal && setShowLinkModal(true)
                        }
                      >
                        Create Referral Link
                      </span>
                    </Antd.Col>
                    <Antd.Col className={s.iconCol}>
                      <PolygonArrowSvg className={s.poligonIcon} />
                    </Antd.Col>
                  </Antd.Row>
                  <Antd.Row className={`${s.statRow} ${s.disable}`}>
                    <Antd.Col>
                      <span
                        className={s.navegationName}
                        onClick={() => history.push('/pools/swapp')}
                      >
                        Stake Swapp Tokens
                      </span>
                    </Antd.Col>
                    <Antd.Col className={s.iconCol}>
                      <PolygonArrowSvg className={s.poligonIcon} />
                    </Antd.Col>
                  </Antd.Row>
                </div>
                <Antd.Row className={s.disconnectBtnRow}>
                  <Antd.Button
                    type="ghost"
                    className={s.disconnectBtn}
                    onClick={handleWalletDisconnect}
                  >
                    Disconnect
                  </Antd.Button>
                </Antd.Row>
              </div>
              <div className={s.overlay}>
                <Antd.Row className={s.disconnectBtnRow}>
                  <>
                    <span className={s.navegationName}>
                      Add Networks to MetaMask:
                    </span>
                    <div className={s.connectorList}>
                      {chains!.map(
                        (chain: any, index: number) =>
                          index > 0 && (
                            <Antd.Button
                              key={chain.id}
                              type="ghost"
                              className={s.connectorBtn}
                              onClick={() => addNewChain(chain)}
                            >
                              {chain.icon}
                              <span className={s.connectorName}>
                                {chain.label}
                              </span>
                            </Antd.Button>
                          )
                      )}
                    </div>
                  </>
                </Antd.Row>
              </div>
            </div>
          }
          trigger="click"
        >
          <Antd.Row className={s.walletPreview}>
            {/* <Antd.Col>
              <Identicon address={wallet.account} className={s.walletPreviewAvatar} />
            </Antd.Col> */}
              <WalletLogoSvg />
              <span className={s.walletPreviewHashOnBtn}>
                {shortenAddr(wallet.account!, 2, 4)}
              </span>
              <ArrowSvg className={s.walletPreviewArrow} />

          </Antd.Row>
        </Antd.Popover>
      </div>
    </div>
  );
};

export default ConnectedWallet;
