import React from 'react';
import { formatDuration, intervalToDuration } from 'date-fns';

export function useWeekCountdown(
  endDate?: number,
  currentEpoch?: number,
  showSeconds?: boolean
): string[] {
  const [countdown, setCountdown] = React.useState<string>('');

  React.useEffect(() => {
    if (!endDate) {
      return;
    }

    if (currentEpoch && currentEpoch > 24) {
      setCountdown('00d 00h 00m');
      return;
    }

    const intervalID = setInterval(() => {
      const start = new Date();

      if (Date.now() >= endDate && showSeconds) {
        setCountdown('00 : 00 : 00 : 00');
        return;
      }

      let duration = intervalToDuration({
        start,
        end: new Date(endDate)
      });

      if (duration < 0) {
        duration = intervalToDuration({
          start,
          end: new Date(endDate)
        });
      }

      const format = !showSeconds
        ? ['days', 'hours', 'minutes']
        : ['days', 'hours', 'minutes', 'seconds'];

      setCountdown(
        formatDuration(duration, {
          format: format,
          delimiter: ' ',
          zero: true,
          locale: {
            formatDistance: (token, value) => {
              switch (token) {
                case 'xDays':
                  return `${String(value).padStart(2, '0')} ${
                    showSeconds ? ':' : 'd'
                  }`;
                case 'xHours':
                  return `${String(value).padStart(2, '0')} ${
                    showSeconds ? ':' : 'h'
                  }`;
                case 'xMinutes':
                  return `${String(value).padStart(2, '0')} ${
                    showSeconds ? ':' : 'm'
                  }`;
                case 'xSeconds':
                  return `${String(value).padStart(2, '0')}`;
              }
            }
          }
        })
      );
    }, 1000);

    return () => {
      clearInterval(intervalID);
    };
  }, [currentEpoch, endDate]);

  return [countdown];
}
