import React, { FunctionComponent } from 'react';

import s from './styles.module.css';
import * as Antd from 'antd';

import CardWidget from 'components/card';
import { ReactComponent as EthereumSvg } from '../../../../resources/svg/icons/ethereum.svg';

type InfoProps = {
  data: any;
}

const InfoReferrals: FunctionComponent<InfoProps> =({data}) => {

  return (
    <>
      <CardWidget className={s.card}>
        <table className={s.table}>
          <thead className={s.tableHead}>
            <tr>
              <th className={s.title}>RESERVATION ON</th>
              <th className={s.title}>AMOUNT REFERRED</th>
              <th className={s.title}>REFERREE WALLET</th>
            </tr>
          </thead>
          <tbody className={s.tableBody}>
            {data.map((item: any) => {
              return (
                <tr 
                  className={s.divider}
                  key={item.id}
                >
                  <th className={s.tableCell}>
                    <span>{item.date }</span>
                  </th>
                  <th className={s.tableCell}>
                    <div className={s.coinEtherum}>
                      <EthereumSvg />
                      <span className={s.statNumber}>{item.amount}</span>   
                    </div>  
                  </th>
                  <th className={s.tableCell}><a>{item.swapp}</a></th>
                </tr>
              )
            })}
          </tbody>
        </table>
        <Antd.Row justify="center" >
          <Antd.Col 
            span={6}
            className={s.loading}
          >
            <Antd.Button className={s.btnLoad}>Load more</Antd.Button>
          </Antd.Col>
        </Antd.Row>
      </CardWidget>
      {data.map((item:any) => {
           return (
            <CardWidget className={s.cardMobile} key={item.id}>
              <Antd.Row >
                <Antd.Col span={12}> 
                  <p className={s.title}>RESERVATION ON</p>
                </Antd.Col>
                <Antd.Col span={12}> 
                  <p  className={s.tableCell}>{item.date }</p>
                </Antd.Col>
              </Antd.Row>
              <Antd.Row align="middle" >
                <Antd.Col span={12} > 
                  <p className={s.title}>AMOUNT REFERRED</p>
                </Antd.Col>
                <Antd.Col span={12}> 
                  <div className={s.coinEtherum}>
                    <EthereumSvg />
                    <p className={s.statNumber}>{item.amount}</p>   
                  </div> 
                </Antd.Col>
              </Antd.Row>
              <Antd.Row >
                <Antd.Col span={12}> 
                <p className={s.title}>REFERREE WALLET</p>
                </Antd.Col>
                <Antd.Col span={12}> 
                  <p className={s.tableCell}><a>{item.swapp}</a></p>
                </Antd.Col>
              </Antd.Row>
              
          </CardWidget>
           )
          })}
        <Antd.Row justify="center" className={s.btnLoadMobile} >
          <Antd.Col 
            span={6}
            className={s.loading}
          >
            <Antd.Button className={s.btnLoad}>Load more</Antd.Button>
          </Antd.Col>
      </Antd.Row>
    </>
  )
}

export default InfoReferrals;