import React from 'react';
import BigNumber from 'bignumber.js';

import { useReload } from 'hooks/useReload';
import { useAsyncEffect } from 'hooks/useAsyncEffect';
import { getHumanValue } from 'web3/utils';
import { useWallet } from 'wallets/wallet';
import Web3Contract from 'web3/contract';
import { SWAPPTokenMeta } from 'web3/contracts/swapp';

export const CONTRACT_YIELD_FARM_SWAPP_ADDR = String(process.env.REACT_APP_CONTRACT_YIELD_FARM_SWAPP_ADDR);

type YieldFarmSWAPPContractData = {
  stakeCount?: number;
  currentSwappDay?: number;
  userStakedAmount?: BigNumber;
  globals?: BigNumber[];
};

export type YieldFarmSWAPPContract = YieldFarmSWAPPContractData & {
  contract: Web3Contract;
  reload: () => void;
  createStake: (_stakedAmount: number, _lockDays: number, _referrer: string) => void;
  createStakeWithETH: (amount: number, _lockDays: number, _referrer : string) => void;
  endStake: (stakeId: string) => Promise<any>;
  stakesPagination: (_offset: number, _length: number) => Promise<any>;
  stakes: (stakeId: string) => Promise<any>;
  checkStakeByID: (stakeId: string) => Promise<any>;
  scrapeInterest: (stakeId: string, _scrapeDays: number) => Promise<any>;
  scrapes: (stakeId: string) => Promise<any>;
  getStakingShare: (_stakedAmount: number, _lockDays: number, _referrer: string) => Promise<any>;
};

const InitialData: YieldFarmSWAPPContractData = {
  stakeCount: undefined,
  currentSwappDay: undefined,
  userStakedAmount: undefined,
  globals: undefined,
};

export function useYieldFarmSWAPPContract(): YieldFarmSWAPPContract {
  const [reload] = useReload();
  const wallet = useWallet();

  const contract = React.useMemo<Web3Contract>(() => {
    return new Web3Contract(
      require('web3/abi/yield_farm_swapp.json'),
      CONTRACT_YIELD_FARM_SWAPP_ADDR,
      'YIELD_FARM_SWAPP',
    );
  }, []);

  const [data, setData] = React.useState<YieldFarmSWAPPContractData>(InitialData);

  useAsyncEffect(async () => {
    let [currentSwappDay] = await contract.batch([
      {
        method: 'currentSwappDay'
      },
    ]);

    setData(prevState => ({
      ...prevState,
      currentSwappDay,
    }));
  }, [reload]);

  useAsyncEffect(async () => {
    let stakeCount: number;
    let userStakedAmount: BigNumber | undefined = undefined;
	let globals: BigNumber[];

    if (wallet.account) {
      [stakeCount, userStakedAmount, globals] = await contract.batch([
        {
          method: 'stakeCount',
          methodArgs: [wallet.account],
        },
        {
          method: 'userStakedAmount',
          methodArgs: [wallet.account],
          transform: (value: string) => getHumanValue(new BigNumber(value), SWAPPTokenMeta.decimals),
        },
		{
		  method: 'globals',
		},
      ]);
    }

    setData(prevState => ({
      ...prevState,
      stakeCount,
      userStakedAmount,
	  globals,
    }));
  }, [reload, wallet.account]);

  const createStake = React.useCallback(
    (_stakedAmount: number, _lockDays: number, _referrer: string) => {
      if (!wallet.account) {
        return Promise.reject();
      }

      return contract.send('createStake', [
        new BigNumber(_stakedAmount).times(new BigNumber(10).pow(18)),
        _lockDays,
        _referrer
      ], {
        from: wallet.account,
      }).then(reload);
    },
    [reload, contract, wallet.account]
  );

  const createStakeWithETH = React.useCallback((amount: number, _lockDays: number, _referrer : string) => {
    if (!wallet.account) {
      return Promise.reject();
    }

    return contract.send('createStakeWithETH', [
      _lockDays,
      _referrer,
    ], {
      from: wallet.account,
      value: new BigNumber(amount).times(new BigNumber(10).pow(18))
    }).then(reload);
  }, [reload, contract, wallet.account]);

  const endStake = React.useCallback((stakeId: string) => {
    if (!wallet.account) {
      return Promise.reject();
    }

    return contract.send('endStake', [
      stakeId,
    ], {
      from: wallet.account,
    }).then(reload);
  }, [reload, contract, wallet.account]);

  const stakesPagination = React.useCallback(
    async (_offset: number, _length: number) => {
      if (!wallet.account) {
        return Promise.reject();
      }

      const [data] = await contract.batch([
        {
          method: 'stakesPagination',
          methodArgs: [wallet.account, _offset, _length],
        },
      ]);

      return data;
    },
    [reload, contract, wallet.account]
  );

  const stakes = React.useCallback(
    (stakeId: string) => {
      if (!wallet.account) {
        return Promise.reject();
      }

      return contract.batch([
        {
          method: 'stakes',
          methodArgs: [wallet.account, stakeId],
        },
      ]);
    },
    [reload, contract, wallet.account]
  );

  const checkStakeByID = React.useCallback(
    async (stakeId: string) => {
      if (!wallet.account) {
        return Promise.reject();
      }

      const [stake] = await contract.batch([
        {
          method: 'checkStakeByID',
          methodArgs: [wallet.account, stakeId],
        },
      ]);

      return stake;
    },
    [reload, contract, wallet.account]
  );

  const scrapeInterest = React.useCallback((stakeId: string, _scrapeDays: number) => {
    if (!wallet.account) {
      return Promise.reject();
    }

    return contract.send('scrapeInterest', [
      stakeId, _scrapeDays
    ], {
      from: wallet.account,
    }).then(reload);
  }, [reload, contract, wallet.account]);

  const scrapes = React.useCallback(
    (stakeId: string): Promise<any> => {
      if (!wallet.account) {
        return Promise.reject();
      }

      return contract.batch([
        {
          method: 'scrapes',
          methodArgs: [wallet.account, stakeId],
          transform: (value: string) => getHumanValue(new BigNumber(value), SWAPPTokenMeta.decimals)
        },
      ]);
    },
    [reload, contract, wallet.account]
  );

  const getStakingShare = React.useCallback(
    (_stakedAmount: number, _lockDays: number, _referrer: string) => {
      if (!wallet.account) {
        return Promise.reject();
      }

      return contract.batch([
        {
          method: 'getStakingShare',
          methodArgs: [
            new BigNumber(_stakedAmount).times(new BigNumber(10).pow(18)),
            _lockDays,
            _referrer
          ],
          transform: (value: string) => getHumanValue(new BigNumber(value), SWAPPTokenMeta.decimals),
        },
      ]);
    },
    [reload, contract, wallet.account]
  );


  return React.useMemo<YieldFarmSWAPPContract>(() => ({
    ...data,
    contract,
    reload,
    createStake,
    createStakeWithETH,
    endStake,
    stakesPagination,
    stakes,
    checkStakeByID,
    scrapeInterest,
    scrapes,
    getStakingShare,
  }), [
    data,
    contract,
    reload,
    createStake,
    createStakeWithETH,
    endStake,
    stakesPagination,
    stakes,
    checkStakeByID,
    scrapeInterest,
    scrapes,
    getStakingShare,
  ]);
}
