import React from 'react';
import * as ReCharts from 'recharts';
import BigNumber from 'bignumber.js';
import moment from 'moment';

import IconsSet from 'components/icons-set';
import Dropdown, { DropdownOption } from 'components/dropdown';
import PoolTxChartProvider, { usePoolTxChart } from 'views/pools/components/pool-tx-chart-provider';

import { formatUSDValue, getPoolIcons, getPoolNames, PoolTypes } from 'web3/utils';
import { useWeb3Contracts } from 'web3/contracts';

import { ReactComponent as EmptyChartSvg } from 'resources/svg/empty-chart.svg';

import s from './styles.module.css';
import Switcher from 'components/switcher';

const PoolFilters: DropdownOption[] = [
  {
    value: 'stable',
    label: getPoolNames(PoolTypes.STABLE).join('/'),
  },
  {
    value: 'unilp',
    label: getPoolNames(PoolTypes.UNILP).join('/'),
  },
  {
    value: 'swapp',
    label: getPoolNames(PoolTypes.SWAPP).join('/'),
  },
];

const TypeFilters: DropdownOption[] = [
  { value: 'all', label: 'All transactions' },
  { value: 'deposits', label: 'Staked' },
  { value: 'withdrawals', label: 'Unstaked' },
];

export type PoolTransactionChartProps = {};

const PoolTransactionChartInner: React.FunctionComponent<PoolTransactionChartProps> = () => {
  const web3c = useWeb3Contracts();
  const poolTxChart = usePoolTxChart();
  const maxEpochCounter = 24;

  const [poolFilter, setPoolFilter] = React.useState<PoolTypes>(PoolTypes.STABLE);
  const [periodFilter, setPeriodFilter] = React.useState<string | number>('all');
  const [typeFilter, setTypeFilter] = React.useState<string | number>('all');

  const PeriodFilters = React.useMemo<DropdownOption[]>(() => {
    const filters = [{ value: 'all', label: 'All epochs' }];

    if (poolFilter === PoolTypes.STABLE) {
      for (let i = 0; i <= maxEpochCounter; i++) {
        if (i <= web3c.staking.currentEpoch!) {
          filters.push({ value: String(i), label: `Epoch ${i}` });
        }
      }
    } else if (poolFilter === PoolTypes.UNILP) {
      // for (let i = 1; i <= web3c.staking.currentEpoch! - web3c.yfLP.delayedEpochs!; i++) {
      for (let i = 0; i <= web3c.staking.currentEpoch!; i++) {
        filters.push({ value: String(i), label: `Epoch ${i}` });
      }
    } else if (poolFilter === PoolTypes.SWAPP) {
      for (let i = 0; i <= web3c.stakingSwapp.currentEpoch!; i++) {
        filters.push({ value: String(i), label: `Epoch ${i}` });
      }
    }

    return filters;
  }, [web3c.staking, poolFilter, web3c.stakingSwapp]);

  React.useEffect(() => {
    poolTxChart.load({
      pool: poolFilter,
      period: periodFilter !== 'all' ? String(periodFilter) : undefined,
      type: typeFilter !== 'all' ? String(typeFilter) : undefined,
    }).catch(x => x);
  }, [poolFilter, periodFilter, typeFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    poolTxChart.startPooling();

    return () => {
      poolTxChart.stopPooling();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const data = React.useMemo(() => {
    const price = web3c.getPoolUsdPrice(poolFilter);

    if (!price) {
      return poolTxChart.summaries;
    }

    return poolTxChart.summaries.map(summary => {
      const deposits = (new BigNumber(summary.deposits))
        .multipliedBy(price)
        .toNumber();
      const withdrawals = (new BigNumber(summary.withdrawals))
        .multipliedBy(price)
        .multipliedBy(-1)
        .toNumber();
      return {
        ...summary,
        deposits,
        withdrawals,
      };
    });
  }, [web3c, poolFilter, poolTxChart.summaries]);

  // const mappedData = useMemo(() =>{
  //   const arr: object[] = []
  //   data.forEach(item=>{
  //     arr.push(item);
  //     arr.push({
  //       deposits: 0,
  //       timestamp: '',
  //       withdrawals: 0
  //     })
  //   })
  //   return arr;
  // },[data])

  const formatXAxis = (tickItem: any) =>  {
    if (!tickItem.includes('Epoch')) return moment.unix(tickItem).format('MM/DD/YYYY');
    return tickItem;
  }

  return (
    <div className={s.component}>
      <div className={s.header}>
        <div className={s.headerLabel}>
          <IconsSet className={s.iconSet} icons={getPoolIcons(poolFilter)} />
          <Dropdown
            items={PoolFilters}
            selected={poolFilter}
            onSelect={(value: string | number) => setPoolFilter(value as PoolTypes)}
          />
        </div>
        <div className={s.legends}>
          <div className={s.legendsItem}>
            <div className={s.legendsStackedLabel}></div>
            <div className={s.legendsName}>Stacked</div>
          </div>
          <div className={s.legendsItem}>
            <div className={s.legendsUnstackedLabel}></div>
            <div className={s.legendsName}>Unstacked</div>
          </div>
        </div>
        <div className={s.filters}>
          <div className={s.filtersItem}>
            <Switcher
                checked={typeFilter==='deposits'}
                onChange={checked=>checked ? setTypeFilter('deposits') : setTypeFilter('all')}
            />
            <div className={s.legendsName}>Stacked only</div>
          </div>
          <div className={s.filtersItem}>
             <span className={s.dropDownLabel}>
              Period
            </span>
            <Dropdown
                button
                items={PeriodFilters}
                selected={periodFilter}
                onSelect={setPeriodFilter}
                className={s.filterDropDown}
                listClassName={s.overflowContainer}
            />
          </div>
          <div className={s.filtersItem}>
            <span className={s.dropDownLabel}>
              Show
            </span>
            <Dropdown
                button
                items={TypeFilters}
                selected={typeFilter}
                onSelect={setTypeFilter}
                className={s.filterDropDown}
            />
          </div>
        </div>
      </div>
      <div className={s.chart}>
        {poolTxChart.loaded && (
          <>
            {!poolTxChart.loading && data.length === 0 && (
              <div className={s.emptyBlock}>
                <EmptyChartSvg />
                <div className={s.emptyLabel}>Not enough data to plot a graph</div>
                <div className={s.emptyLabel}>Coming soon</div>
              </div>
            )}
            {data.length > 0 && (
              <ReCharts.ResponsiveContainer width="100%" height={350}>
                <ReCharts.LineChart
                  data={data}
                  stackOffset="sign"
                  margin={{
                    top: 10, right: 0, left: 10, bottom: 15,
                  }}
                >
                  <ReCharts.CartesianGrid stroke="#666" strokeDasharray="1 10" />
                  <ReCharts.XAxis
                    dataKey="timestamp"
                    tickMargin={24}
                    tickFormatter={formatXAxis}
                    axisLine={false}
                    tickLine={false}
                  />
                  <ReCharts.YAxis axisLine={false} tickLine={false} tickFormatter={(value: any) =>
                    formatUSDValue(value, 2, 0)
                  } />
                  <ReCharts.Tooltip
                    cursor={{ stroke: '#182032', strokeWidth: 2 }}
                    formatter={(value: any) => formatUSDValue(value)}
                    itemStyle={{}}
                    contentStyle={{ background: '#182032', borderRadius: '8px', border: 'none'}}
                  />
                  {/*<ReCharts.Legend*/}
                  {/*  align="right"*/}
                  {/*  verticalAlign="top"*/}
                  {/*  iconType="circle"*/}
                  {/*  wrapperStyle={{ top: 0, right: 12, color: 'var(--text-color-5)' }} />*/}
                  <defs>
                    <filter id="filter">
                      <feDropShadow dx="0" dy="3" stdDeviation="3" floodColor="#E53E4C" />
                      <feDropShadow dx="0" dy="-3" stdDeviation="3" floodColor="#E53E4C" />
                    </filter>
                    <filter id="filter2">
                      <feDropShadow dx="0" dy="3" stdDeviation="3" floodColor="#1286E3" />
                      <feDropShadow dx="0" dy="-3" stdDeviation="3" floodColor="#1286E3" />
                    </filter>
                  </defs>
                  <ReCharts.ReferenceLine y={0} stroke="#666" />
                  {(typeFilter === 'all' || typeFilter === 'deposits') && (
                    <ReCharts.Line
                        dataKey="deposits"
                        name="Staked"
                        stroke="#E53E4C"
                        dot={false}
                        type="monotone"
                        strokeWidth={2}
                        filter="url(#filter)"
                                   //stackId="stack"
                    />
                  )}
                  {(typeFilter === 'all' || typeFilter === 'withdrawals') && (

                    <ReCharts.Line
                        dataKey="withdrawals"
                        name="Unstaked"
                        stroke="#1286E3"
                        dot={false}
                        type="monotone"
                        strokeWidth={2}
                        filter="url(#filter2)"
                                   //stackId="stack"
                    />
                  )}
                </ReCharts.LineChart>
              </ReCharts.ResponsiveContainer>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const PoolTransactionChart: React.FunctionComponent<PoolTransactionChartProps> = props => (
  <PoolTxChartProvider>
    <PoolTransactionChartInner {...props} />
  </PoolTxChartProvider>
);

export default PoolTransactionChart;
