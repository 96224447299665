import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation, Redirect } from 'react-router-dom';
import * as Antd from 'antd';
import { useWallet } from 'wallets/wallet';
import cx from 'classnames';

import PoolsView from 'views/pools';
import VotingView from 'views/voting';
import ReferralView from 'views/referral';
import BurnView from 'views/burn';
import PersonalView from '../personal-dashboard/index';
import DashboardView from '../dashboard/index';
import SwapModal from './components/swap-modal';
import CompaundView from 'views/compaund';
import BridgeView from 'views/bridge';
import Footer from './components/footer';

import Warnings from 'components/warnings';
import SiderNav from 'components/sider-nav';
import { SiderNavLinkProps } from 'components/sider-nav-link';
import MobileMenu from 'components/mobile-menu';
import PoolRewards from 'components/pool-rewards';

import Web3 from 'web3';
import { getWSRpcUrl } from 'web3/utils';

import { ReactComponent as PoolsSvg } from 'resources/svg/sidbarIcons/pools.svg';
import { ReactComponent as VotingSvg } from 'resources/svg/sidbarIcons/voting.svg';
import { ReactComponent as BurnSvg } from 'resources/svg/sidbarIcons/burn.svg';
import { ReactComponent as PersonalBoardSvg } from '../../resources/svg/sidbarIcons/board.svg';
import { ReactComponent as DashboardSvg } from '../../resources/svg/sidbarIcons/dashboard.svg';
import { ReactComponent as BridgeArrowsSvg } from '../../resources/svg/sidbarIcons/bridge.svg';
import { ReactComponent as LotterySvg } from '../../resources/svg/sidbarIcons/lottery.svg';

import s from './styles.module.css';
import LayoutHeader from 'components/layout-header';
declare let window: any;

const DEFAULT_CONTRACT_PROVIDER = new Web3.providers.WebsocketProvider(
  getWSRpcUrl()
);
const web3 = new Web3(DEFAULT_CONTRACT_PROVIDER);

const LayoutView: React.FunctionComponent = () => {
  const wallet = useWallet();
  const location = useLocation();

  const [siderNavLinks, setiderNavLinks] = useState<SiderNavLinkProps[][]>([]);

  const query = window.location.search.substr(1);
  if (query.substr(0, 1) == 'w' && web3.utils.isAddress(query.substr(2))) {
    localStorage.setItem('w', query.substr(2));
  }

  const saveNewChainId = () => {
    const siderNavLinks = [
      [
        {
          icon: <DashboardSvg />,
          label: 'Dashboard',
          path: '/dashboard'
        },
        {
          icon: <PersonalBoardSvg />,
          label: 'Personal board',
          path: '/personal-dashboard'
        },
        {
          icon: <PoolsSvg />,
          label: 'Pools',
          path: '/pools'
        },
        {
          icon: <VotingSvg />,
          label: 'Voting',
          path: '/voting'
        },
        {
          icon: <BurnSvg />,
          label: 'Burn',
          path: '/burn'
        },
        {
          icon: <BridgeArrowsSvg />,
          label: 'Bridge',
          path: '/bridge'
        }
      ],
      [
        {
          icon: <LotterySvg />,
          label: 'LotteryX',
          path:
            'https://lotteryx.io/swapp-bsc?ref=0x83aB6600bf496717Da86D2722e6D0CE39EE0F9Df',
          outerLink: true
        }
      ]
    ];

    setiderNavLinks(siderNavLinks);
  };

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('networkChanged', (id: number) => {
        saveNewChainId();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    saveNewChainId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet.networkId]);

  return (
      <Antd.Layout className={s.wrapper}>
        <LayoutHeader />
        <Antd.Layout className={s.container}>
          <SiderNav className={s.siderNav} links={siderNavLinks} />
          <Antd.Layout
              className={cx(
                  s.main,
                  location.pathname === '/pre-sale' ? s.preSale : ''
              )}
          >
            {/*<MobileMenu />*/}
            <Warnings>
              <Antd.Layout.Content className={s.content}>
                <PoolRewards />
                <Switch>
                  <Route path="/pools" component={PoolsView} />
                  <Route path="/voting" component={VotingView} />
                  <Route path="/burn" component={BurnView} />
                  <Route path="/referral" component={ReferralView} />
                  <Route path="/personal-dashboard" component={PersonalView} />
                  <Route path="/dashboard" component={DashboardView} />
                  <Route path="/compound" component={CompaundView} />
                  <Route path="/bridge" component={BridgeView} />
                  <Redirect from="/" to="/pools" />
                </Switch>
                {/*<Footer />*/}
              </Antd.Layout.Content>
            </Warnings>
          </Antd.Layout>
          {location.pathname !== '/pre-sale' && (
              <SwapModal networkId={wallet.networkId!} />
          )}
        </Antd.Layout>
      </Antd.Layout>

  );
};

export default LayoutView;
