import React, { useEffect, useState } from 'react';
import * as Antd from 'antd';
import Burger from '@animated-burgers/burger-squeeze';
import cx from 'classnames';
import { useWallet } from 'wallets/wallet';

import SiderNavLink from 'components/sider-nav-link';
import FadeBlock from 'components/fade-block';

import '@animated-burgers/burger-squeeze/dist/styles.css';

import { ReactComponent as LogoSvg } from 'resources/svg/swappLogo.svg';
import { ReactComponent as PoolsSvg } from 'resources/svg/sidbarIcons/pools.svg';
import { ReactComponent as VotingSvg } from 'resources/svg/sidbarIcons/voting.svg';
import { ReactComponent as BurnSvg } from 'resources/svg/sidbarIcons/burn.svg';
import { ReactComponent as PersonalBoardSvg } from '../../resources/svg/sidbarIcons/board.svg';
import { ReactComponent as DashboardSvg } from '../../resources/svg/sidbarIcons/dashboard.svg';
import { ReactComponent as BridgeArrowsSvg } from '../../resources/svg/sidbarIcons/bridge.svg';
import { ReactComponent as LotterySvg } from '../../resources/svg/sidbarIcons/lottery.svg';

import s from './styles.module.css';
import { useTheme } from 'components/theme-provider';
import { ReactComponent as MoonSvg } from 'resources/svg/icons/moon.svg';
import { ReactComponent as SunSvg } from 'resources/svg/icons/sun.svg';

declare let window: any;

const MobileMenu: React.FunctionComponent = () => {
  const wallet = useWallet();

  const { toggleDarkTheme, isDarkTheme } = useTheme();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [siderNavLinks, setiderNavLinks]: any = useState([]);

  function handleMenuClick() {
    setIsOpen((prevState) => !prevState);
  }

  function handleLinkClick() {
    setIsOpen(false);
  }

  function handleThemeToggle() {
    toggleDarkTheme();
  }

  const saveNewChainId = () => {
    const siderNavLinks = [
      {
        icon: <DashboardSvg />,
        label: 'Dashboard',
        path: '/dashboard'
      },
      {
        icon: <PersonalBoardSvg />,
        label: 'Personal board',
        path: '/personal-dashboard'
      },
      {
        icon: <PoolsSvg />,
        label: 'Pools',
        path: '/pools'
      },
      {
        icon: <VotingSvg />,
        label: 'Voting',
        path: '/voting'
      },
      {
        icon: <BurnSvg />,
        label: 'Burn',
        path: '/burn'
      },
      {
        icon: <BridgeArrowsSvg />,
        label: 'Bridge',
        path: '/bridge'
      },
      {
        icon: <LotterySvg />,
        label: 'LotteryX',
        path:
          'https://lotteryx.io/swapp-bsc?ref=0x83aB6600bf496717Da86D2722e6D0CE39EE0F9Df',
        outerLink: true
      }
    ];

    setiderNavLinks(siderNavLinks);
  };

  useEffect(() => {
    saveNewChainId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet.networkId]);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('networkChanged', (id: number) => {
        saveNewChainId();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (isOpen) {
      if (!document.body.classList.contains('mobile-menu-open')) {
        document.body.classList.add('mobile-menu-open');
      }
    } else {
      if (document.body.classList.contains('mobile-menu-open')) {
        document.body.classList.remove('mobile-menu-open');
      }
    }
  }, [isOpen]);

  return (
    <div className={s.component}>
      <Antd.Button type="link" className={s.menuBtn} onClick={handleMenuClick}>
        <Burger className={s.burger} isOpen={isOpen} />
      </Antd.Button>
      <FadeBlock className={s.logo} visible={!isOpen}>
        {/*<LogoSvg />*/}
        {/*<span>SWAPP</span>*/}
      </FadeBlock>
      <div className={cx(s.mask, { [s.maskOpen]: isOpen })} />
      <div className={cx(s.menuList, { [s.menuListOpen]: isOpen })}>
        <div className={s.innerLogo}>
          <LogoSvg />
          <span className={s.title}>swapp</span>
        </div>
        {siderNavLinks.map((link: any) => (
          <SiderNavLink
            key={link.path}
            {...link}
            expanded
            onClick={handleLinkClick}
          />
        ))}

        {/*<Antd.Button*/}
        {/*  type="link"*/}
        {/*  className={s.themeToggle}*/}
        {/*  onClick={handleThemeToggle}*/}
        {/*>*/}
        {/*  {!isDarkTheme ? <MoonSvg /> : <SunSvg />}*/}
        {/*  <FadeBlock visible={isOpen}>*/}
        {/*    {!isDarkTheme ? 'Dark' : 'Light'} Theme*/}
        {/*  </FadeBlock>*/}
        {/*</Antd.Button>*/}
      </div>
    </div>
  );
};

export default MobileMenu;
