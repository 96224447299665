import React from 'react';
import BigNumber from 'bignumber.js';
import memoize from 'lodash/memoize';

import { TokenMeta } from 'web3/types';
import { USDCTokenMeta } from 'web3/contracts/usdc';
import { USDTTokenMeta } from 'web3/contracts/usdt';
import { DAITokenMeta } from 'web3/contracts/dai';
import { UNISWAPTokenMeta } from 'web3/contracts/uniswap';
import { SWAPPTokenMeta } from 'web3/contracts/swapp';
import { WBTCTokenMeta } from 'web3/contracts/wbtc';

export const MAX_UINT_256 = new BigNumber(2).pow(256).minus(1);
export const ZERO_BIG_NUMBER = new BigNumber(0);

export function getWSRpcUrl(
  chainId: number = Number(process.env.REACT_APP_WEB3_CHAIN_ID)
): string {
  const WEB3_RPC_ID = String(process.env.REACT_APP_WEB3_RPC_ID);

  switch (chainId) {
    case 1:
      return `wss://mainnet.infura.io/ws/v3/${WEB3_RPC_ID}`;
    case 3:
      return `wss://ropsten.infura.io/ws/v3/${WEB3_RPC_ID}`;
    case 25:
      return 'wss://evm-cronos.crypto.org';
    case 42:
      return `wss://kovan.infura.io/ws/v3/${WEB3_RPC_ID}`;
    case 56:
      return 'wss://bsc.getblock.io/mainnet/?api_key=a343bca2-9ce6-4e3b-afef-d07c1c598d80';
    case 97:
      return 'wss://bsc.getblock.io/testnet/?api_key=a343bca2-9ce6-4e3b-afef-d07c1c598d80';
    case 137:
      return 'wss://ws-mainnet.matic.network/';
    case 80001:
      return 'wss://ws-mumbai.matic.today/';
    default:
      throw new Error(`Not supported chainId=${chainId!}.`);
  }
}

export function getHttpsRpcUrl(
  chainId: number = Number(process.env.REACT_APP_WEB3_CHAIN_ID)
): string {
  const WEB3_RPC_ID = String(process.env.REACT_APP_WEB3_RPC_ID);

  switch (chainId) {
    case 1:
      return `https://mainnet.infura.io/v3/${WEB3_RPC_ID}`;
    case 3:
      return `https://ropsten.infura.io/v3/${WEB3_RPC_ID}`;
    case 25:
      return 'https://evm-cronos.crypto.org/';
    case 42:
      return `https://kovan.infura.io/v3/${WEB3_RPC_ID}`;
    case 56:
      return 'https://bsc-dataseed.binance.org/';
    case 97:
      return 'https://data-seed-prebsc-1-s2.binance.org:8545/';
    case 137:
      return `https://polygon-mainnet.infura.io/v3/${WEB3_RPC_ID}`;
    case 80001:
      return `https://polygon-mumbai.infura.io/v3/${WEB3_RPC_ID}`;
    default:
      throw new Error(`Not supported chainId=${chainId}.`);
  }
}

export function getTxUrl(
  TxHash: string,
  chainId: number = Number(process.env.REACT_APP_WEB3_CHAIN_ID)
): string {
  switch (chainId) {
    case 1:
      return `https://etherscan.io/tx/${TxHash}`;
    case 3:
      return `https://ropsten.etherscan.io/tx/${TxHash}`;
    case 42:
      return `https://kovan.etherscan.io/tx/${TxHash}`;
    default:
      return '';
  }
}

export function getEtherscanTxUrl(
  txHash: string,
  chainId: number = Number(process.env.REACT_APP_WEB3_CHAIN_ID)
): string {
  switch (chainId) {
    case 1:
      return `https://etherscan.io/tx/${txHash}`;
    case 3:
      return `https://ropsten.etherscan.io/tx/${txHash}`;
    case 42:
      return `https://kovan.etherscan.io/tx/${txHash}`;
    default:
      throw new Error(`Not supported chainId=${chainId}.`);
  }
}

export function getEtherscanAddressUrl(
  address: string,
  chainId: number = Number(process.env.REACT_APP_WEB3_CHAIN_ID)
): string {
  switch (chainId) {
    case 1:
      return `https://etherscan.io/address/${address}`;
    case 3:
      return `https://ropsten.etherscan.io/address/${address}`;
    case 42:
      return `https://kovan.etherscan.io/address/${address}`;
    default:
      throw new Error(`Not supported chainId=${chainId}.`);
  }
}

export function getNetworkName(chainId: number | undefined): string {
  switch (chainId) {
    case 1:
      return 'Mainnet';
    case 3:
      return 'Ropsten';
    case 25:
      return 'Cronos';
    case 42:
      return 'kovan';
    case 56:
      return 'Smart Chain';
    case 97:
      return 'Smart Chain - Testnet';
    case 137:
      return 'Polygon';
    case 80001:
      return 'Mumbai Testnet';
    default:
      return '';
  }
}

export function getNetworkAddress(chainId: number | undefined): string {
  switch (chainId) {
    case 1:
      return String(process.env.REACT_APP_CONTRACT_SWAPP_ADDR).toLowerCase();
    case 3:
      return String(process.env.REACT_APP_CONTRACT_SWAPP_ADDR).toLowerCase();
    case 25:
      return String(
        process.env.REACT_APP_CONTRACT_SWAPP_ADDR_CRONOS
      ).toLowerCase();
    case 56:
      return String(
        process.env.REACT_APP_CONTRACT_SWAPP_ADDR_BINANCE
      ).toLowerCase();
    case 97:
      return String(
        process.env.REACT_APP_CONTRACT_SWAPP_ADDR_BINANCE
      ).toLowerCase();
    case 137:
      return String(
        process.env.REACT_APP_CONTRACT_SWAPP_ADDR_POLYGON
      ).toLowerCase();
    case 80001:
      return String(
        process.env.REACT_APP_CONTRACT_SWAPP_ADDR_POLYGON
      ).toLowerCase();
    default:
      return '';
  }
}

export function getBridgeAddress(chainId: number | undefined): string {
  switch (chainId) {
    case 1:
      return String(
        process.env.REACT_APP_CONTRACT_BRIDGE_ETHEREUM
      ).toLowerCase();
    case 3:
      return String(
        process.env.REACT_APP_CONTRACT_BRIDGE_ETHEREUM
      ).toLowerCase();
    case 25:
      return String(process.env.REACT_APP_CONTRACT_BRIDGE_CRONOS).toLowerCase();
    case 56:
      return String(
        process.env.REACT_APP_CONTRACT_BRIDGE_BINANCE
      ).toLowerCase();
    case 97:
      return String(
        process.env.REACT_APP_CONTRACT_BRIDGE_BINANCE
      ).toLowerCase();
    case 137:
      return String(
        process.env.REACT_APP_CONTRACT_BRIDGE_POLYGON
      ).toLowerCase();
    case 80001:
      return String(
        process.env.REACT_APP_CONTRACT_BRIDGE_POLYGON
      ).toLowerCase();
    default:
      return '';
  }
}

export function getExponentValue(decimals: number = 0): BigNumber {
  return new BigNumber(10).pow(decimals);
}

export function getHumanValue(
  value?: BigNumber,
  decimals: number = 0
): BigNumber | undefined {
  return value?.div(getExponentValue(decimals));
}

export function getNonHumanValue(
  value: BigNumber | number,
  decimals: number = 0
): BigNumber {
  return new BigNumber(value).multipliedBy(getExponentValue(decimals));
}

export function formatBigValue(
  value?: BigNumber,
  decimals: number = 4,
  defaultValue: string = '-',
  minDecimals: number | undefined = undefined
): string {
  return value
    ? new BigNumber(value.toFixed(decimals)).toFormat(minDecimals)
    : defaultValue;
}

export function formatUSDValue(
  value?: BigNumber,
  decimals: number = 2,
  minDecimals: number = decimals
): string {
  if (value === undefined) {
    return '-';
  }

  const val = BigNumber.isBigNumber(value) ? value : new BigNumber(value);
  const formattedValue = formatBigValue(val.abs(), decimals, '-', minDecimals);

  return val.isPositive() ? `$${formattedValue}` : `-$${formattedValue}`;
}

export function formatSWAPPValue(value?: BigNumber): string {
  return formatBigValue(value, 2);
}

export function shortenAddr(addr: string, first: number = 6, last: number = 4) {
  return [String(addr).slice(0, first), String(addr).slice(-last)].join('...');
}

export function getTokenMeta(tokenAddr: string): TokenMeta | undefined {
  switch (tokenAddr.toLowerCase()) {
    case USDCTokenMeta.address:
      return USDCTokenMeta;
    case USDTTokenMeta.address:
      return USDTTokenMeta;
    case DAITokenMeta.address:
      return DAITokenMeta;
    case UNISWAPTokenMeta.address:
      return UNISWAPTokenMeta;
    case SWAPPTokenMeta.address:
      return SWAPPTokenMeta;
    default:
      return undefined;
  }
}

export enum PoolTypes {
  STABLE = 'stable',
  UNILP = 'unilp',
  SWAPP = 'swapp'
}

export const getPoolIcons = memoize(
  (poolType: PoolTypes): React.ReactNode[] => {
    switch (poolType) {
      case PoolTypes.STABLE:
        return [USDCTokenMeta.icon, USDTTokenMeta.icon, DAITokenMeta.icon];
      case PoolTypes.UNILP:
        return [WBTCTokenMeta.icon, SWAPPTokenMeta.icon];
      case PoolTypes.SWAPP:
        return [SWAPPTokenMeta.icon];
      default:
        return [];
    }
  }
);

export const getPoolNames = memoize((poolType: PoolTypes): string[] => {
  switch (poolType) {
    case PoolTypes.STABLE:
      return [USDCTokenMeta.name, USDTTokenMeta.name, DAITokenMeta.name];
    case PoolTypes.UNILP:
      return [UNISWAPTokenMeta.name];
    case PoolTypes.SWAPP:
      return [SWAPPTokenMeta.name];
    default:
      return [];
  }
});

export const getPoolAddresses = memoize(
  (poolType: PoolTypes | undefined): string[] => {
    switch (poolType) {
      case PoolTypes.STABLE:
        return [
          USDCTokenMeta.address,
          USDTTokenMeta.address,
          DAITokenMeta.address
        ];
      case PoolTypes.UNILP:
        return [UNISWAPTokenMeta.address];
      case PoolTypes.SWAPP:
        return [SWAPPTokenMeta.address];
      default:
        return [];
    }
  }
);

export function getTokenAddress(token: string) {
  switch (token) {
    case 'DAI':
      return String(process.env.REACT_APP_CONTRACT_DAI_ADDR);
  }
  return '';
}

export const fixBNArrayLength = (arr: BigNumber[][], max: number) =>arr.map(el=>el.length<max ?
      [...Array(max-el.length).fill(ZERO_BIG_NUMBER),...el] :
      el);

export const fixArrayLength = <T>(arr: number[][], max: number) =>arr.map(el=>el.length<max ?
    [...Array(max-el.length).fill(0),...el] :
    el);
