import { ReactComponent as EthereumSvg } from '../resources/svg/icons/ethereum.svg';
import { ReactComponent as BinanceSvg } from '../resources/svg/icons/binance.svg';
import { ReactComponent as PolygonSvg } from '../resources/svg/icons/polygon.svg';
import { ReactComponent as CronosSvg } from '../resources/svg/icons/cronos.svg';

export const priceArray = [0.006, 0.2, 0.75, 0.95, 1.0];
export const rewardArray = [458969, 1498041, 5173385, 18951677, 74086406];
export const monthlyRewardArray = [
  24000000,
  6000000,
  4800000,
  3000000,
  3000000
];

export const swappWallets = [
  '0x0d970a04d46c73B6d20d9a0B2B07C35F2495ca9c',
  '0x915D99375Ba8EDbbee46bE1AD045718a05A6655b',
  '0x7Db4456a73a9C94a381d244E9dfC76E83C05913E',
  '0x62F16a5bA06693B1E96a656d46e66A8CdaE17C69',
  '0x94dc2f1823AbfdC2fb9BB8Ae10162b65D2Cf1c65',
  '0x2a8eA8a4842DA268FA4180b1a99B7876f820ECC1',
  '0x000baFB91ED6436ad2888C2418197aFDB85785C5',
  '0xd4041e1c24A54134Fb9657e8DA85e75001D7Ea44'
];

const chainId = process.env.REACT_APP_WEB3_CHAIN_ID;

export const chainArrays = [
  {
    label: 'Ethereum',
    icon: <EthereumSvg />,
    id: 'ethereum',
    selected: false,
    current: false,
    bridgeId: 1,
    chainIdHex: '0x1',
    chainId: chainId == 1 ? 1 : 3,
    symbol: 'ETH',
    decimals: 18,
    rpcUrls: 'https://mainnet.infura.io/v3/8d342375c0044757adf4e8661cb62deb',
    blockExplorerUrls: 'https://etherscan.io'
  },
  {
    label: 'Binance',
    icon: <BinanceSvg />,
    id: 'binance',
    selected: false,
    current: false,
    bridgeId: 2,
    chainIdHex: '0x38',
    chainId: chainId == 1 ? 56 : 97,
    symbol: 'BNB',
    decimals: 18,
    rpcUrls: 'https://bsc-dataseed.binance.org/',
    blockExplorerUrls: 'https://bscscan.com'
  },
  {
    label: 'Cronos',
    icon: <CronosSvg />,
    id: 'cronos',
    selected: false,
    current: false,
    bridgeId: 3,
    chainIdHex: '0x19',
    chainId: 25,
    symbol: 'CRO',
    decimals: 18,
    rpcUrls: 'https://evm-cronos.crypto.org/',
    blockExplorerUrls: 'https://cronos.crypto.org/explorer/'
  },
  {
    label: 'Polygon',
    icon: <PolygonSvg />,
    id: 'polygon',
    selected: false,
    current: false,
    bridgeId: 4,
    chainIdHex: '0x89',
    chainId: chainId == 1 ? 137 : 80001,
    symbol: 'MATIC',
    decimals: 18,
    rpcUrls: 'https://rpc-mainnet.matic.network',
    blockExplorerUrls: 'https://polygonscan.com/'
  }
];

export const BridgeTransactionsStatuses = [
  'New',
  'Sent to mint',
  'Minted',
  'Error',
  'Check not passed'
];

export const ratesData = [
  { sum: 93, sign: '<', data: '1000' },
  { sum: 98, sign: '', data: '1000 - 5000' },
  { sum: 100, sign: '', data: '5000 - 50000' },
  { sum: 105, sign: '', data: '50000 - 100000' },
  { sum: 110, sign: '<', data: '100000' }
];

export const preSaleEnd = 1635555600000;
