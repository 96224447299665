import React from 'react';
import * as Antd from 'antd';
import cx from 'classnames';

import { useTheme } from 'components/theme-provider';

import SiderNavLink, { SiderNavLinkProps } from 'components/sider-nav-link';
import FadeBlock from 'components/fade-block';

// import { ReactComponent as LogoSvg } from 'resources/svg/logo.svg';
import { ReactComponent as SwappLogo } from 'resources/svg/swappLogo.svg';
import { ReactComponent as ArrowRightSvg } from 'resources/svg/icons/arrow-right.svg';
import { ReactComponent as MoonSvg } from 'resources/svg/icons/moon.svg';
import { ReactComponent as SunSvg } from 'resources/svg/icons/sun.svg';

import s from './styles.module.css';

export type SiderNavProps = {
  links: SiderNavLinkProps[][];
  className?: string;
};

const SiderNav: React.FunctionComponent<SiderNavProps> = props => {
  const { toggleDarkTheme, isDarkTheme } = useTheme();
  const [expanded, setExpanded] = React.useState<boolean>(false);

  function handleExpand() {
    setExpanded(prevState => !prevState);
  }

  function handleThemeToggle() {
    toggleDarkTheme();
  }

  return (
    <Antd.Layout.Sider
      className={cx(s.component, props.className, { [s.expanded]: expanded })}
      collapsed={!expanded}
      collapsedWidth={72}
      width={273}
    >
      {/*<div className={s.header}>*/}
      {/*  <div className={s.logo}>*/}
      {/*    <SwappLogo />*/}
      {/*  </div>*/}
      {/*  <FadeBlock className={s.brandName} visible={expanded}>*/}
      {/*    <span>swapp</span>*/}
      {/*  </FadeBlock>*/}
      {/*</div>*/}
      {expanded && <div className={s.blockLabel}>MENU</div>}
      {props.links.map((setItems, i) => (
        <div className={s.navList} key={i}>
          {
            setItems.map ((link, t) => (
              <SiderNavLink key={t} {...link} expanded={expanded} tooltip />
            ))
          }
        {(i < props.links.length - 1 && expanded) && <div key={i} className={s.blockLabel}>PARTNERS</div>}
        </div>
      ))}

      {/*<Antd.Button type="link" className={s.themeToggle} onClick={handleThemeToggle}>*/}
      {/*  {!isDarkTheme ? <MoonSvg /> : <SunSvg />}*/}
      {/*  <FadeBlock className={s.themeText} visible={expanded}>*/}
      {/*    {!isDarkTheme ? 'Dark' : 'Light'} Theme*/}
      {/*  </FadeBlock>*/}
      {/*</Antd.Button>*/}

      <Antd.Button
        className={s.expandBtn}
        type="link"
        icon={<ArrowRightSvg />}
        onClick={handleExpand}>
      </Antd.Button>
    </Antd.Layout.Sider>
  );
};

export default SiderNav;
