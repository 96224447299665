import React, {useState} from 'react';
import * as Antd from "antd";
import s from './styles.module.css';
import {ReactComponent as SwappLogo} from "../../resources/svg/swappLogo.svg";
import SwapButton from 'components/swap-button';
import {useWallet} from "../../wallets/wallet";
import Dropdown from 'components/dropdown';
import ConnectedWallet from "../connected-wallet";
import LinkModal from "../link-modal";
import MobileMenu from 'components/mobile-menu';


const LayoutHeader: React.FunctionComponent = () =>{
    const [showLinkModal, setShowLinkModal] = useState(false);
    const wallet = useWallet();
    return (
        <Antd.Layout.Header className={s.header}>
            <MobileMenu />
            <div className={s.logo}>
                <SwappLogo/>
                <div className={s.brandName}>swapp</div>
                <div className={s.border}></div>
            </div>
            <div className={s.container}>
                <SwapButton networkId={wallet.networkId!}/>
                <ConnectedWallet
                    hideHeader={false}
                    setShowLinkModal={setShowLinkModal}
                />
            </div>
            <LinkModal visible={showLinkModal} setShowLinkModal={setShowLinkModal} />
        </Antd.Layout.Header>
    )
}

export default LayoutHeader;
