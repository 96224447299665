import React, { useEffect } from 'react';
import cx from 'classnames';
import axios from 'axios';
import Web3 from 'web3';

import k from './styles.module.css';
import ExternalLink from 'components/externalLink';

interface GasPriceValues {
  id: string | undefined;
  ethGasValues: any;
}

const GasPrice: React.FunctionComponent<GasPriceValues> = ({
  id,
  ethGasValues
}) => {
  const [bnbGasValue, setBnbGasValues] = React.useState({} as any);
  const [cronosGasValue, setCronosGasValues] = React.useState({} as any);
  const [maticGasValue, setMaticGasValues] = React.useState({} as any);
  const [currentGasValue, setCurrentGasValues] = React.useState({} as any);

  useEffect(() => {
    bnbGasPrice();
    maticGasPrice();
    cronosGasPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      bnbGasPrice();
      maticGasPrice();
      cronosGasPrice();
    }, 60000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (id) {
      case 'binance':
        setCurrentGasValues(bnbGasValue);
        break;
      case 'polygon':
        setCurrentGasValues(maticGasValue);
        break;
      case 'cronos':
        setCurrentGasValues(cronosGasValue);
        break;
      default:
        setCurrentGasValues(ethGasValues);
    }
  }, [id, bnbGasValue, maticGasValue, ethGasValues, cronosGasValue]);

  const bnbGasPrice = React.useCallback(() => {
    if (id === 'binance') {
      fetch('https://api.bscscan.com/api?module=gastracker&action=gasoracle')
        .then((result) => result.json())
        .then((result) => {
          setBnbGasValues({
            fast: (+result.result.FastGasPrice).toFixed(1),
            standard: (+result.result.ProposeGasPrice).toFixed(1),
            slow: (+result.result.SafeGasPrice).toFixed(1)
          });
        });
    }
  }, []);

  const cronosGasPrice = React.useCallback(async () => {
    const response = await axios.post(
      'https://evm-cronos.crypto.org/',
      {
        jsonrpc: '2.0',
        id: 1,
        method: 'eth_gasPrice',
        params: []
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      }
    );
    const value = Web3.utils.fromWei(response?.data?.result, 'gwei');

    setCronosGasValues({
      fast: '',
      standard: (+value).toFixed(1),
      slow: ''
    });
  }, []);

  const maticGasPrice = React.useCallback(() => {
    fetch('https://gasstation-mainnet.matic.network')
      .then((result) => result.json())
      .then((result) => {
        setMaticGasValues({
          fast: (+result.fast).toFixed(1),
          standard: (+result.standard).toFixed(1),
          slow: (+result.safeLow).toFixed(1)
        });
      });
  }, []);

  const warning =
    id === 'ethereum' &&
    currentGasValue.standard! >= +process.env.REACT_APP_CRITICAL_GAS_PRICE!;

  return (
    <div className={cx(k.gasPrice, warning ? k.warning : '')}>
      {id === 'ethereum' ? (
        <ExternalLink className={k.gasLink} href="https://ethgasstation.info">
          <span className={k.value}>Low - {currentGasValue.slow}</span>
          <span className={k.separator}> | </span>
          <span className={k.value}>Standard - {currentGasValue.standard}</span>
          <span className={k.separator}> | </span>
          <span className={k.value}>Fast - {currentGasValue.fast}</span>
        </ExternalLink>
      ) : (
        <>
          <span className={k.value}>Low - {currentGasValue.slow}</span>
          <span className={k.separator}> | </span>
          <span className={k.value}>Standard - {currentGasValue.standard}</span>
          <span className={k.separator}> | </span>
          <span className={k.value}>Fast - {currentGasValue.fast}</span>
        </>
      )}
    </div>
  );
};

export default GasPrice;
