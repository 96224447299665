import React, { useState, useEffect, useContext } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { Spin } from 'antd';
import * as Antd from 'antd';
import BigNumber from 'bignumber.js';
import { formatDistance } from 'date-fns';
import { ColumnsType } from 'antd/lib/table/interface';
import { formatBigValue, getHumanValue } from 'web3/utils';

import { SWAPPTokenMeta } from 'web3/contracts/swapp';
import { LoadingOutlined } from '@ant-design/icons';

import { PoolTxListContext } from 'views/pools/components/pool-tx-list-provider';
import PaginationElement from 'components/pagination';

import { chainArrays, BridgeTransactionsStatuses } from '../../../../constants';

import k from './styles.module.css';
import s from '../../../pools/components/pool-token-row/styles.module.css';
import m from '../../../pools/components/pool-transaction-table/styles.module.css';

const Columns: ColumnsType<any> = [
	{
		title: 'Date',
		dataIndex: 'date',
		render: (value: number) => (
			<>
				<p className={s.firstLine}>
					{formatDistance(new Date(value * 1000), new Date(), {
						addSuffix: true,
					})}
				</p>
				<p>{moment.unix(value).format('ll')}</p>
			</>
		),
	},
	{
		title: 'Amount',
		dataIndex: 'amount',
		render: (value: number) => (
			<>
				<div className={m.amountWrapper}>
					<span className={cx(m.icon, k.icon)}>{SWAPPTokenMeta.icon}</span>
					{formatBigValue(
						getHumanValue(new BigNumber(value), SWAPPTokenMeta.decimals)
					)}
				</div>
			</>
		),
	},
	{
		title: 'From Network',
		dataIndex: 'fromNetworkId',
		render: (value: string) => (
			<div className={cx(m.amountWrapper, k.amountWrapper)}>
				<span className={m.icon}>
					{chainArrays.find((item) => item.bridgeId === +value)?.icon}
				</span>
				<span>
					{chainArrays.find((item) => item.bridgeId === +value)?.label}
				</span>
			</div>
		),
	},
	{
		title: 'To Network',
		dataIndex: 'toNetworkId',
		render: (value: string) => (
			<div className={cx(m.amountWrapper, k.amountWrapper)}>
				<span className={m.icon}>
					{chainArrays.find((item) => item.bridgeId === +value)?.icon}
				</span>
				<span>
					{chainArrays.find((item) => item.bridgeId === +value)?.label}
				</span>
			</div>
		),
	},
	{
		title: 'Status',
		dataIndex: 'status',
		render: (value: string) => (
			<div className={cx(m.amountWrapper, k.amountWrapper)}>
				<span>{BridgeTransactionsStatuses[+value]}</span>
			</div>
		),
	},
];

interface BridgeTransactionsTableType {
	reloadTransactionsList: boolean;
	resetReloader(): void;
	networkId: number;
}

const BridgeTransactionsTable: React.FunctionComponent<BridgeTransactionsTableType> = ({
	reloadTransactionsList,
	resetReloader,
	networkId,
}) => {
	const [data, setData]: any = useState({});
	const [arrayLength, setArrayLength] = useState(0);
	const [loader, setLoader] = useState(false);
	const [paginationValues, setPaginationValues] = useState({
		minValue: 0,
		maxValue: 10,
	});
	const { setPagination, setExclude } = useContext(PoolTxListContext);

	useEffect(() => {
		setLoader(true);
		const url =
			process.env.REACT_APP_WEB3_CHAIN_ID === '1'
				? 'https://api.swapp.ee'
				: 'https://swapp-dev-api.hubio.dev';
		fetch(`${url}/migration?size=10&page=${paginationValues.maxValue / 10}`)
			.then((response) => response.json())
			.then((data) => setData(data))
			.then(() => setLoader(false));
		resetReloader();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationValues, reloadTransactionsList, networkId]);

	useEffect(() => {
		if (data.count!) setArrayLength(data.count);
		resetReloader();
	}, [data.count]);

	const handlePaginationValues = (minValue: number, maxValue: number) => {
		setPaginationValues({ minValue, maxValue });
		setPagination(minValue);
	};

	return (
		<div className={cx(m.component, k.componentTable)}>
			{loader ? (
				<div className={m.preloader_custom}>
					<Spin indicator={<LoadingOutlined style={{ fontSize: 62 }} spin />} />
				</div>
			) : (<>
					<Antd.Table
						className={cx(m.table, k.table)}
						columns={Columns}
						rowKey='id'
						dataSource={data.rows}
						scroll={{ x: true }}
						locale={{
							emptyText: (
								<div className={m.emptyBlock}>
									<div className={m.emptyLabel}>
										There are no transactions to show
									</div>
									<div className={m.startEarning}>
										Start earning iterest by creating your first stake
									</div>
								</div>
							),
						}}
						showSorterTooltip={false}
						pagination={false}
					/>
					<PaginationElement
						arrayLength={arrayLength}
						handlePaginationValues={handlePaginationValues}
					/>
				</>
			)}

		</div>
	);
};

export default BridgeTransactionsTable;
