import React from 'react';
import * as Antd from 'antd';
import { ModalProps } from 'antd/lib/modal';

import { formatSWAPPValue, getPoolIcons, getPoolNames, PoolTypes } from 'web3/utils';
import { useWeb3Contracts } from 'web3/contracts';

import IconsSet from 'components/icons-set';

import s from './styles.module.css';

export type PoolHarvestModalProps = ModalProps & {};

const PoolHarvestModal: React.FunctionComponent<PoolHarvestModalProps> = props => {
  const { ...modalProps } = props;

  const { yf, yfLP, yfNewSWAPP, swapp } = useWeb3Contracts();
  const [yfHarvesting, setYFHarvesting] = React.useState<boolean>(false);
  const [yfLPHarvesting, setYFLPHarvesting] = React.useState<boolean>(false);
  const [yfSWAPPHarvesting, setYFSWAPPHarvesting] = React.useState<boolean>(false);

  async function handleYFHarvest() {
    setYFHarvesting(true);

    try {
      await yf.massHarvestSend();
      swapp.reload();
    } catch (e) {
    }

    setYFHarvesting(false);
  }

  async function handleYFLPHarvest() {
    setYFLPHarvesting(true);

    try {
      await yfLP.massHarvestSend();
      swapp.reload();
    } catch (e) {
    }

    setYFLPHarvesting(false);
  }

  async function handleYFSWAPPHarvest() {
    setYFSWAPPHarvesting(true);

    try {
      await yfNewSWAPP.massHarvestSend();
      swapp.reload();
    } catch (e) {
    }

    setYFSWAPPHarvesting(false);
  }

  return (
    <Antd.Modal
      className={s.component}
      centered
      closable
      footer={[]}
      {...modalProps}
    >
      <div className={s.header}>Claim your reward</div>
      <div className={s.note}>Select the pool you want to claim your reward from</div>
      <div className={s.body}>
        <div className={s.option}>
          <div className={s.optionIconsWrapper}>
            <IconsSet className={s.optionIcons} icons={getPoolIcons(PoolTypes.STABLE)} />
          </div>
          <div className={s.optionLabel}>{getPoolNames(PoolTypes.STABLE).join('/')}</div>
          <div className={s.optionRewardLabel}>Reward</div>
          <div className={s.optionRewardValue}>
            {
              yf?.currentReward ? formatSWAPPValue(yf?.currentReward) + " SWAPP" :
              "0 SWAPP"
            }
          </div>
          <div className={s.claimBtnWrapper}>
            <Antd.Button
              disabled={yf?.currentReward?.isEqualTo(0) !== false}
              onClick={handleYFHarvest}
              loading={yfHarvesting}
            >
              Claim
            </Antd.Button>
          </div>
        </div>
        <div className={s.option}>
          <div className={s.optionIconsWrapper}>
            <IconsSet className={s.optionIcons} icons={getPoolIcons(PoolTypes.UNILP)} />
          </div>
          <div className={s.optionLabel}>{getPoolNames(PoolTypes.UNILP).join('/')}</div>
          <div className={s.optionRewardLabel}>Reward</div>
          <div className={s.optionRewardValue}>
            {
              yfLP?.currentReward ? formatSWAPPValue(yfLP?.currentReward) + " SWAPP" :
              "0 SWAPP"
            }
          </div>
          <div className={s.claimBtnWrapper}>
            <Antd.Button
              type="ghost"
              loading={yfLPHarvesting}
              disabled={yfLP?.currentReward?.isEqualTo(0) !== false}
              onClick={handleYFLPHarvest}>
              Claim
            </Antd.Button>
          </div>
        </div>
        <div className={s.option}>
          <div className={s.optionIconsWrapper}>
            <IconsSet className={s.optionIcons} icons={getPoolIcons(PoolTypes.SWAPP)} />
          </div>
          <div className={s.optionLabel}>{getPoolNames(PoolTypes.SWAPP).join('/')}</div>
          <div className={s.optionRewardLabel}>Reward</div>
          <div className={s.optionRewardValue}>
            {
              yfNewSWAPP?.currentReward ? formatSWAPPValue(yfNewSWAPP?.currentReward) + " SWAPP" :
              "0 SWAPP"
            }
          </div>
          <div className={s.claimBtnWrapper}>
            <Antd.Button
              type="ghost"
              loading={yfSWAPPHarvesting}
              disabled={yfNewSWAPP?.currentReward?.isEqualTo(0) !== false}
              onClick={handleYFSWAPPHarvest}>
              Claim
            </Antd.Button>
          </div>
        </div>
      </div>
    </Antd.Modal>
  );
};

export default PoolHarvestModal;
