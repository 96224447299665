import React, { useState } from 'react';
import { Pagination } from 'antd';
import { isMobile } from 'react-device-detect';

import s from './styles.module.css';

export type PaginationProps = {
	numberEachPage?: number;
	minimumValue?: number;
	maximumValue?: number;
	arrayLength: number;
	handlePaginationValues: Function;
};

const PaginationElement: React.FunctionComponent<PaginationProps> = ({
	numberEachPage = 10,
	minimumValue = 0,
	maximumValue = 10,
	arrayLength,
	handlePaginationValues,
}) => {
	const [numEachPage, setNumEachPage] = useState(numberEachPage);
	const [minValue, setMinValue] = useState(minimumValue);
	const [maxValue, setMaxValue] = useState(maximumValue);

	const handlePagination = (value: number) => {
		setMinValue((value - 1) * numEachPage);
		setMaxValue(value * numEachPage);
		handlePaginationValues((value - 1) * numEachPage, value * numEachPage);
	};

	return (
		<div className={s.paginationBlock}>
			<div className={s.paginationInfo}>
				{!isMobile && 'Showing'} {minValue + 1} to{' '}
				{maxValue <= arrayLength ? maxValue : arrayLength} out of {arrayLength}{' '}
				{!isMobile && 'transactions'}
			</div>
			<Pagination
				defaultPageSize={numEachPage}
				onChange={handlePagination}
				defaultCurrent={1}
				total={arrayLength}
				simple={!!isMobile}
			/>
		</div>
	);
};

export default PaginationElement;
