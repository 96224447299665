import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useHistory } from "react-router";
import { isMobile } from 'react-device-detect';

import EthGasPriceProvider from 'context/useEthGas';
import { useWallet } from 'wallets/wallet';
import { useWarnings } from 'components/warnings';
import PoolStats from 'views/pools/components/pool-stats';
import PoolStak from 'views/pools/components/pool-stak';
// import PoolOverview from 'views/pools/components/pool-overview';
import PoolTxListProvider from 'views/pools/components/pool-tx-list-provider';
import ComingSoonModal from "components/coming-soon-modal";

import s from './styles.module.css';
import PoolOverviewSection from './components/poolOverview';

const PoolsView: React.FunctionComponent = () => {
  const history = useHistory();

  const wallet = useWallet();
  const warnings = useWarnings();

  React.useEffect(() => {
    let warningDestructor: Function;

    if (isMobile) {
      warningDestructor = warnings.addWarn({
        text: 'Transactions can only be made from the desktop version using Metamask',
        closable: true,
        // storageIdentity: 'bb_desktop_metamask_tx_warn',
      });
    } else {
      warningDestructor = warnings.addWarn({
        text: 'Do not send funds directly to the contract!',
        closable: true,
        // storageIdentity: 'bb_send_funds_warn',
      });
    }

    return () => {
      warningDestructor?.();
    };
  }, [isMobile]); // eslint-disable-line react-hooks/exhaustive-deps

  function goBack() {
    history.replace("/pools");
  }

  return (
    <div className={s.view}>
      <PoolStats />
      <EthGasPriceProvider>
        <PoolTxListProvider>
          <Switch>
            <Route path="/pools" exact render={() => (
              <PoolOverviewSection />
            )} />
            {wallet.isActive && (
              <>
                <Route path="/pools/stable" exact render={() => (
                  <PoolStak stableToken />
                )} />
                <Route path="/pools/unilp" exact render={() => (
                  <PoolStak unilpToken />
                )} />
                <Route path="/pools/swapp" exact render={() => (
                  <PoolStak swappToken />
                )} />
              </>
            )}
          </Switch>
        </PoolTxListProvider>
      </EthGasPriceProvider>
      <ComingSoonModal
        visible={false}
        message={<span>Staking will be active next day after <a rel="noopener noreferrer" target="_self" href="/reserve">30 DAY Auction</a> is completed</span>}
        onCancel={() => {goBack()}} />
    </div>
  );
};

export default PoolsView;
