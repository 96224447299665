import React from "react";
import { useHistory } from "react-router";
import * as Antd from "antd";
import LinkModal from "components/link-modal";
import { useWallet } from 'wallets/wallet';

import { ReactComponent as RefreshSvg } from "resources/svg/icons/refresh-cw.svg";

import s from "./styles.module.css";

export type ReferralProps = {};

const Referral: React.FunctionComponent<ReferralProps> = (props) => {
  const history = useHistory();
  const wallet = useWallet();

  const [showLinkModal, setShowLinkModal] = React.useState(false);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function goBack() {
    history.replace("/pools");
  }

  return (
    <div>
      <div className={s.titleHeader}>
        <Antd.Button
          type="link"
          className={s.headerLabel}
          onClick={goBack}
        >
          Swapp Referrals
        </Antd.Button>
      </div>
      <div className={s.referralMain}>
        <div className={s.header}>
          <Antd.Tooltip
            placement="right"
            title="0% Progress $0.00 out of $10,000 referred"
          >
            <div className={s.statusWrapper}>
              <div className={s.statusText}>
                {/* <span className={s.notAchieved}>STATUS NOT ACHIEVED</span> */}
                <span className={s.criticalMassStatus}>Influencer Status</span>
              </div>
            </div>
          </Antd.Tooltip>
          <RefreshSvg className={s.refreshBtn} />
        </div>
        <div className={s.main}>
          <div className={s.noReferral}>
            <div style={{ marginTop: "80px" }}>
              <h4 className={s.dontHave}>
                You don't have influencer referrer status yet
              </h4>
              <p className={s.startPromoting}>
                Start promoting SWAPP by creating your referral link
              </p>
            </div>
            <Antd.Button
              className={s.createReferralBtn}
              onClick={() => {
                if (wallet.isActive) {
                  setShowLinkModal(true);
                } else {
                  wallet.showWalletsModal();
                }
              }}
            >
              Create Swapp Referral link
            </Antd.Button>
          </div>
        </div>
      </div>
      <LinkModal visible={showLinkModal} setShowLinkModal={setShowLinkModal} />
    </div>
  );
};

export default Referral;
