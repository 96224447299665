import React, { useState, useEffect } from 'react';
import * as Antd from 'antd';
import { useWeb3Contracts } from 'web3/contracts';
import BigNumber from 'bignumber.js';
import { numberWithCommas, getYearDurationBonus, getMonthlyReward } from 'functions/helpers';
import cx from 'classnames';

import { useTheme } from 'components/theme-provider';
import NumericInput from 'components/numeric-input';
import InfoTooltip from 'components/info-tooltip';

import s from './styles.module.css';

export type BonusCalculatorProps = {
  visible: boolean,
  hideInput: boolean,
  setVisibility?: Function,
};

const BonusCalculator: React.FunctionComponent<BonusCalculatorProps> = ({ visible, setVisibility, hideInput }) => {

  const { yfNewSWAPP, stakingSwapp, uniswap } = useWeb3Contracts();
  const [amount, setAmount] = useState<BigNumber | undefined>(new BigNumber(0));
  const [modalVisibility, setModalVisibility] = useState(false);
  const { toggleDarkTheme, isDarkTheme } = useTheme();

  useEffect(() => {
    if (hideInput) setAmount(new BigNumber(1000000));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getBonusCounter = () => {
    let output  = [];
    for (let i = 1; i < 6; i++) {
      output.push(
        <div className={s.tableRow}>
          <div className={s.balanceValue}>per {i * 12} epoch</div>
          <div className={cx(s.balanceValue, s.apy)}>{numberWithCommas(getYearDurationBonus(i, amount!, stakingSwapp!, yfNewSWAPP!, uniswap.swappPrice!).toFixed(2))} %</div>
          <div className={cx(s.balanceValue, s.apy)}>{numberWithCommas(getMonthlyReward(i, amount!, stakingSwapp!, yfNewSWAPP!, uniswap.swappPrice!).toFixed(2))} %</div>
        </div>
      )
    }
    return output;
  }

  return (
    <div className={s.row3}>
      <div className={s.titleWrapper}>
        <div className={s.balanceTitile}>Pool 3 Potential Rewards Calculator</div>
        {hideInput && 
          <InfoTooltip title={<span>Calculation per 1,000,000 staked SWAPP</span>} />
        }
      </div>
      {!hideInput &&
        <div className={s.inputValue}>
          <NumericInput
            placeholder='0'
            value={amount}
            onChange={(value: any) => setAmount(value)}
          />
        </div>
      }
      <div className={s.tableWrapper}> 
        <div className={s.tableHeader}>
          <div className={s.balanceLabel}>TIMEFRAME</div>
          <div className={s.balanceLabel}>ACCUMULATED DURATION BONUS</div>
          <div className={s.balanceLabel}>ACCUMULATED MONTHLY REWARD</div>
        </div>
        {getBonusCounter()}
      </div>
      <ul className={s.infoList}>
        <li> Calculated based on current rates and conservatively projected prices for other years.
          <ul>
            <li>1st - 12 EPOCH – Current Price</li>
            <li>13th - 24th EPOCH – 0.20 USDC</li>
            <li>25th – 36th EPOCH - 0.75 USDC</li>
            <li>37th – 48th EPOCH – 0.95 USDC</li>
            <li>49th – 60th EPOCH – 1.00 USDC</li>
          </ul>
        </li>
        <li>All figures are estimates provided for your convenience only and do not represent a promised rate of return. We are not financial advisors, and this is not financial advice.</li>
        <li>These prices can change at any point of time these were used only to estimate APY.</li>
        <li>PLEASE DO YOUR OWN RESEARCH.</li>
      </ul>

      <div
        className={s.link}
        onClick={() => setModalVisibility(true)}              
      >
        Get SWAPP &gt;
      </div>
      <Antd.Modal
        centered
        closable
        onCancel={() => setModalVisibility(false)}
        footer={[]}
        visible={modalVisibility}
      >
        <iframe
          title='widget'
          src={`https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x8CB924583681cbFE487A62140a994A49F833c244&use=v2&theme=${isDarkTheme ? 'dark' : 'light'}`}
          height="600px"
          width="100%"
          style={{
            border: 0,
            margin: '40px auto 0',
            display: 'block',
            borderRadius: 10,
            maxWidth: 600,
            minWidth: 300,
          }}
          id="widget"
        />
      </Antd.Modal>
    </div>
  );
};

export default BonusCalculator;
