import React from 'react';
import { useHistory } from 'react-router';
import * as Antd from 'antd';

import IconsSet from 'components/icons-set';
import PoolTokenRow from 'views/pools/components/pool-token-row';
import PoolTransactionTable from 'views/pools/components/pool-transaction-table';
import { useEthGasPrice } from 'context/useEthGas';

import { getPoolIcons, getPoolNames, PoolTypes } from 'web3/utils';
import { USDCTokenMeta } from 'web3/contracts/usdc';
import { USDTTokenMeta } from 'web3/contracts/usdt';
import { DAITokenMeta } from 'web3/contracts/dai';
import { UNISWAPTokenMeta } from 'web3/contracts/uniswap';
import { SWAPPTokenMeta } from 'web3/contracts/swapp';
import { useWeb3Contracts } from 'web3/contracts';
import { useWeekCountdown } from 'hooks/useCountdown';

import s from './styles.module.css';

export type PoolStakProps = {
  stableToken?: boolean;
  unilpToken?: boolean;
  swappToken?: boolean;
};

const PoolStak: React.FunctionComponent<PoolStakProps> = props => {
  const ethGasPrice = useEthGasPrice();
  const history = useHistory();

  const { stakingSwapp } = useWeb3Contracts();
  const [, epochEndSwapp] = stakingSwapp.getEpochPeriod(stakingSwapp.currentEpoch!) ?? [];
  const [untilNextEpochSwapp] = useWeekCountdown(epochEndSwapp, stakingSwapp.currentEpoch);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    ethGasPrice.load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function goBack() {
    history.replace('/pools');
  }

  const icons = React.useMemo<React.ReactNode[]>(() => {
    if (props.stableToken) {
      return getPoolIcons(PoolTypes.STABLE);
    } else if (props.swappToken) {
      return getPoolIcons(PoolTypes.SWAPP);
    }

    return getPoolIcons(PoolTypes.UNILP);
  }, [props]);

  const swappEpochCoundown = React.useMemo<string>(() => {
    if (props.swappToken) {
      return `${untilNextEpochSwapp}`;
    }

    return '-';
  }, [props, untilNextEpochSwapp]);

  return (
    <div className={s.component}>
      <Antd.Button
        type="link"
        className={s.headerLabel}
        onClick={goBack}
      >
        &#60; Go back to Pools
      </Antd.Button>

      <div className={s.row1}>
        <div className={s.poolIcon}>
          <IconsSet icons={icons} />
        </div>
        <div className={s.poolName}>
          <div>
            {props.stableToken && getPoolNames(PoolTypes.STABLE).join('/')}
            {props.unilpToken && getPoolNames(PoolTypes.UNILP).join('/')}
            {props.swappToken && getPoolNames(PoolTypes.SWAPP).join('/')}
          </div>
          <div>
          {props.swappToken && 
            <div className={s.pool_epoch_countdown}>
              {`${swappEpochCoundown} until next epoch `}
            </div>
          }
          </div>
        </div>
      </div>

      <div className={s.dataRows}>
        {props.stableToken && (
          <>
            <PoolTokenRow stableToken token={USDCTokenMeta} type="deposit" />
            <PoolTokenRow stableToken token={USDTTokenMeta} type="deposit" />
            <PoolTokenRow stableToken token={DAITokenMeta} type="deposit" />
          </>
        )}
        {props.unilpToken && (
          <PoolTokenRow unilpToken token={UNISWAPTokenMeta} type="deposit" />
        )}
        {props.swappToken && (
          <PoolTokenRow swappToken token={SWAPPTokenMeta} type="deposit" />
        )}
      </div>
      <PoolTransactionTable
        className={s.table}
        label="Transactions"
        ownTransactions
        stableToken={props.stableToken}
        unilpToken={props.unilpToken}
        swappToken={props.swappToken} 
      />
    </div>
  );
};

export default PoolStak;
