import React, { FunctionComponent, useState } from 'react';
import * as Antd from 'antd';

// import DailyBreakdown from './components/daily-breakdown';
// import Contributedeth from './components/eth-contributed';
// import QuickMenuDashboard from './components/quick-menu';
import LinkModal from 'components/link-modal';
import StakingChart from './components/stakingСhart';

import s from './styles.module.css';

interface DashboardProps {}

const Dashboard: FunctionComponent<DashboardProps> = () => {
	const [showLinkModal, setShowLinkModal] = useState(false);
	return (
		<div className={s.dashboardView}>
			<Antd.Row className={s.titleRow}>
				{/* <Antd.Col xs={12} md={16} lg={21}>
         <h2 className={s.title}>Dashboard</h2>
       </Antd.Col>
        <Antd.Col xs={12} md={6} lg={3} className={s.quickMenuWrapper}>
          <QuickMenuDashboard setShowLinkModal={setShowLinkModal} />
        </Antd.Col> */}
				<Antd.Col xs={24} md={24} lg={24} className={s.chartWrapper}>
					<StakingChart />
				</Antd.Col>
			</Antd.Row>
			{/* <Contributedeth/> */}
			{/* <DailyBreakdown/> */}
			<LinkModal visible={showLinkModal} setShowLinkModal={setShowLinkModal} />
		</div>
	);
};

export default Dashboard;
