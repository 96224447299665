import React, { useState } from 'react';
import { useHistory } from 'react-router';
import * as Antd from 'antd';

import InfoTooltip from 'components/info-tooltip';
import IconsSet from 'components/icons-set';
import CalcModalWrapper from '../pool-bonus-calculator/modal-wrapper';

import {
	formatBigValue,
	formatSWAPPValue,
	formatUSDValue,
	getPoolIcons,
	getPoolNames,
	PoolTypes,
	ZERO_BIG_NUMBER,
} from 'web3/utils';
import { useWallet } from 'wallets/wallet';
import { useWeb3Contracts } from 'web3/contracts';
import { USDCTokenMeta } from 'web3/contracts/usdc';
import { USDTTokenMeta } from 'web3/contracts/usdt';
import { DAITokenMeta } from 'web3/contracts/dai';
import { UNISWAPTokenMeta } from 'web3/contracts/uniswap';
import { SWAPPTokenMeta } from 'web3/contracts/swapp';
import { useWeekCountdown } from 'hooks/useCountdown';
import {
	numberWithCommas,
	getYearDurationBonus,
	getMonthlyReward,
} from 'functions/helpers';
import { WBTCTokenMeta } from 'web3/contracts/wbtc';
import { ReactComponent as CalcSVG } from '../../../../resources/svg/icons/calculator.svg';

import s from './styles.module.css';
import BigNumber from 'web3-core/node_modules/bignumber.js';

export type PoolCardProps = {
	stableToken?: boolean;
	unilpToken?: boolean;
	swappToken?: boolean;
	isDisable?: boolean;
};

type TokenBalanceShare = {
	icon: React.ReactNode;
	name: string;
	value: string;
	share: number;
	color: string;
};

const PoolCardItem: React.FunctionComponent<PoolCardProps> = (props) => {
	const [calcWidgetVisible, setCalcWidgetVisible] = useState(false);

	const history = useHistory();
	const wallet = useWallet();
	const {
		yf,
		yfLP,
		yfSWAPP,
		yfNewSWAPP,
		staking,
		aggregated,
		uniswap,
		stakingSwapp,
	} = useWeb3Contracts();

	const {
		stableToken = false,
		unilpToken = false,
		swappToken = false,
		isDisable = false,
	} = props;

	const [, epochEndSwapp] =
		stakingSwapp.getEpochPeriod(stakingSwapp.currentEpoch!) ?? [];
	const [untilNextEpochSwapp] = useWeekCountdown(
		epochEndSwapp,
		stakingSwapp.currentEpoch
	);

	const icons = React.useMemo<React.ReactNode[]>(() => {
		if (stableToken) {
			return getPoolIcons(PoolTypes.STABLE);
		} else if (unilpToken) {
			return getPoolIcons(PoolTypes.UNILP);
		} else if (swappToken) {
			return getPoolIcons(PoolTypes.SWAPP);
		}

		return [];
	}, [stableToken, unilpToken, swappToken]);

	const nameLabel = React.useMemo<string>(() => {
		if (stableToken) {
			return getPoolNames(PoolTypes.STABLE).join('/');
		} else if (unilpToken) {
			return getPoolNames(PoolTypes.UNILP).join('/');
		} else if (swappToken) {
			return getPoolNames(PoolTypes.SWAPP).join('/') + ' Staking';
		}

		return '-';
	}, [stableToken, unilpToken, swappToken]);

	const epochLabel = React.useMemo<string>(() => {
		if (stableToken) {
			return `Epoch ${yf.currentEpoch ?? '-'}/${yf.totalEpochs ?? '-'}`;
		} else if (unilpToken) {
			return `Epoch ${yfLP.currentEpoch ?? '-'}/${yfLP.totalEpochs ?? '-'}`;
		} else if (swappToken) {
			return `Epoch ${yfNewSWAPP.currentEpoch ?? '-'}/${
				yfNewSWAPP.totalEpochs ?? '-'
			}`;
		}

		return '-';
	}, [stableToken, unilpToken, swappToken, yf, yfLP, yfNewSWAPP]);

	const swappEpochCoundown = React.useMemo<string>(() => {
		if (swappToken) {
			return `${untilNextEpochSwapp}`;
		}

		return '';
	}, [swappToken, untilNextEpochSwapp]);

	const isStakingEnabled = React.useMemo<boolean>(() => {
		if (yfSWAPP && wallet.networkName === 'Mainnet') {
			return yfSWAPP.currentSwappDay! > 0;
		}
		if (wallet.networkName !== 'Mainnet') return false;
		return true;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [yfSWAPP]);

	const epochReward = React.useMemo<string>(() => {
		if (stableToken) {
			if (yf.currentEpoch && yf.currentEpoch > 0) {
				return `${formatSWAPPValue(yf.epochReward)}`;
			} else {
				return `${formatSWAPPValue(ZERO_BIG_NUMBER)}`;
			}
		} else if (unilpToken) {
			return `${formatSWAPPValue(yfLP.epochReward)}`;
		} else if (swappToken) {
			return `${formatSWAPPValue(yfNewSWAPP.epochReward)}`;
		}

		return '-';
	}, [stableToken, unilpToken, swappToken, yf, yfLP, yfSWAPP]);

	const totalReward = React.useMemo<string>(() => {
		if (stableToken) {
			return `${formatSWAPPValue(yf.totalReward)} SWAPP`;
		} else if (unilpToken) {
			return `${formatSWAPPValue(yfLP.totalReward)} SWAPP`;
		} else if (swappToken) {
			return `${formatSWAPPValue(yfNewSWAPP.totalReward)} SWAPP`;
		}

		return '-';
	}, [stableToken, unilpToken, swappToken, yf, yfLP, yfNewSWAPP]);

	const potentialReward = React.useMemo<string>(() => {
		if (stableToken) {
			return `${formatSWAPPValue(yf.potentialReward)} SWAPP`;
		} else if (unilpToken) {
			return `${formatSWAPPValue(yfLP.potentialReward)} SWAPP`;
		} else if (swappToken) {
			if (stakingSwapp.currentEpoch! >= stakingSwapp?.stakes?.endEpoch!)
				return '0 SWAPP';
			return `${formatSWAPPValue(yfNewSWAPP.potentialReward)} SWAPP`;
		}

		return '-';
	}, [stableToken, unilpToken, swappToken, yf, yfLP, yfNewSWAPP]);

	const balance = React.useMemo<string | React.ReactNode>(() => {
		if (stableToken) {
			return formatUSDValue(aggregated.yfStakedValue);
		} else if (unilpToken) {
			return formatUSDValue(aggregated.yfLPStakedValue);
		} else if (swappToken) {
			return formatUSDValue(aggregated.yfNewSWAPPStakedValue);
		}

		return '-';
	}, [stableToken, unilpToken, swappToken, aggregated]);

	const effectiveBalance = React.useMemo<string | React.ReactNode>(() => {
		if (stableToken) {
			return formatUSDValue(aggregated.yfEffectiveStakedValue);
		} else if (unilpToken) {
			return formatUSDValue(aggregated.yfLPEffectiveStakedValue);
		} else if (swappToken) {
			return formatUSDValue(aggregated.yfSWAPPEffectiveStakedValue);
		}

		return '-';
	}, [stableToken, unilpToken, swappToken, aggregated]);

	const balanceShares = React.useMemo<TokenBalanceShare[]>(() => {
		const shares: TokenBalanceShare[] = [];

		if (stableToken) {
			if (yf.nextPoolSize) {
				const usdcShare = staking.usdc.nextEpochPoolSize
					?.multipliedBy(100)
					.div(yf.nextPoolSize)
					.toNumber();

				if (usdcShare) {
					shares.push({
						icon: USDCTokenMeta.icon,
						name: USDCTokenMeta.name,
						value: formatUSDValue(staking.usdc.nextEpochPoolSize),
						share: usdcShare,
						color: '#1286E3',
					});
				}

				const usdtShare = staking.usdt.nextEpochPoolSize
					?.multipliedBy(100)
					.div(yf.nextPoolSize)
					.toNumber();

				if (usdtShare) {
					shares.push({
						icon: USDTTokenMeta.icon,
						name: USDTTokenMeta.name,
						value: formatUSDValue(staking.usdt.nextEpochPoolSize),
						share: usdtShare,
						color: '#0FCB7C',
					});
				}

				const daiShare = staking.dai.nextEpochPoolSize
					?.multipliedBy(100)
					.div(yf.nextPoolSize)
					.toNumber();

				if (daiShare) {
					shares.push({
						icon: DAITokenMeta.icon,
						name: DAITokenMeta.name,
						value: formatUSDValue(staking.dai.nextEpochPoolSize),
						share: daiShare,
						color: '#F0AD00',
					});
				}
			}
		} else if (unilpToken) {
			if (yfLP.nextPoolSize) {
				const uniShare = staking.uniswap.nextEpochPoolSize
					?.multipliedBy(100)
					.div(yfLP.nextPoolSize)
					.toNumber();

				if (uniShare) {
					shares.push({
						icon: UNISWAPTokenMeta.icon,
						name: UNISWAPTokenMeta.name,
						// value: formatBigValue(yfLP.nextPoolSize, UNISWAPTokenMeta.decimals),
						value: formatBigValue(yfLP.nextPoolSize, 2),
						share: uniShare,
						color: 'var(--text-color-3)',
					});
				}
			}
		} else if (swappToken) {
			if (yfNewSWAPP.nextPoolSize) {
				const swappShare = stakingSwapp.swapp.nextEpochPoolSize
					?.multipliedBy(100)
					.div(yfNewSWAPP.nextPoolSize)
					.toNumber();

				if (swappShare) {
					shares.push({
						icon: SWAPPTokenMeta.icon,
						name: SWAPPTokenMeta.name,
						value: formatBigValue(yfNewSWAPP.nextPoolSize, 2),
						share: swappShare,
						color: 'var(--text-color-3)',
					});
				}
			}
		}

		return shares;
	}, [stableToken, unilpToken, swappToken, yf, yfLP, yfSWAPP, staking]);

	const myBalance = React.useMemo<string | React.ReactNode>(() => {
		if (stableToken) {
			return formatUSDValue(yf.nextEpochStake);
		} else if (unilpToken && yfLP.nextEpochStake) {
			return formatUSDValue(aggregated.myLPStakedValue);
		} else if (swappToken) {
			return formatUSDValue(aggregated.myNewSWAPPStakedValue);
		}

		return '-';
	}, [stableToken, unilpToken, swappToken, yf, yfLP, aggregated]);

	const myEffectiveBalance = React.useMemo<string | React.ReactNode>(() => {
		if (stableToken) {
			return formatUSDValue(yf.currentEpoch == 0 ? undefined : yf.epochStake);
		} else if (unilpToken && yfLP.epochStake) {
			return formatUSDValue(aggregated.myLPEffectiveStakedValue);
		} else if (swappToken && yfNewSWAPP.epochStake) {
			return formatUSDValue(aggregated.mySwappEffectiveStakedValue);
		}

		return '-';
	}, [stableToken, unilpToken, swappToken, yf, yfLP, aggregated, yfNewSWAPP]);

	const myBalanceShares = React.useMemo<TokenBalanceShare[]>(() => {
		const shares: TokenBalanceShare[] = [];

		if (stableToken) {
			if (yf.nextEpochStake) {
				const usdcShare = staking.usdc.nextEpochUserBalance
					?.multipliedBy(100)
					.div(yf.nextEpochStake)
					.toNumber();

				if (usdcShare) {
					shares.push({
						icon: USDCTokenMeta.icon,
						name: USDCTokenMeta.name,
						value: formatUSDValue(staking.usdc.nextEpochUserBalance),
						share: usdcShare,
						color: '#4f6ae6',
					});
				}

				const usdtShare = staking.usdt.nextEpochUserBalance
					?.multipliedBy(100)
					.div(yf.nextEpochStake)
					.toNumber();

				if (usdtShare) {
					shares.push({
						icon: USDTTokenMeta.icon,
						name: USDTTokenMeta.name,
						value: formatUSDValue(staking.usdt.nextEpochUserBalance),
						share: usdtShare,
						color: '#50af95',
					});
				}

				const daiShare = staking.dai.nextEpochUserBalance
					?.multipliedBy(100)
					.div(yf.nextEpochStake)
					.toNumber();

				if (daiShare) {
					shares.push({
						icon: DAITokenMeta.icon,
						name: DAITokenMeta.name,
						value: formatUSDValue(staking.dai.nextEpochUserBalance),
						share: daiShare,
						color: '#ffd160',
					});
				}
			}
		} else if (unilpToken) {
			if (yfLP.nextEpochStake) {
				const uniShare = staking.uniswap.nextEpochUserBalance
					?.multipliedBy(100)
					.div(yfLP.nextEpochStake)
					.toNumber();

				if (uniShare) {
					shares.push({
						icon: UNISWAPTokenMeta.icon,
						name: UNISWAPTokenMeta.name,
						value: formatBigValue(
							yfLP.nextEpochStake,
							UNISWAPTokenMeta.decimals
						),
						share: uniShare,
						color: 'var(--text-color-3)',
					});
				}
			}
		} else if (swappToken) {
			if (yfNewSWAPP.nextEpochStake) {
				const swappShare = stakingSwapp.swapp.nextEpochUserBalance
					?.multipliedBy(100)
					.div(yfNewSWAPP.nextEpochStake)
					.toNumber();

				if (swappShare) {
					shares.push({
						icon: SWAPPTokenMeta.icon,
						name: SWAPPTokenMeta.name,
						value: formatBigValue(
							yfNewSWAPP.nextEpochStake,
							SWAPPTokenMeta.decimals
						),
						share: swappShare,
						color: 'var(--text-color-3)',
					});
				}
			}
		}

		return shares;
	}, [stableToken, unilpToken, swappToken, yf, yfLP, yfNewSWAPP, staking]);

	function handleStaking() {
		if (stableToken) {
			history.push('/pools/stable');
		} else if (unilpToken) {
			history.push('/pools/unilp');
		} else if (swappToken) {
			history.push('/pools/swapp');
		}
	}

	const getApyValue = () => {
		let res: any = 0;
		let epoch: any = 0;
		let stake: any = 0;
		let epochDurationBonus: any = 0;
		let swappPercent: number = 0;

		const swapp_price = uniswap.swappPrice?.toNumber() ?? 0;

		if (yf.currentEpoch) {
			if (stableToken) {
				if (yf.currentEpoch > 0) epoch = yf.epochReward;
				else epoch = ZERO_BIG_NUMBER;
				stake = aggregated.yfStakedValue;
			} else if (unilpToken) {
				epoch = yfLP.epochReward;
				stake = aggregated.yfLPStakedValue;
			} else if (swappToken) {
				epoch = yfNewSWAPP.epochReward;
				epochDurationBonus = yfNewSWAPP.epochDurationBonus;
				stake = aggregated.yfNewSWAPPStakedValue;

				const staticStake = 1000000;
				for (let i = 5; i < 6; i++) {
					swappPercent += getYearDurationBonus(
						i,
						staticStake,
						stakingSwapp!,
						yfNewSWAPP!,
						uniswap.swappPrice!
					);
					swappPercent += getMonthlyReward(
						i,
						staticStake,
						stakingSwapp!,
						yfNewSWAPP!,
						uniswap.swappPrice!
					);
				}
			}

			res = formatBigValue(
				new BigNumber(
					(((+epoch + +epochDurationBonus) * swapp_price) / +stake / 28) *
						365 *
						100
				),
				0
			);
		}

		if (res && +stake && swapp_price) {
			return (<>
					<div className={s.apy_body}>
						<div className={s.apyTitle}>APY{' '}</div>
						<div className={s.apyValue}>{swappToken ?
							`${(numberWithCommas(swappPercent.toFixed(0)))}%` : `${res}%`
						}</div>

						{/*{swappToken ? '5Yr APY' : 'APY'}*/}
						<InfoTooltip
							title={
								<span>
									APY calculation formula:
									<br />
									<br />
									{swappToken ? (
										<span>
											APY shown is an estimate based on a 60-epoch term
											including aggregate monthly interest, annual projected
											token price appreciation, and all 5 annual bonuses
											together. This rate cannot be guaranteed and may fluctuate
											as market conditions change
										</span>
									) : (
										<span>
											((((Pool Epoch Reward x Token Market Price)/Total Pool
											Balance)/28)x365)x100
										</span>
									)}
								</span>
							}
						/>
					</div>
					{swappToken ? (
						<CalcSVG onClick={() => setCalcWidgetVisible(true)} />
					) : null}
					{/*<div className={s.pool_epoch}>*/}
					{/*	{`${res}%`}*/}
					{/*	/!*{swappToken ? (*!/*/}
					{/*	/!*	<>*!/*/}
					{/*	/!*		<span className={s.pool_epoch_countdown}>Up to&nbsp;</span>*!/*/}
					{/*	/!*		<span>*!/*/}
					{/*	/!*			{numberWithCommas(swappPercent.toFixed(0))}%&nbsp;&nbsp;*!/*/}
					{/*	/!*		</span>*!/*/}
					{/*	/!*	</>*!/*/}
					{/*	/!*) : (*!/*/}
					{/*	/!*	`${res}%`*!/*/}
					{/*	/!*)}*!/*/}
					{/*	{swappToken ? (*/}
					{/*		<CalcSVG onClick={() => setCalcWidgetVisible(true)} />*/}
					{/*	) : null}*/}
					{/*</div>*/}
			</>);
		}
	};

	return (
		<div className={s.component}>
			<div className={s.row1}>
				<IconsSet className={s.pool_avatars} icons={icons} />
				<div className={s.pool_info}>
					<div className={s.pool_label}>{nameLabel}</div>
					<div className={s.pool_epoch}>
						{epochLabel}
						<span className={s.pool_epoch_countdown}>
							&nbsp;{swappEpochCoundown}
						</span>
					</div>
				</div>
				<div className={s.pool_apy}>
					{getApyValue()}
				</div>
			</div>

			<div className={s.row2}>
				<div className={s.reward}>
					<div className={s.row_label}>
						Reward {swappToken ? '/ Duration bonus' : ''}
						<InfoTooltip
							title={
								<span>
									Test title:
									<br />
									<br />
									<span>
										Need tooltip text
									</span>
								</span>
							}/>
					</div>
					<div className={s.row_value}>
							<span className={s.swappIcon}>{SWAPPTokenMeta.icon}</span>
							{epochReward}{' '}
							{swappToken
								? <> / <span className={s.swappIcon}>{SWAPPTokenMeta.icon}</span>{formatSWAPPValue(yfNewSWAPP.epochDurationBonus)}</>
								: ''}
					</div>
				</div>
				{wallet.isActive && (
					<Antd.Button
						type='primary'
						className={s.stakingBtn}
						disabled={!isStakingEnabled || isDisable}
						onClick={handleStaking}>
						{isDisable ? 'Coming Soon' : 'Staking'}
					</Antd.Button>
				)}
			</div>

			<div className={s.row3}>
				<div className={s.balance}>
					<div className={s.reward}>
						<div className={s.row_label}>
							<span>Pool balance</span>
							<InfoTooltip
								title={
									<span>
								This number shows the total staked balance of the pool, and the
								effective balance of the pool.
								<br />
								<br />
								When staking tokens during an epoch that is currently running,
								your effective deposit amount will be proportionally reduced by
								the time that has passed from that epoch. Once an epoch ends,
								your staked balance and effective staked balance will be the
								equal, therefore pool balance and effective pool balance will
								differ in most cases.
							</span>
								}
							/>
						</div>
						<div className={s.row_value}>{balance}</div>
					</div>
					<div className={s.row_value_2}>
						{effectiveBalance} effective balance
					</div>
				</div>
				<div className={s.pool_stak_bar}>
					{balanceShares.map((tokenShare, index) => (
						<Antd.Tooltip
							key={index}
							placement='top'
							title={
								<div className={s.balance_tooltip}>
									{unilpToken ? (
										<>
											<span className={s.coinWrapper}>
												{tokenShare.icon}&nbsp;{tokenShare.value}
											</span>
											<span className={s.coinWrapper}>
												&nbsp;&nbsp;{WBTCTokenMeta.icon}{' '}
												{numberWithCommas(+uniswap.wbtcReserve!?.toFixed(2))}
											</span>
											<span className={s.coinWrapper}>
												&nbsp;&nbsp;{SWAPPTokenMeta.icon}{' '}
												{numberWithCommas(+uniswap.swappReserve!?.toFixed(2))}
											</span>
										</>
									) : (
										<>
											<div>{tokenShare.icon}</div>
											<span>{tokenShare.name}:</span>
											<span>{tokenShare.value}</span>
										</>
									)}
								</div>
							}
						>
							<div className={s[`line${index}`]}
								style={{
									width: `${tokenShare.share}%`,
									backgroundColor: tokenShare.color,
								}}
							/>
						</Antd.Tooltip>
					))}
				</div>
			</div>

			{wallet.isActive && (
				<div className={s.row4}>
					<div className={s.balance}>
						<div className={s.reward}>
							<div className={s.row_label}>
								<span>My pool balance</span>
								<InfoTooltip
									title={
										<span>
									This number shows your total staked balance in the pool, and
									your effective staked balance in the pool.
									<br />
									<br />
									When staking tokens during an epoch that is currently running,
									your effective deposit amount will be proportionally reduced
									by the time that has passed from that epoch. Once an epoch
									ends, your staked balance and effective staked balance will be
									the equal, therefore your pool balance and your effective pool
									balance will differ in most cases.
								</span>
									}
								/>
							</div>
							<div className={s.row_value}>{myBalance}</div>
						</div>
						<div className={s.row_value_2}>
							{myEffectiveBalance} effective balance{' '}
						</div>
					</div>
					<div className={s.pool_stak_bar}>
						{myBalanceShares.map((tokenShare, index) => (
							<Antd.Tooltip
								key={index}
								placement='top'
								title={
									<div className={s.balance_tooltip}>
										<div>{tokenShare.icon}</div>
										<span>{tokenShare.name}:</span>
										<span>{tokenShare.value}</span>
									</div>
								}>
								<div
									style={{
										width: `${tokenShare.share}%`,
										backgroundColor: tokenShare.color,
									}}
								/>
							</Antd.Tooltip>
						))}
					</div>
				</div>
			)}

			{wallet.isActive && (
				<div className={s.row5}>
					<div className={s.balance}>
						<div className={s.reward}>
							<div className={s.row_label}>
								My potential reward
								<InfoTooltip
									title={
										<span>
									TEST TOOLTIP
									<br />
									<br />
									Need text for this section
								</span>
									}
								/>
							</div>
							<div className={s.row_value}>
								<span>{potentialReward}</span>
							</div>
						</div>
					</div>
				</div>
			)}
			<CalcModalWrapper
				visible={calcWidgetVisible}
				setVisibility={() => setCalcWidgetVisible(false)}
				hideInput={true}
			/>
		</div>
	);
};

export default PoolCardItem;
