import React, {FunctionComponent, useState, useEffect, useMemo} from 'react';
import * as Antd from 'antd';
import moment from "moment";

import { formatUSDValue } from 'web3/utils';
import { useWeb3Contracts } from 'web3/contracts';
import { useWeekCountdown } from 'hooks/useCountdown';
import CardWidget from 'components/card';
import ExternalLink from 'components/externalLink';

import { ReactComponent as GtArrow } from '../../../../resources/svg/icons/gt-arrow.svg';
import s from './styles.module.css';

import PersonalInfoModal, { modalTabsKey } from '../contributions-modal/index';
import StatisticWidget from "../../../../components/statistic-widget";
import {LineChartWidget} from "../../../../components/lineChartWidget";
import {Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import ProgressBarCircular from "../../../../components/progressbar-circular";
import axios from "axios";

type PersonalProps = {};

const PersonalInfo: FunctionComponent<PersonalProps> = () => {
  const { transformer, swapp, staking, uniswap, stakingSwapp } = useWeb3Contracts();
  const epochEnd = React.useMemo<number | undefined>(() => {
    const [, end] = staking.getEpochPeriod(staking.currentEpoch!) ?? [];
    return end;
  }, [staking]);

  const epochStart = React.useMemo<number | undefined>(() => {
    const [start] = staking.getEpochPeriod(staking.currentEpoch!) ?? [];
    return start;
  }, [staking]);

  const [untilNextEpoch] = useWeekCountdown(epochEnd, staking.currentEpoch);

  const [timeLeft, setTimeLeft] = useState(0);
  const [timeLeftFormat, setTimeLeftFormat] = useState(moment());
  const [modalKey, setModalKey] = useState<modalTabsKey>('contribution');
  const [contributionVisible, setContributionVisible] = React.useState<boolean>(false);
  const [swappPrice, setSwappPrice] = useState<any[]>([]);

  useEffect(()=>{
    const getPrices = async () =>{
      const {data:{prices}} = await axios.get('https://api.coingecko.com/api/v3/coins/swapp/market_chart?vs_currency=usd&days=max&interval=daily')
      return prices;
    }
    getPrices().then(res=>setSwappPrice(res));
  },[])

  useEffect(() => {
    if (transformer?.currentSwappDay && swapp.launchTime) {
      const days: number = +swapp.launchTime;
      const seconds = (3600 * 24 * 31) + days;
      setTimeLeft(seconds);
    }
  }, [transformer, swapp])

  useEffect(() => {
    if (!timeLeft) return;

    const timeout = setInterval(() => {
      setTimeLeft(timeLeft - 1);
      let now = moment();
      let end = moment.unix(timeLeft);
      let duration = moment.duration(end.diff(now));
      let days = parseInt(duration.asDays().toFixed(0));
      duration.subtract(moment.duration(days, 'days'));
      //Get hours and subtract from duration
      let hours = duration.hours();
      duration.subtract(moment.duration(hours, 'hours'));
      //Get Minutes and subtract from duration
      let minutes = duration.minutes();
      duration.subtract(moment.duration(minutes, 'minutes'));
      //Get seconds
      let seconds = duration.seconds();

      let matchDate = moment();
      matchDate.day(days)
      matchDate.hour(hours);
      matchDate.minute(minutes);
      matchDate.second(seconds);

      setTimeLeftFormat(matchDate)
    }, 1000)

    return () => clearInterval(timeout);
  }, [timeLeft]);

  const onContributionClick = (modalKey: string) => {
    if (modalKey === 'modal_contribution') {
      setModalKey('contribution');
    } else {
      setModalKey('referral');
    }
    setContributionVisible(true);
  };

  const percent = React.useMemo<number>(() => {
    if(epochEnd && epochStart){
      return Number((100-((epochEnd-Date.now())*100)/(epochEnd-epochStart)).toFixed(1));
    }
    return 0;
  }, [epochEnd,epochStart])

  const onModalCloseContributions = () => {
    setContributionVisible(false);
  };

  const getUniqueReferrals = (contract1: any, contract2: any, contract3: any, contract4: any) => {
    if (contract1 && contract2 && contract3 && contract4) {
      const uniqueArray = Array.from(new Set(contract1.concat(contract2).concat(contract3).concat(contract4)));
      return uniqueArray.length;
    } return 0;
  }

  const priceChartData = useMemo(()=>{
    return swappPrice?.map(el=>({
      value: el[1],
      name: el[0]
    }))
  },[swappPrice])

  return (
    <div className={s.personalInfoContainer}>
      <StatisticWidget
          label='Referral contributions'
          value={`${((staking.stableCoinBalance?? 0) + (staking.stableCoinBalance2Level?? 0) + (stakingSwapp.swappCoinBalance! * +uniswap.swappPrice!)??0 + ((stakingSwapp.swappCoinBalance2Level! * +uniswap.swappPrice!)??0)).toFixed(2)} USDC`}
          hint1={`${((staking.lpCoinBalance?? 0) + (staking.lpCoinBalance2Level?? 0)).toFixed(6)} LP`}
          // hint2={`effective locked`}
          // widget={lockedChartData ? <LineChartWidget data={lockedChartData} color={'#0FCB7C'}/> :
          //     <Spin
          //         indicator={<LoadingOutlined style={{ fontSize: 62 }} spin />}
          //     />}
      />
      <StatisticWidget
          label='Referrals'
          value={`${getUniqueReferrals(staking.referrals1Level!, staking.referrals2Level!, stakingSwapp.referrals1Level!, stakingSwapp.referrals2Level!)} USDC`}
          //hint1={`${((staking.lpCoinBalance?? 0) + (staking.lpCoinBalance2Level?? 0)).toFixed(6)} LP`}
          // hint2={`effective locked`}
          // widget={lockedChartData ? <LineChartWidget data={lockedChartData} color={'#0FCB7C'}/> :
          //     <Spin
          //         indicator={<LoadingOutlined style={{ fontSize: 62 }} spin />}
          //     />}
      />
      <StatisticWidget
          label='Swapp price'
          value={formatUSDValue(uniswap.swappPrice, 5)}
          hint1={   <div
              // href={`https://app.uniswap.org/#/swap?inputCurrency=${SWAPPTokenMeta.address}&outputCurrency=${WBTCTokenMeta.address}&use=V2`}
              className={s.linkBtn}>
            <a
                href='https://v2.info.uniswap.org/token/0x8cb924583681cbfe487a62140a994a49f833c244'
                target="_blank"
            >Uniswap Market</a>
          </div>}
          // hint2={`effective locked`}
          widget={priceChartData ? <LineChartWidget data={priceChartData} color={'#E53E4C'}/> :
              <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 62 }} spin />}
              />}
      />
      <StatisticWidget
          label='Epoch countdown'
          value={untilNextEpoch}
          hint1='until auction phase ends'
          // hint2={`effective locked`}
          widget={<ProgressBarCircular value={percent}/>}
      />
      <PersonalInfoModal
        modalKey={modalKey}
        visible={contributionVisible}
        onCancel={onModalCloseContributions}
      />
    </div>
  );
};

export default PersonalInfo;
