import React, { useState } from 'react';

// import PoolCard from 'views/pools/components/pool-card';
import PoolTransactionChart from 'views/pools/components/pool-transaction-chart';
import PoolTransactionTable from 'views/pools/components/pool-transaction-table';
import { ReactComponent as SortRowSvg } from 'resources/svg/icons/sortRow.svg';
import { ReactComponent as SortColumnSvg } from 'resources/svg/icons/sortColumn.svg';
import cx from 'classnames';

import s from './styles.module.css';
import PoolCardItem from '../pollCard';

const PoolOverviewSection: React.FunctionComponent = () => {
    const [view, setView] = useState(0);
  return (
    <div className={s.component}>
      <div className={s.header}>
          <div className={s.labelPools}>Pools</div>
          <div className={s.viewSelect}>
              <div className={cx(s.icon, { [s.active]: view===0 })} onClick={()=>setView(0)}>
                  <SortColumnSvg />
              </div>
              <div className={cx(s.icon, { [s.active]: view===1 })} onClick={()=>setView(1)}>
                  <SortRowSvg />
              </div>
          </div>
      </div>

      <div className={s.cards}>
        <PoolCardItem stableToken isDisable={false} />
        <PoolCardItem unilpToken isDisable={false} />
        <PoolCardItem swappToken isDisable={false} />
      </div>
      {/*<div className={s.divider} />*/}
      <div className={s.labelOverview}>Overview</div>
      <PoolTransactionChart />
      <div className={s.labelOverview}>Transactions</div>
      <PoolTransactionTable label="Transactions" stableToken unilpToken swappToken />
    </div>
  );
};

export default PoolOverviewSection;
