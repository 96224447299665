import React, { useState } from "react";
import * as Antd from 'antd';
import { useAsyncEffect } from 'hooks/useAsyncEffect';
import { useWeb3Contracts } from 'web3/contracts';
import cx from 'classnames';

import s from "../pools/components/pool-token-row/./styles.module.css";
import k from './styles.module.css';

export type CompaundViewProps = {};

const CompaundView: React.FunctionComponent<CompaundViewProps> = (props) => {
  const { staking } = useWeb3Contracts();
  const [values, setValues] = useState([])

  useAsyncEffect(async() => {
    const array: any = await staking.getPotentialInterestFromCompoundFunction();
    setValues(array);
  }, [staking])

  return (
    <div>
      <div className={k.labelPools}>Potential interest from Compound</div>
      <Antd.Row className={cx(s.balanceRow, s.additional)}>
        <Antd.Col flex="1" className={cx(s.walletBlnc, s.card)}>
          <div className={cx(s.balanceLabel, s.striked)}>USDC</div>
          <span className={s.balanceValue}>{(+values[0] * .8).toFixed(2)}</span>
        </Antd.Col>
        <Antd.Col flex="1" className={s.card}>
          <div className={cx(s.balanceLabel, s.striked)}>USDT</div>
          <span className={s.balanceValue}>{(+values[1] * .8).toFixed(2)}</span>
        </Antd.Col>
        <Antd.Col flex="1" className={s.card}>
          <div className={cx(s.balanceLabel, s.striked)}>DAI</div>
          <span className={s.balanceValue}>{(+values[2] * .8).toFixed(2)}</span>
        </Antd.Col>
      </Antd.Row>
    </div>
  );
};

export default CompaundView;
