import React, { useEffect } from 'react';
import * as Antd from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import cx from 'classnames';
import BigNumber from 'bignumber.js';
import { numberWithCommas, getYearDurationBonus, getMonthlyReward } from 'functions/helpers';

import { TokenMeta } from 'web3/types';
import { formatBigValue, getHumanValue, getNonHumanValue, MAX_UINT_256, ZERO_BIG_NUMBER } from 'web3/utils';
import { useWeb3Contracts } from 'web3/contracts';
import { USDCTokenMeta } from 'web3/contracts/usdc';
import { USDTTokenMeta } from 'web3/contracts/usdt';
import { DAITokenMeta } from 'web3/contracts/dai';
import { UNISWAPTokenMeta } from 'web3/contracts/uniswap';
import { SWAPPTokenMeta } from 'web3/contracts/swapp';
import { useEthGasPrice } from 'context/useEthGas';
import { ReactComponent as ReferralSVG } from "resources/svg/icons/stake-referral.svg";

import InfoBox from 'components/info-box';
import InfoTooltip from 'components/info-tooltip';
import NumericInput from 'components/numeric-input';
import OutlinedTextField from "components/outlined-textfield";
import Dropdown from 'components/dropdown';
import AdditionalPanel from './components/stake-additional-panel';
import StakeCloseModal from "../pool-stak-swapp/components/stake-close-modal";
import DurationModal from "../pool-token-row/components/duration-modal";

import s from './styles.module.css';
import k from '../pool-transaction-table/styles.module.css';

export type PoolTokenRowProps = {
  token: TokenMeta;
  stableToken?: boolean;
  unilpToken?: boolean;
  swappToken?: boolean;
  type: 'deposit' | 'withdraw';
}

type StateType = {
  walletBalance?: BigNumber;
  stakedBalance?: BigNumber;
  effectiveStakedBalance?: BigNumber;
  enabled?: boolean;
  maxAllowance?: BigNumber;
  amount?: BigNumber;
  gasAmount: string;
  expanded: boolean;
  withdrawAmount?: BigNumber;
};

const InitialState: StateType = {
  walletBalance: undefined,
  stakedBalance: undefined,
  effectiveStakedBalance: undefined,
  enabled: false,
  maxAllowance: undefined,
  amount: undefined,
  gasAmount: 'Standard',
  expanded: false,
  withdrawAmount: undefined,
};

const balanceOptions = [
  { value: 25, label: "25% Balance" },
  { value: 50, label: "50% Balance" },
  { value: 75, label: "75% Balance" },
  { value: 100, label: "MAX Balance" },
];

const PoolTokenRow: React.FunctionComponent<PoolTokenRowProps> = props => {
  const web3c = useWeb3Contracts()
  const { stakingSwapp, usdc, usdt, staking, dai, uniswap, swapp, yf, yfSWAPP, yfLP, yfNewSWAPP } = useWeb3Contracts();
  const ethGasPrice = useEthGasPrice();
  const storedW = localStorage.getItem('w');

  const [derivationPath, setDerivationPath] = React.useState<string>('');
  const [state, setState] = React.useState<StateType>(InitialState);
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [dropdownValue, setDropdownValue] = React.useState([{value: '', label: ''}]);
  const [enabling, setEnabling] = React.useState<boolean>(false);
  const [depositing, setDepositing] = React.useState<boolean>(false);
  const [withdrawing, setWithdrawing] = React.useState<boolean>(false);
  const [referralAddress, setReferralAddress] = React.useState(storedW ? storedW : '');
  const [stake, setStake] = React.useState<any>({});
  const [showStakeCloseModal, setShowStakeCloseModal] = React.useState(false);
  const [showDurationModal, setShowDurationModal] = React.useState(false);
  const [depositingClose, setDepositingClose] = React.useState(false);
  const [penalty, setPenalty] = React.useState(0);
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    if(stakingSwapp.stakes?.active) {
      setDerivationPath(stakingSwapp.stakes?.endEpoch)
      setStake(stakingSwapp.stakes)
    }
  }, [stakingSwapp.stakes])

  React.useEffect(() => {
    const totalEpochs = stakingSwapp.totalEpochs! + 1;
    const currentEpoch = stakingSwapp.currentEpoch!;
    const options: any[] = [];

    for(let i = currentEpoch + 1; i <= totalEpochs; i++) {
      if(i === 1) {
        continue;
      }
      const option = { value: i, label: `#${i-1} (Duration - ${currentEpoch == 0 ? i - currentEpoch - 1 : i - currentEpoch} epochs)` };
      options.push(option);
    }
    setDropdownValue(options)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stakingSwapp])

  React.useEffect(() => {
    switch (props.token) {
      case USDCTokenMeta:
        setState(prevState => ({
          ...prevState,
          walletBalance: usdc.balance,
          stakedBalance: staking.usdc.balance,
          effectiveStakedBalance: staking.usdc.epochUserBalance,
          enabled: usdc.allowance?.gt(ZERO_BIG_NUMBER) ?? false,
          maxAllowance: BigNumber.min(usdc.allowance ?? ZERO_BIG_NUMBER, usdc.balance ?? ZERO_BIG_NUMBER),
        }));
        break;
      case USDTTokenMeta:
        setState(prevState => ({
          ...prevState,
          walletBalance: usdt.balance,
          stakedBalance: staking.usdt.balance,
          effectiveStakedBalance: staking.usdt.epochUserBalance,
          enabled: usdt.allowance?.gt(ZERO_BIG_NUMBER) ?? false,
          maxAllowance: BigNumber.min(usdt.allowance ?? ZERO_BIG_NUMBER, usdt.balance ?? ZERO_BIG_NUMBER),
        }));
        break;
      case DAITokenMeta:
        setState(prevState => ({
          ...prevState,
          walletBalance: dai.balance,
          stakedBalance: staking.dai.balance,
          effectiveStakedBalance: staking.dai.epochUserBalance,
          enabled: dai.allowance?.gt(ZERO_BIG_NUMBER) ?? false,
          maxAllowance: BigNumber.min(dai.allowance ?? ZERO_BIG_NUMBER, dai.balance ?? ZERO_BIG_NUMBER),
        }));
        break;
      case UNISWAPTokenMeta:
        setState(prevState => ({
          ...prevState,
          walletBalance: uniswap.balance,
          stakedBalance: staking.uniswap.balance,
          effectiveStakedBalance: staking.uniswap.epochUserBalance,
          enabled: uniswap.allowance?.gt(ZERO_BIG_NUMBER) ?? false,
          maxAllowance: BigNumber.min(uniswap.allowance ?? ZERO_BIG_NUMBER, uniswap.balance ?? ZERO_BIG_NUMBER),
        }));
        break;
      case SWAPPTokenMeta:
        setState(prevState => ({
          ...prevState,
          walletBalance: swapp.balance,
          stakedBalance: stakingSwapp.swapp.balance,
          effectiveStakedBalance: stakingSwapp.currentEpoch === 0 ? undefined : stakingSwapp.swapp.epochUserBalance,
          enabled: swapp.allowanceSwapp?.gt(ZERO_BIG_NUMBER) ?? false,
          maxAllowance: BigNumber.min(swapp.allowanceSwapp ?? ZERO_BIG_NUMBER, swapp.balance ?? ZERO_BIG_NUMBER),
        }));
        break;
      default:
        break;
    }
  }, [web3c, props.token]);

  React.useEffect(() => {
    if (props.unilpToken || props.swappToken) {
      setExpanded(true);
    }
  }, [props.unilpToken, props.swappToken]);

  const activeBalance = React.useMemo<BigNumber | undefined>(() => {
    if (props.type === 'deposit') {
      return state.maxAllowance;
    } else if (props.type === 'withdraw') {
      return state.stakedBalance;
    }

    return undefined;
  }, [props.type, state]);

  const maxAmount = React.useMemo<number>(() => {
    return getNonHumanValue(activeBalance ?? 0, props.token.decimals).toNumber();
  }, [activeBalance, props.token]);

  function toggleExpanded() {
    setExpanded(prevState => !prevState);
  }

  async function handleSwitchChange(checked: boolean) {
    const value = checked ? MAX_UINT_256 : ZERO_BIG_NUMBER;

    setEnabling(true);

    try {
      switch (props.token) {
        case USDCTokenMeta:
          await usdc.approveSend(value);
          break;
        case USDTTokenMeta:
          await usdt.approveSend(value);
          break;
        case DAITokenMeta:
          await dai.approveSend(value);
          break;
        case UNISWAPTokenMeta:
          await uniswap.approveSend(value);
          break;
        case SWAPPTokenMeta:
          await swapp.approveSendSwapp(value);
          break;
        default:
          break;
      }
    } catch (e) {
    }

    setEnabling(false);
  }

  function handleAmountChange(value: BigNumber | undefined) {
    setState(prevState => ({
      ...prevState,
      amount: value,
    }));
  }

  function handleWithdrawAmountChange(value: BigNumber | undefined) {
    setState(prevState => ({
      ...prevState,
      withdrawAmount: value,
    }));
  }

  function handleGasAmountChange(ev: RadioChangeEvent) {
    setState(prevState => ({
      ...prevState,
      gasAmount: ev.target.value,
    }));
  }

  function handleInputMaxClick() {
    const editedBalance = props.swappToken ? activeBalance?.minus(1) : activeBalance!;
    setState(prevState => ({
      ...prevState,
      amount: editedBalance,
    }));
  }

  function handleSelectAmount(value: any) {
    let newAmmount = new BigNumber(+activeBalance! / 100 * value);
    if (value === '100' && props.swappToken) newAmmount = newAmmount?.minus(1);
    setState(prevState => ({
      ...prevState,
      amount: newAmmount,
    }));
  }

  function handleWithdrawInputMaxClick() {
    setState(prevState => ({
      ...prevState,
      withdrawAmount: state.stakedBalance,
    }));
  }

  function handleSliderChange(value: number) {
    setState(prevState => ({
      ...prevState,
      amount: value === maxAmount ? activeBalance : getHumanValue(new BigNumber(value), props.token.decimals),
    }));
  }

  async function handleDeposit() {
    setDepositing(true);

    try {
      if(SWAPPTokenMeta.name === props.token.name) {
        await stakingSwapp.depositSend(
          props.token, 
          state.amount!, 
          +derivationPath, 
          gasOptions.get(state.gasAmount)!
        );
      } else {
        await staking.depositSend(props.token, state.amount!, gasOptions.get(state.gasAmount)!);
      }

      setState(prevState => ({
        ...prevState,
        amount: undefined,
        gasAmount: 'Standard',
      }));

      switch (props.token) {
        case USDCTokenMeta:
          usdc.reload();
          yf.reload();
          break;
        case USDTTokenMeta:
          usdt.reload();
          yf.reload();
          break;
        case DAITokenMeta:
          dai.reload();
          yf.reload();
          break;
        case UNISWAPTokenMeta:
          uniswap.reload();
          yfLP.reload();
          break;
        case SWAPPTokenMeta:
          swapp.reload();
          yfSWAPP.reload();
          break;
      }
    } catch (e) {
    }

    setDepositing(false);
  }

  async function unstakeCall() {
    setWithdrawing(true);

    try {
      if(SWAPPTokenMeta.name === props.token.name) {
        await stakingSwapp.withdrawSend(props.token, state.withdrawAmount!, gasOptions.get(state.gasAmount)!);
      } else {
        await staking.withdrawSend(props.token, state.withdrawAmount!, gasOptions.get(state.gasAmount)!);
      }

      setState(prevState => ({
        ...prevState,
        amount: undefined,
        gasAmount: 'Standard',
      }));

      switch (props.token) {
        case USDCTokenMeta:
          usdc.reload();
          yf.reload();
          break;
        case USDTTokenMeta:
          usdt.reload();
          yf.reload();
          break;
        case DAITokenMeta:
          dai.reload();
          yf.reload();
          break;
        case UNISWAPTokenMeta:
          uniswap.reload();
          yfLP.reload();
          break;
        case SWAPPTokenMeta:
          swapp.reload();
          yfSWAPP.reload();
          break;
      }
    } catch (e) {
    }

    setWithdrawing(false);
    setShowStakeCloseModal(false)
  }

  const handleWithdraw = async() => {
    let result = await stakingSwapp.getPenalty(SWAPPTokenMeta, state.withdrawAmount);
    result = getHumanValue(new BigNumber(result), SWAPPTokenMeta.decimals);

    setPenalty(result);
    setShowStakeCloseModal(true)
  }

  const gasOptions = React.useMemo<Map<string, number>>(() => new Map<string, number>([
    ['Very fast', ethGasPrice.price.fastest],
    ['Fast', ethGasPrice.price.fast],
    ['Standard', ethGasPrice.price.average],
    ['Slow', ethGasPrice.price.safeLow],
  ]), [ethGasPrice]);

  React.useEffect(() => {
    setState(prevState => ({
      ...prevState,
      gasAmount: 'Standard',
    }));
  }, [gasOptions]);

  const noAmount = React.useMemo(() => !state.amount || state.amount?.isEqualTo(ZERO_BIG_NUMBER), [state.amount]);
  const noWithdrawAmount = React.useMemo(() => 
    !state.withdrawAmount || 
    state.withdrawAmount?.isEqualTo(ZERO_BIG_NUMBER) ||
    (props.swappToken && stakingSwapp.stakes! && stakingSwapp.currentEpoch == stakingSwapp.stakes.startEpoch) ||
    (props.swappToken && stakingSwapp.stakes! &&
      (+yfNewSWAPP.getUserLastEpochHarvested! < stakingSwapp?.currentEpoch! - 1)) , 
  [stakingSwapp.currentEpoch, stakingSwapp.stakes, state.withdrawAmount, props.swappToken, yfNewSWAPP]);

const renderRferralField = () => {
  const hasReferrer = staking.hasReferrer;
  const address = staking.referrals;
  if (hasReferrer) return address;
  else if (referralAddress) return referralAddress;
  return '';
}

const handleSelect = (value: string | number) => {
  setDerivationPath(String(value));
  setShowDurationModal(true);
}

useEffect(() => {
  let progress = 0
  if (stakingSwapp.currentEpoch! >= stake.endEpoch) progress = 100;
  else progress = 100 * (stakingSwapp.currentEpoch! - stake.startEpoch) / stake.duration;
  setProgress(progress);
}, [stakingSwapp.currentEpoch, stake])

// const getYearBonus = (yearCounter: number) => {
//   const bonusArray = [21450, 23595, 25954, 28550, 31405, 34545, 38000, 41800, 45980, 50578, 55635, 61477, 67932, 75065, 82947, 91656, 101280, 111915, 123666, 136651, 150999, 166854, 184374, 204701, 227269, 252326, 280145, 311031, 345322, 383393, 425662, 472592, 524695, 582543, 646768, 721639, 805178, 898388, 1002387, 1118426, 1247898, 1392358, 1553541, 1733382, 1934043, 2157933, 2407740, 2700403, 3028638, 3396771, 3809651, 4272716, 4792068, 5374546, 6027826, 6760512, 7582256, 8503884, 9537537, 11000000];
//   const epochId = yearCounter * 12
//   const currentEpoch = stakingSwapp?.currentEpoch!
//   const multiplier = (epochId - currentEpoch) / epochId;
//   let bonusValue = 0;

//   let i = currentEpoch ? currentEpoch : 1;
//   let epochSize = currentEpoch === 0 ? +yfNewSWAPP.nextPoolSize! : +yfNewSWAPP.poolSize!;
//   for (i; i <= epochId; i++) {
//     bonusValue += bonusArray[i - 1] * +state.amount! / (epochSize + +state.amount!) * multiplier;
//   }

//   if (bonusValue) return bonusValue;
//   return 0;
// }

const getBonusCounter = () => {
  let output  = [];
  for (let i = 1; i < 6; i++) {
    output.push(
      <Antd.Col flex="1" className={cx(s.walletBlnc, s.card)}>
        <div className={s.balanceLabel}>
          <div>{i * 12} Epochs</div>
          <span className={s.tooltip}>
          <InfoTooltip title={
            <ul className={s.infoList}>
              <li> Calculated based on current rates and conservatively projected prices for other years.
                <ul>
                  <li>1st - 12 EPOCH – Current Price</li>
                  <li>13th - 24th EPOCH – 0.20 USDC</li>
                  <li>25th – 36th EPOCH - 0.75 USDC</li>
                  <li>37th – 48th EPOCH – 0.95 USDC</li>
                  <li>49th – 60th EPOCH – 1.00 USDC</li>
                </ul>
              </li>
              <li>All figures are estimates provided for your convenience only and do not represent a promised rate of return. We are not financial advisors, and this is not financial advice.</li>
              <li>These prices can change at any point of time these were used only to estimate APY.</li>
              <li>PLEASE DO YOUR OWN RESEARCH.</li>
            </ul>
          } />
          </span>
        </div>
        <div className={cx(s.balanceValue, s.apy)}>DB {numberWithCommas(getYearDurationBonus(i, state.amount!, stakingSwapp!, yfNewSWAPP!, uniswap.swappPrice!).toFixed(2))} %</div>
        <div className={cx(s.balanceValue, s.apy)}>MR {numberWithCommas(getMonthlyReward(i, state.amount!, stakingSwapp!, yfNewSWAPP!, uniswap.swappPrice!).toFixed(2))} %</div>
      </Antd.Col>
    )
  }
  return output;
}

  return (
    <div className={s.component}>
      <div className={s.header} onClick={() => {toggleExpanded()}}>
        <div className={s.col}>
          <div className={s.logo}>{props.token.icon}</div>
          <div className={s.name}>{props.token.name}</div>
        </div>
        {!expanded && 
          <div className={s.col}>
            <div className={s.label}>Wallet balance</div>
            <div className={s.value}>{formatBigValue(state.walletBalance, 2, '-', props.token.name == "WBTC_SWAPP_UNI_LP" ? 8 : 2)}</div>
          </div>
        }
        <div className={s.col}>
          {props.type === 'deposit' && (
            <>
              <div className={s.value} onClick={(e) => e.stopPropagation()}>
                <Antd.Switch
                  checked={state.enabled}
                  loading={state.enabled === undefined || enabling}
                  onChange={handleSwitchChange} />
              </div>
              <div className={cx(s.tokenEnablelabel, state.enabled && s.tokenEnableColor)}>
                {
                  state.enabled ? "Token enabled" : "Token disabled"
                }
              </div>
            </>
          )}
        </div>
        {/* {(state.enabled || props.type === 'withdraw') && (
          <div className={s.col}>
            <Antd.Button
              className={s.arrow}
              icon={expanded ? <ChevronTopSvg /> : <ChevronRightSvg />}
              onClick={toggleExpanded}
            />
          </div>
        )} */}
      </div>
      <div className={cx(s.body, (!expanded || !state.enabled) && s.collapsed)}>
        <Antd.Row className={s.balanceRow}>
          <Antd.Col flex="1" className={cx(s.walletBlnc, s.card)}>
            <div className={s.balanceLabel}>Wallet balance</div>
            <div className={s.balanceValue}>{formatBigValue(state.walletBalance, props.token.decimals, '-', props.token.name == "WBTC_SWAPP_UNI_LP" ? 8 : 2)}</div>
          </Antd.Col>
          <Antd.Col flex="1" className={s.card}>
            <div className={s.balanceLabel}>Stake balance</div>
            <div className={s.balanceValue}>{formatBigValue(state.stakedBalance, props.token.decimals, '-', props.token.name == "WBTC_SWAPP_UNI_LP" ? 8 : 2)}</div>
          </Antd.Col>
          <Antd.Col flex="1" className={s.card}>
            <div className={s.balanceLabel}>Effective staked balance
              <InfoTooltip
                title="This value represents your 'effective stake' in this pool - meaning the portion of your total staked balance that is earning rewards this epoch. When depositing new tokens during an epoch that is currently running, your effective deposit amount will be proportionally sized by the time that has passed from that epoch. Once an epoch ends, your staked balance and effective staked balance will become equal." />
            </div>
            <div className={s.balanceValue}>{formatBigValue(state.effectiveStakedBalance, props.token.decimals, '-', props.token.name == "WBTC_SWAPP_UNI_LP" ? 8 : 2)}</div>
          </Antd.Col>
        </Antd.Row>
        <AdditionalPanel 
          progress={progress}
          stakingSwapp={stakingSwapp}
          yfNewSWAPP={yfNewSWAPP}
          name={props.token.name}
        />
        <Antd.Row className={s.inputRow}>
          <Antd.Col className={cx(s.innerCol1, s.card)}>
            <div className={s.inputLabel}>Deposit</div>
            <div className={s.inputValue}>
              <NumericInput
                addonBefore={
                  <>
                    <span className={cx(s.addonWrap, props.swappToken && s.withSelect)}>
                      <span className={s.addonIcon}>{props.token.icon}</span>
                      <span className={s.addonLabel}>{props.token.name}</span>
                    </span>
                    { props.swappToken &&
                      <Dropdown
                        className={s.dropdown}
                        items={balanceOptions}
                        // selected={derivationPath}
                        onSelect={handleSelectAmount}
                      />
                    }
                  </>
                }
                addonAfter={
                  <Antd.Button
                    className={s.inputMaxBtn}
                    disabled={!activeBalance}
                    onClick={handleInputMaxClick}
                  >
                    Max
                  </Antd.Button>
                }
                placeholder={activeBalance ? `0 (Max ${activeBalance.toFormat()})` : '0'}
                maximumFractionDigits={props.token.decimals}
                value={state.amount}
                onChange={handleAmountChange}
              />
              {props.swappToken && 
                <div className={s.potentialBonus}>
                  <span className={s.bonusLabel}>Potential APY
                    <InfoTooltip title={
                      <span>
                        Annual duration bonus projections are based on the current pool size and token price. As your % of the entire pool fluctuates, that impacts the % of the duration bonus you are entitled to.
                        The current version of this pool allows for <b>ONLY</b> one stake at a time. Users can "top up" (add to) an active stake at any time. Accumulated interest can be scraped (cashed out) once per epoch at the close of each epoch.
                        <br /><br />
                          <span><b>EXAMPLE:</b> User creates stake for 1 year (12 epochs). User can add to (top up) that 12-month stake multiple times but cannot create a new stake until that 12 months has passed; epoch has matured.</span>
                      </span>
                    } />
                  </span>
                  {getBonusCounter()}
                </div> 
              }
              <div className={s.inputWrapper}>
                <OutlinedTextField
                  onChange={()  => {}}
                  onChangeText={(value: any) => { setReferralAddress(value); localStorage.setItem('w', value) }}
                  label="Referral Address"
                  placeholder="Type here..."
                  startAdornment={<ReferralSVG className={s.svgColor} />}
                  value={renderRferralField()}
                  disabled={staking.hasReferrer}
                />
              </div>
              {props.swappToken && !stakingSwapp?.stakes?.active && 
                <div className={`${k.filters} ${s.filters}`}>
                  <Dropdown
                    button
                    className={k.filterDropDown}
                    label='Stake till the end of epoch:'
                    items={dropdownValue}
                    selected={derivationPath}
                    onSelect={handleSelect}
                    disabled={stakingSwapp.stakes?.active}
                  />
                </div>
              }
              <div className={s.actionBtnWrapper}>
                <Antd.Button
                  type="primary"
                  className={s.actionBtn}
                  loading={depositing}
                  disabled={
                    !state.enabled || 
                    noAmount || 
                    (stakingSwapp.stakes?.active && stakingSwapp.stakes?.endEpoch <= stakingSwapp?.currentEpoch!)
                  }
                  onClick={handleDeposit}
                  >
                  {stakingSwapp.stakes?.active ? 'Top Up Stake' : 'Stake'}
                </Antd.Button>
              </div>
              <InfoBox
                  text="Deposits made after an epoch started will be considered as pro-rata figures in relation to the length of the epoch." />
            </div>
          </Antd.Col>
          <Antd.Col className={cx(s.innerCol1, s.card)}>
            <div className={s.inputLabel}>Withdraw</div>
            <div className={s.inputValue}>
              <NumericInput
                addonBefore={
                  <span className={s.addonWrap}>
                    <span className={s.addonIcon}>{props.token.icon}</span>
                    <span className={s.addonLabel}>{props.token.name}</span>
                  </span>
                }
                addonAfter={
                  <Antd.Button
                    className={s.inputMaxBtn}
                    disabled={!activeBalance}
                    onClick={handleWithdrawInputMaxClick}>Max</Antd.Button>
                }
                placeholder={state.stakedBalance ? `0 (Max ${state.stakedBalance.toFormat()})` : '0'}
                maximumFractionDigits={props.token.decimals}
                value={state.withdrawAmount}
                onChange={handleWithdrawAmountChange}
              />
              <div className={cx(s.actionBtnWrapper, s.withdrawWrapper)}>
                <Antd.Button
                  type="primary"
                  className={cx(s.actionBtn, s.withdrawBtn)}
                  loading={withdrawing}
                  disabled={noWithdrawAmount}
                  onClick={props.token.name === 'SWAPP' ? handleWithdraw : unstakeCall}
                >
                  Unstake
                </Antd.Button>
                {props.swappToken && 
                  stakingSwapp?.currentEpoch! < stakingSwapp.stakes?.endEpoch &&
                  stakingSwapp?.currentEpoch! > stakingSwapp.stakes?.startEpoch &&
                    (+yfNewSWAPP.getUserLastEpochHarvested! < stakingSwapp?.currentEpoch! - 1) &&
                    <span className={s.warning}>
                      Your stake is not matured, to unstake you should claim your reward first.
                    </span>
                }
                {props.swappToken && 
                  stakingSwapp?.currentEpoch! < stakingSwapp.stakes?.endEpoch &&
                  stakingSwapp?.currentEpoch! == stakingSwapp.stakes?.startEpoch &&
                    <span className={s.warning}>
                      You cannot unstake during your first epoch, otherwise you would lose all your principal balance!
                    </span>
                }
                {props.swappToken && 
                  stakingSwapp?.currentEpoch! >= stakingSwapp.stakes?.endEpoch &&
                    (+yfNewSWAPP.getUserLastEpochHarvested! < stakingSwapp?.stakes.endEpoch! - 1) &&
                    <span className={s.warning}>
                      Your stake is matured, to unstake you should claim your reward first.
                    </span>
                }
              </div>
              <InfoBox
                text="Any funds withdrawn before the end of this epoch will not accrue any rewards for this epoch." />
            </div>
          </Antd.Col>
          <StakeCloseModal
            visible={showStakeCloseModal}
            setShowStakeCloseModal={setShowStakeCloseModal}
            stake={stake}
            depositing={depositingClose}
            setDepositing={setDepositingClose}
            penalty={penalty}
            withdrawAmount={state.withdrawAmount}
            unstakeCall={unstakeCall}
          />
          <DurationModal
            visible={showDurationModal}
            onCancel={() => setShowDurationModal(false)}
          />
          {/* <Antd.Col className={s.innerCol2}>
            <div className={s.inputLabel}>Gas Fee
              <InfoTooltip
                title="This value represents the gas price you're willing to pay for each unit of gas. Gwei is the unit of ETH typically used to denominate gas prices and generally, the more gas fees you pay, the faster the transaction will be mined." />
            </div>
            <Antd.Radio.Group
              className={s.gasGroup}
              value={state.gasAmount}
              onChange={handleGasAmountChange}>
              {Array.from(gasOptions.entries()).map(([label, value]) => (
                <Antd.Radio.Button key={label} className={s.gasOption} value={label}>
                  <div>
                    <div className={s.gasOptionName}>{label}</div>
                    <div className={s.gasOptionValue}>{value} Gwei</div>
                  </div>
                  <div className={s.gasOptionRadio} />
                </Antd.Radio.Button>
              ))}
            </Antd.Radio.Group>
          </Antd.Col> */}
        </Antd.Row>
      </div>
    </div>
  );
};

export default PoolTokenRow;
