import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import cx from 'classnames';
import * as Antd from 'antd';
import BigNumber from 'bignumber.js';
import { useWallet } from 'wallets/wallet';
import { numberWithCommas } from 'functions/helpers';
import {
  MAX_UINT_256,
  ZERO_BIG_NUMBER,
  formatBigValue,
  getHttpsRpcUrl,
  getNonHumanValue,
  getNetworkAddress,
  getHumanValue,
  getBridgeAddress
} from 'web3/utils';

import { SWAPPTokenMeta } from 'web3/contracts/swapp';
import { CONTRACT_STAKING_ADDR_SWAPP } from 'web3/contracts/stakingSwapp';
import { useWeb3Contracts } from 'web3/contracts';

import NumericInput from 'components/numeric-input';
import DestinationChaineModal from 'components/destination-change-modal';
import SourceChaineModal from 'components/source-change-modal';
import InfoTooltip from 'components/info-tooltip';
import BridgeTransactionsTable from './components/bridge-transactions';
import BridgeInfo from './components/bridge-info';
import TransactionErrorModal from 'components/transaction-error-modal';
import GasPrice from './components/bridge-gas-value';
import ComingSoonModal from 'components/coming-soon-modal';

import { chainArrays } from '../../constants';

import { ReactComponent as ArrowRightSvg } from '../../resources/svg/icons/chevron-right.svg';

import k from './styles.module.css';
import s from '../pools/components/pool-token-row/styles.module.css';

type StateType = {
  extraFee: string;
  dayLimit: string;
  minAmount: string;
  maxAmount: string;
  maxFeeAmount: string;
  fixFeeAmount: string;
  usedDayLimit: string;
  feePercentage: string;
  currentFeeAmount: number;
  maxAvailableAmount: number;
  loading: boolean;
  enabled?: boolean;
  amount?: BigNumber;
  walletBalance?: BigNumber;
  allowanceSwapp?: BigNumber;
};

const InitialState: StateType = {
  extraFee: '0',
  dayLimit: '',
  minAmount: '',
  maxAmount: '',
  usedDayLimit: '',
  maxFeeAmount: '',
  fixFeeAmount: '',
  feePercentage: '',
  currentFeeAmount: 0,
  maxAvailableAmount: 0,
  loading: false,
  enabled: false,
  amount: undefined,
  walletBalance: undefined,
  allowanceSwapp: undefined
};

const BridgeView: React.FunctionComponent = () => {
  const wallet = useWallet();
  const { swapp } = useWeb3Contracts();

  const [ethGasValues, setEthGasValues] = React.useState({} as any);
  const [enabling, setEnabling] = useState<boolean>(false);
  const [chains, setChainArrays] = useState([...chainArrays]);
  const [state, setState] = useState<StateType>(InitialState);
  const [sourceModal, setSourceModal] = useState<boolean>(false);
  const [destinationModal, setDestinationModal] = useState<boolean>(false);
  const [warningVisibility, setWarningVisibility] = useState<boolean>(false);
  const [soonVisibility, setSoonVisibility] = useState<boolean>(false);
  const [reloadTransactionsList, setReloadTransactionsList] = useState<boolean>(
    false
  );

  let web3 = new Web3(new Web3.providers.HttpProvider(getHttpsRpcUrl()));

  //update bridge info every minute
  useEffect(() => {
    const interval = setInterval(() => {
      getBridgeReadEvents();
    }, 60000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // update transactions table every 3 minute
  useEffect(() => {
    const interval2 = setInterval(() => {
      setReloadTransactionsList(true);
    }, 180000);

    return () => clearInterval(interval2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    web3 = new Web3(
      new Web3.providers.HttpProvider(getHttpsRpcUrl(wallet.networkId))
    );
    getBridgeEvents();

    if (!wallet.isActive) {
      setState((prevState) => ({
        ...prevState,
        enabled: false
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet.networkId, wallet.provider, wallet.account]);

  useEffect(() => {
    const arrays = [...chains];
    if (!wallet.account) return;

    arrays.map((item) => {
      item.current = false;
      item.selected = false;
      return item;
    });

    // Set current item acconding to wallet networkId
    if (wallet.networkId === 1 || wallet.networkId === 3) {
      arrays[0].current = true;
    }
    if (wallet.networkId === 56 || wallet.networkId === 97) {
      arrays[1].current = true;
    }
    if (wallet.networkId === 25) {
      arrays[2].current = true;
    }
    if (wallet.networkId === 137 || wallet.networkId === 80001) {
      arrays[3].current = true;
    }

    // Set selected item - fist in array not current
    let count = 0;
    arrays.forEach((item) => {
      if (count === 0) {
        if (!item.current) {
          item.selected = true;
          count++;
        }
      }
    });

    setChainArrays(arrays);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet]);

  const getBridgeEvents = async () => {
    const contractSwappBridge = getSwappBridgeContract(false);

    let balance: BigNumber | undefined = new BigNumber(0);
    let allowanceSwapp: BigNumber | undefined = new BigNumber(0);

    if (wallet.account && wallet.networkId) {
      balance = await contractSwappBridge.methods
        .balanceOf(wallet.account)
        .call();
      balance = getHumanValue(new BigNumber(balance!), SWAPPTokenMeta.decimals);

      allowanceSwapp = await contractSwappBridge.methods
        .allowance(wallet.account, getBridgeAddress(wallet.networkId))
        .call();
      allowanceSwapp = new BigNumber(allowanceSwapp!);

      setState((prevState) => ({
        ...prevState,
        walletBalance: balance!,
        allowanceSwapp: allowanceSwapp!,
        enabled: allowanceSwapp!.gt(ZERO_BIG_NUMBER) ?? false
      }));
    }
  };

  useEffect(() => {
    if (state.maxAmount) getBridgeReadEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.maxAmount, wallet.networkId]);

  useEffect(() => {
    handleAmountChange(state.amount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.fixFeeAmount]);

  useEffect(() => {
    if (wallet.account) {
      getBridgeReadEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet.account]);

  useEffect(() => {
    let value = '';
    if (
      chains.find((i) => i.selected)?.bridgeId! === 1 &&
      ethGasValues.standard >= process.env.REACT_APP_CRITICAL_GAS_PRICE! &&
      ethGasValues.standard <= process.env.REACT_APP_STOP_GAS_PRICE!
    ) {
      value = process.env.REACT_APP_EXTRA_FEE_GAS_PRICE!;
    }
    setState((prevState) => ({
      ...prevState,
      extraFee: value
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ethGasValues, state.currentFeeAmount]);

  const ethGasPrice = React.useCallback(() => {
    fetch('https://ethgasstation.info/api/ethgasAPI.json')
      .then((result) => result.json())
      .then((result) => {
        setEthGasValues({
          fast: Math.round(result.fast / 10),
          standard: Math.round(result.average / 10),
          slow: Math.round(result.safeLow / 10)
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBridgeReadEvents = async () => {
    const url = process.env.REACT_APP_BASE_API_URI;
    fetch(`${url}/bridge-data`).then((result) =>
      result.json().then((result) => {
        saveBridgeValues(result);
      })
    );
  };

  const saveBridgeValues = (result: any) => {
    ethGasPrice();

    const currentChain = chains.find((i) => i.selected)?.id;
    const current = result[currentChain!];

    const maxAvailableAmount =
      +current?.maxAmount <= current?.dayLimit - current?.usedDayLimit
        ? +current?.maxAmount
        : current?.dayLimit - current?.usedDayLimit;

    setState((prevState) => ({
      ...prevState,
      dayLimit: current?.dayLimit!,
      minAmount: current?.minAmount!,
      maxAmount: current?.maxAmount!,
      fixFeeAmount: current?.fixFeeAmount!,
      maxFeeAmount: current?.maxFeeAmount!,
      usedDayLimit: current?.usedDayLimit!,
      feePercentage: current?.feePercentage!,
      maxAvailableAmount: maxAvailableAmount!
    }));
  };

  const getBridgeContract = (changeProvider: boolean) => {
    const CONTRACT_SWAPP_ADDR = getBridgeAddress(wallet.networkId);

    if (changeProvider) web3.setProvider(wallet.provider);
    return new web3.eth.Contract(
      require('web3/abi/bridge.json'),
      CONTRACT_SWAPP_ADDR
    );
  };

  const getSwappBridgeContract = (changeProvider: boolean) => {
    const CONTRACT_SWAPP_ADDR = getNetworkAddress(wallet.networkId);

    if (changeProvider) web3.setProvider(wallet.provider);
    return new web3.eth.Contract(
      require('web3/abi/swapp.json'),
      CONTRACT_SWAPP_ADDR
    );
  };

  const setSelectedChain = (value: any) => {
    const arrays = [...chains];
    arrays.map((item) => (item.selected = false));
    arrays.find((item) => item.id === value)!.selected = true;
    setChainArrays(arrays);
    setDestinationModal(false);
    getBridgeReadEvents();
  };

  const handleSwitchChange = async (checked: boolean) => {
    try {
      setEnabling(true);
      const value = checked ? MAX_UINT_256 : ZERO_BIG_NUMBER;
      await approveSendSwapp(value, getBridgeAddress(wallet.networkId));
      getBridgeEvents();
      setEnabling(false);
    } catch (e) {
      setEnabling(false);
    }
  };

  const handleInputMaxClick = () => {
    // const minimum = Math.min(
    //   +state.allowanceSwapp!,
    //   +state.walletBalance!,
    //   +state.maxAmount,
    //   +state.maxAvailableAmount!
      // );
    const arr = [state.allowanceSwapp!, state.walletBalance!, state.maxAmount, state.maxAvailableAmount!];
    const minimum = BigNumber.min.apply(null, arr);

    setState((prevState) => ({
      ...prevState,
      amount: minimum
    }));
  };

  const handleAmountChange = (value: BigNumber | undefined) => {
    let fee: number = 0;
    let result: BigNumber | undefined =
      +state.walletBalance! <= +state.maxAvailableAmount
        ? state.walletBalance
        : new BigNumber(state.maxAvailableAmount);

    if (+value! <= +result!) result = value;
    else if (isNaN(+value!)) result = undefined;

    if (+state.fixFeeAmount > 0) fee = +state.fixFeeAmount;
    else {
      if (+state.feePercentage > 0) {
        fee = (+state.amount! * +state.feePercentage) / 10000;
        if (fee > +state.maxFeeAmount && +state.maxFeeAmount > 0) {
          fee = +state.maxFeeAmount;
        }
      }
    }

    setState((prevState) => ({
      ...prevState,
      amount: result,
      currentFeeAmount: fee
    }));
  };

  const approveSendSwapp = (
    value: BigNumber,
    address: string
  ): Promise<any> => {
    if (!wallet.account) {
      return Promise.reject();
    }

    const contract = getSwappBridgeContract(true);

    return contract.methods
      .approve(address ? address : CONTRACT_STAKING_ADDR_SWAPP, value)
      .send({ from: wallet.account });
  };

  const bridgeBurnHandler = () => {
    ethGasPrice();

    if (!wallet.account) {
      return Promise.reject();
    }

    const contract = getBridgeContract(true);
    const toNetworkId = chains.find((i) => i.selected)?.bridgeId!;

    return contract.methods
      .burn(
        wallet.account!,
        getNonHumanValue(state.amount!, SWAPPTokenMeta.decimals),
        toNetworkId,
        getNonHumanValue(+state.extraFee, SWAPPTokenMeta.decimals)
      )
      .send({ from: wallet.account });
  };

  const bridgeBurn = async () => {
    setState((prevState) => ({
      ...prevState,
      loading: true
    }));

    try {
      await bridgeBurnHandler();
      resetState();
      getBridgeEvents();
      getBridgeReadEvents();
      setReloadTransactionsList(true);
      swapp.reload();
      setWarningVisibility(true);
    } catch (e) {
      resetState();
    }
  };

  const resetState = () => {
    setState((prevState) => ({
      ...prevState,
      amount: undefined,
      loading: false
    }));
  };

  return (
    <>
      <div className={s.component}>
        {wallet.account && (
          <div className={s.header}>
            {state.enabled && (
              <>
                <div className={s.col}>
                  <div className={s.logo}>
                    {chains.find((i) => i.current)?.icon}
                  </div>
                  <div className={s.name}>
                    {chains.find((i) => i.current)?.label}
                  </div>
                </div>
                <div className={s.col}>
                  <div className={s.label}>Wallet balance</div>
                  <div className={s.value}>
                    {formatBigValue(state.walletBalance, 2, '-', 2)}
                  </div>
                </div>
              </>
            )}
            <div className={s.col}>
              <div className={s.value} onClick={(e) => e.stopPropagation()}>
                <Antd.Switch
                  checked={state.enabled}
                  loading={state.enabled === undefined || enabling}
                  onChange={handleSwitchChange}
                />
              </div>
              <div
                className={cx(
                  s.tokenEnablelabel,
                  state.enabled && s.tokenEnableColor
                )}
              >
                {state.enabled ? 'Disable Bridge' : 'Enable Bridge'}
                <InfoTooltip
                  className={s.tooltip}
                  title={
                    <ul>
                      <li>Need to enable bridge for each network</li>
                      <li>
                        To see your balance in target network you will need
                        switch to that network
                      </li>
                      <li>
                        If you have enabled bridge and transaction was
                        confirmed, but switch is disabled - reload page please
                      </li>
                    </ul>
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {state.enabled && (
        <div className={s.changeWrapper}>
          <div className={k.component}>
            <div className={k.col}>
              <div className={k.label}>From</div>
              <div className={k.logo} onClick={() => setSourceModal(true)}>
                {chains.find((i) => i.current)?.icon}
              </div>
              <div className={k.networkName}>
                {chains.find((i) => i.current)?.label}
              </div>
              {chains.find((i) => i.current)?.id === 'ethereum' && (
                <GasPrice
                  id={chains.find((i) => i.current)?.id}
                  ethGasValues={ethGasValues}
                />
              )}
            </div>
            <div className={k.swapIconWrapper}>
              <ArrowRightSvg />
            </div>
            <div className={k.col}>
              <div className={k.label}>To</div>
              <div className={k.logo} onClick={() => setDestinationModal(true)}>
                {chains.find((i) => i.selected)?.icon}
              </div>
              <div className={k.networkName}>
                {chains.find((i) => i.selected)?.label}
              </div>
              {chains.find((i) => i.selected)?.id === 'ethereum' && (
                <GasPrice
                  id={chains.find((i) => i.selected)?.id}
                  ethGasValues={ethGasValues}
                />
              )}
            </div>
          </div>
          <div className={cx(k.component, k.infocont)}>
            <div className={cx(k.col, k.info)}>
              <div className={k.label}>Amount</div>
              <div className={k.inputValue}>
                <NumericInput
                  className={s.ters}
                  addonAfter={
                    <Antd.Button
                      className={s.inputMaxBtn}
                      disabled={false}
                      onClick={handleInputMaxClick}
                    >
                      Max
                    </Antd.Button>
                  }
                  placeholder={
                    state.walletBalance
                      ? `0 (Max ${
                          +state.walletBalance <= +state.maxAvailableAmount
                            ? formatBigValue(state.walletBalance, 2, '-', 2)
                            : numberWithCommas(+state.maxAvailableAmount!)
                        })`
                      : '0'
                  }
                  maximumFractionDigits={18}
                  value={state.amount}
                  onChange={handleAmountChange}
                />
              </div>
              <div
                className={cx(
                  s.actionBtnWrapper,
                  s.withdrawWrapper,
                  k.actionBtnWrapper
                )}
              >
                <Antd.Button
                  type="primary"
                  className={cx(s.actionBtn, s.withdrawBtn)}
                  loading={state.loading}
                  disabled={
                    !state.amount ||
                    +state.amount < +state.minAmount! ||
                    +state.amount > +state.maxAmount! ||
                    +state.amount > +state.maxAvailableAmount! ||
                    (+ethGasValues.standard >
                      +process.env.REACT_APP_STOP_GAS_PRICE! &&
                      chains.find((i) => i.selected)?.bridgeId! === 1)
                  }
                  onClick={() =>
                    process.env.REACT_APP_WEB3_BRIDGE_DEMO_MODE === 'true'
                      ? setSoonVisibility(true)
                      : bridgeBurn()
                  }
                >
                  Move
                </Antd.Button>
              </div>
            </div>
            <BridgeInfo
              amount={state.amount!}
              extraFee={state.extraFee!}
              dayLimit={state.dayLimit!}
              ethGasValues={ethGasValues}
              minAmount={state.minAmount!}
              maxAmount={state.maxAmount!}
              usedDayLimit={state.usedDayLimit!}
              currentFeeAmount={state.currentFeeAmount!}
              maxAvailableAmount={state.maxAvailableAmount!}
              networkId={chains.find((i) => i.selected)?.bridgeId!}
            />
          </div>
        </div>
      )}
      {!wallet.isActive && (
        <div className={k.changeWrapper}>
          <div className={k.component}>
            <div className={k.col}>
              <div className={k.label}>Please, connect the wallet</div>
            </div>
          </div>
        </div>
      )}
      <BridgeTransactionsTable
        reloadTransactionsList={reloadTransactionsList}
        resetReloader={() => setReloadTransactionsList(false)}
        networkId={wallet.networkId!}
      />
      <DestinationChaineModal
        chains={chains}
        visible={destinationModal}
        setChainArrays={setSelectedChain}
        onCancel={() => setDestinationModal(false)}
      />
      <SourceChaineModal
        chains={chains}
        visible={sourceModal}
        setVisibility={() => setSourceModal(false)}
        onCancel={() => setSourceModal(false)}
      />
      <TransactionErrorModal
        visible={warningVisibility}
        message={<span>Transfer can take up to 15 minutes.</span>}
        onCancel={() => setWarningVisibility(false)}
      />
      <ComingSoonModal
        visible={soonVisibility}
        message={<span>Coming soon</span>}
        onCancel={() => {
          setSoonVisibility(false);
        }}
      />
    </>
  );
};

export default BridgeView;
