import React from 'react';
import s from './styles.module.css'
import { Progress } from 'antd';

interface IProps{
    value: number;
}

const ProgressBarCircular: React.FC<IProps> = ({value}) =>{
    return (<Progress className={s.progress} type="circle" percent={value} />)
}

export default ProgressBarCircular;
