import React, {useEffect, useMemo, useState} from 'react';
import cx from 'classnames';

import {formatBigValue, formatSWAPPValue, formatUSDValue, getHumanValue } from 'web3/utils';
import { useWeb3Contracts } from 'web3/contracts';
import { useWeekCountdown } from 'hooks/useCountdown';
import { numberWithCommas } from 'functions/helpers';

// import StatWidget from 'components/stat-widget';
// import ExternalLink from 'components/externalLink';

import s from './styles.module.css';
import StatisticWidget from "../../../../components/statistic-widget";
import ProgressBarCircular from "../../../../components/progressbar-circular";
import { LineChartWidget } from 'components/lineChartWidget';
import axios from 'axios';
import {Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";

export type PoolStatsProps = {
	className?: string;
};

export interface IWidgetLineChart {
	name: number,
	value: number,
}

const PoolStats: React.FunctionComponent<PoolStatsProps> = (props) => {
	const {
		aggregated,
		uniswap,
		staking,
		yfNewSWAPP,
		yfLP,
		yf,
	} = useWeb3Contracts();

	const [swappPrice, setSwappPrice] = useState<any[]>([]);

	useEffect(()=>{
		const getPrices = async () =>{
			const {data:{prices}} = await axios.get('https://api.coingecko.com/api/v3/coins/swapp/market_chart?vs_currency=usd&days=max&interval=daily')
			return prices;
		}
		getPrices().then(res=>setSwappPrice(res));
	},[])

	const epochEnd = React.useMemo<number | undefined>(() => {
		const [, end] = staking.getEpochPeriod(staking.currentEpoch!) ?? [];
		return end;
	}, [staking]);
	const epochStart = React.useMemo<number | undefined>(() => {
		const [start] = staking.getEpochPeriod(staking.currentEpoch!) ?? [];
		return start;
	}, [staking]);
	const [untilNextEpoch] = useWeekCountdown(epochEnd, staking.currentEpoch);

	const lockedChartData = useMemo<IWidgetLineChart[] | undefined>(()=>{
		return aggregated?.lockedByEpoch?.map((el,i)=>({
			value:Math.round(el.toNumber()),
			name: i
		}));
	},[aggregated.lockedByEpoch])

	const priceChartData = useMemo(()=>{
		return swappPrice?.map(el=>({
			value: el[1],
			name: el[0]
		}))
	},[swappPrice])

	const rewardChartData = useMemo<IWidgetLineChart[] | undefined>(()=>{
		return aggregated?.rewardByEpoch?.map((el,i)=>({
			value:el,
			name: i
		}));
	},[aggregated.rewardByEpoch])

	const percent = React.useMemo<number>(() => {
		if(epochEnd && epochStart){
			return Number((100-((epochEnd-Date.now())*100)/(epochEnd-epochStart)).toFixed(1));
		}
		return 0;
	}, [epochEnd,epochStart])

	const totalSwappReward = formatSWAPPValue(aggregated.totalSwappReward);

	const swappReward = numberWithCommas(
		+yfNewSWAPP.epochReward! +
			+yfLP.epochReward! +
			+yf.epochReward! +
			+yfNewSWAPP.epochDurationBonus!
	);

	return (
		<div className={cx(s.component, props.className)}>
			<StatisticWidget
				label='Total locked value'
				value={formatUSDValue(aggregated.totalStaked)}
				hint1={formatUSDValue(
					aggregated.totalEffectiveStaked
				)}
				hint2={`effective locked`}
				widget={lockedChartData ? <LineChartWidget data={lockedChartData} color={'#0FCB7C'}/> :
					<Spin
						indicator={<LoadingOutlined style={{ fontSize: 62 }} spin />}
					/>}
			/>
			<StatisticWidget
				label='Swapp Rewards'
				value={swappReward === 'NaN' ? '0.00' : swappReward}
				hint1={`out of ${totalSwappReward}`}
				widget={rewardChartData ?
					<LineChartWidget data={rewardChartData} color={'#1286E3'}/> :
					<Spin
						indicator={<LoadingOutlined style={{ fontSize: 62 }} spin />}
					/>
					}
			/>
			<StatisticWidget
				label='SWAPP Price'
				value={formatUSDValue(uniswap.swappPrice, 5)}
				hint1={
					<div
						// href={`https://app.uniswap.org/#/swap?inputCurrency=${SWAPPTokenMeta.address}&outputCurrency=${WBTCTokenMeta.address}&use=V2`}
						className={s.linkBtn}>
						<a
							href='https://v2.info.uniswap.org/token/0x8cb924583681cbfe487a62140a994a49f833c244'
						   target="_blank"
						>Uniswap Market</a>
					</div>
				}
				widget={priceChartData ? <LineChartWidget data={priceChartData} color={'#E53E4C'}/> :
					<Spin
					indicator={<LoadingOutlined style={{ fontSize: 62 }} spin />}
					/>}
			/>
			<StatisticWidget
				label='Epoch Countdown'
				value={untilNextEpoch}
				hint1='until next epoch'
				widget={<ProgressBarCircular value={percent}/>}
			/>
		</div>
	);
};

export default PoolStats;
