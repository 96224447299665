import React, { useEffect } from "react";
import * as Antd from "antd";

import { ReactComponent as CheckCircleSvg } from "resources/svg/icons/check-circle.svg";
import { ReactComponent as CircleSvg } from "resources/svg/icons/circle.svg";

import { useWeb3Contracts } from "web3/contracts";

import s from "./styles.module.css";

export type StakeCloseModalProps = {
  visible: boolean,
  setShowStakeCloseModal: Function,
  stake: any,
  depositing: any,
  setDepositing: Function,
  refreshStakes?: Function,
  unstakeCall?: Function,
  penalty?: number,
  withdrawAmount?: any,
};

const StakeCloseModal: React.FunctionComponent<StakeCloseModalProps> = (props) => {
  const { stakingSwapp, yfNewSWAPP } = useWeb3Contracts();
  const [progress, setProgress] = React.useState(0);
  const { visible, setShowStakeCloseModal, stake, depositing, setDepositing, refreshStakes } = props;
  const mainColor = progress < 100 ? "#FD8F77" : "#00D26D";

  useEffect(() => {
    let progress = 0
    if (stakingSwapp.currentEpoch! >= stake.endEpoch) progress = 100;
    else progress = 100 * (stakingSwapp.currentEpoch! - stake.startEpoch) / stake.duration;
    setProgress(progress);
  }, [stakingSwapp.currentEpoch, stake])

  const endStake = async () => {
    setDepositing(true);
    try {
      if (props.unstakeCall) props.unstakeCall();
      stakingSwapp.reload();
    } catch (e) {
    }

    setDepositing(false);
    setShowStakeCloseModal(false);
  }

  const getPenalty = () => {
    if (stakingSwapp.currentEpoch! < +stakingSwapp?.stakes?.endEpoch!) {
      const percent = (100 * +props.withdrawAmount) / +stakingSwapp.swapp.balance!;
      const result = +yfNewSWAPP.collectedDurationBonus! * (percent / 100)!
      return `- ${numberWithCommas(result.toFixed(2))} SWAPP`
    }
    return "0 SWAPP";
  }

  const numberWithCommas = (value: string) => value && value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return (
    <Antd.Modal
      centered
      closeIcon={null}
      footer={null}
      wrapClassName={s.createStakeCloseModal}
      visible={visible}
      onOk={() => setShowStakeCloseModal(false)}
      onCancel={() => setShowStakeCloseModal(false)}
      width={849}
    >
      <div className={s.stakingCloseForms}>
        <div className={s.title}>
          Stake Closing Summary
        </div>
        {
          progress < 100 &&
            <div className={s.stakeCloseModalAlert}>
              <CheckCircleSvg />
              <span className={s.message}>
                Warning! Your stake <b>{stake.stakeId}</b> has not yet matured.
                Unstake will result in penalties, to confirm unstake click on the <b>Unstake</b> button below.
            </span>
            </div>
        }
        <div className={s.main}>
          <div className={s.leftWrapper}>
            <div className={s.stakingCloseModalInfo}>
              <div className={s.stakingCloseModalInfoTitle}>
                <b>Staked Tokens</b>
              </div>
              <span className={s.description}>Original principal of current stake</span>
              <div style={{ color: "var(--text-color-1)" }}>
                <b>{numberWithCommas((+stakingSwapp.swapp.balance!).toFixed(2))} SWAPP</b>
              </div>
            </div>
            <div className={s.stakingCloseLeft} style={progress < 100 ? { color: "#FD8F77" } : {}}>
              <Antd.Progress
                type="circle"
                percent={progress}
                width={169}
                strokeColor={mainColor}
                format={() => `${progress.toFixed(2)} %`}
                className={progress < 100 ? s.progress : s.progressDone}
              />
              <div className={s.stakeProgress} style={{ marginTop: "12px" }}>
                <CircleSvg />
                <span style={{ color: "var(--text-color-1)" }}>Stake progress</span>
              </div>
            </div>
          </div>
          <div className={s.stakingCloseRight}>
            <div className={s.stakingCloseModalInfo}>
              <div className={s.stakingCloseModalInfoTitle}>
                <b>Amount to Unstake</b>
              </div>
              <span className={s.description}>Amount that you want to unstake</span>
              <div style={progress < 100 ? { color: "var(--bg-color-8)" } : { color: "#00D26D" }}>
                <b>{numberWithCommas(props.withdrawAmount?.toFixed(2))} SWAPP</b>
              </div>
            </div>
            <div className={s.stakingCloseModalInfo}>
              <div className={s.stakingCloseModalInfoTitle}>
                <b>Penalty Amount</b>
              </div>
              <span className={s.description}>Negative impact if ended pre-maturely</span>
              <div className={s.penaltyAmount} style={progress < 100 ? { color: "#FD8F77" } : {}}>
                <b>- {numberWithCommas(props.penalty!.toFixed(2))} SWAPP</b>
              </div>
            </div>
            <div className={s.stakingCloseModalInfo}>
              <div className={s.stakingCloseModalInfoTitle}>
                <b>Duration bonus</b>
              </div>
              <span className={s.description}>Total accrued duration bonus</span>
              <div style={{ color: "#00D26D" }}>
                <b>+ {numberWithCommas((+yfNewSWAPP.collectedDurationBonus!).toFixed(2))} SWAPP</b>
              </div>
            </div>
            <div className={s.stakingCloseModalInfo}>
              <div className={s.stakingCloseModalInfoTitle}>
                <b>Duration Bonus Penalty</b>
              </div>
              <span className={s.description}>Negative impact for duration bonus</span>
              <div className={s.penaltyAmount} style={progress < 100 ? { color: "#FD8F77" } : {}}>
                <b>{getPenalty()}</b>
              </div>
            </div>
            <div className={s.stakingCloseModalInfo}>
              <div className={s.stakingCloseModalInfoTitle}>
                <b>Total Payout</b>
              </div>
              <span className={s.description}>Final amount of tokens returned</span>
              <div style={progress < 100 ? { color: "var(--bg-color-8)" } : { color: "var(--text-color-15)" }}>
                <b>{numberWithCommas((+props.withdrawAmount - props.penalty!).toFixed(2))} SWAPP</b>
              </div>
            </div>
            <div className={s.stakingCloseModalInfo}>
              <div className={s.stakingCloseModalInfoTitle}>
                <b>Total Remainder</b>
              </div>
              <span className={s.description}>Final amount after the unstake</span>
              <div>
                <b>{numberWithCommas((+stakingSwapp.swapp.balance! - props.withdrawAmount).toFixed(2))} SWAPP</b>
              </div>
            </div>
          </div>
        </div>
        <div className={s.btnWrapper}>
          <Antd.Button
            className={progress < 100 ? s.stakingCloseModalBtn : s.stakingCloseModalMatureBtn}
            onClick={() => endStake()}
            loading={depositing}
          >
            Unstake
          </Antd.Button>
        </div>
      </div>
    </Antd.Modal>
  );
};

export default StakeCloseModal;
