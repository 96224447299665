import React, { FunctionComponent, useState, useEffect } from 'react'
import cx from 'classnames';
import { useWeb3Contracts } from 'web3/contracts';
import { useAsyncEffect } from 'hooks/useAsyncEffect';
import {formatSWAPPValue, formatUSDValue} from 'web3/utils';
import BigNumber from 'bignumber.js';
import { SWAPPTokenMeta } from 'web3/contracts/swapp';
import { USDCTokenMeta } from 'web3/contracts/usdc';
import { useUNISWAPContract } from 'web3/contracts/uniswap';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Web3 from 'web3';
import { getHttpsRpcUrl } from 'web3/utils';
import { useWallet } from 'wallets/wallet';

import CardWidget from 'components/card';
import s from './styles.module.css'
import StatisticWidget from "../../../../components/statistic-widget";
import {LineChartWidget} from "../../../../components/lineChartWidget";

type ReferralsSumaryProps = {
  litleText?: string,
  coin: string,
  text: string,
  eth: string,
  swapp: string,
  rewardLevel: string,
  referrals?: number,
  lpCoinBalance?: number,
  stableCoinBalance?: number,
  commonReward?: number,
  swappCoinBalance?: number,
  loader?: boolean,
}

const yfContract = require('web3/abi/yield_farm.json');
const yfLPContract = require('web3/abi/yield_farm_lp.json');

const ReferralsSumary: FunctionComponent = () => {
  const { staking, yf, yfLP, stakingSwapp, yfNewSWAPP } = useWeb3Contracts();
  const wallet = useWallet();
  const web3 = new Web3(new Web3.providers.HttpProvider(getHttpsRpcUrl()));

  const [reward1Level, setReward1Level]: any = useState(0);
  const [commonReward1Level, setCommonReward1Level] = useState(0);
  const [loader1Level, setLoader1Level] = useState(true);
  const [reward2Level, setReward2Level]: any = useState(0);
  const [commonReward2Level, setCommonReward2Level] = useState(0);
  const [loader2Level, setLoader2Level] = useState(true);

  const calculateRewards = async (data: string[] | undefined, setFunction: any, percent: number, setLoaderFunction: any) => {
    let summ = new BigNumber(0);
    let summ2 = new BigNumber(0);
    let summ3 = new BigNumber(0);
    let result = new BigNumber(0);

    const clientRequests: any = data?.map((request) => {
      return yf.getEstimatedReward(request)
    });

    const clientRequestsLP: any = data?.map((request) => {
      return yfLP.getEstimatedReward(request)
    });

    const clientRequestsSwapp: any = data?.map((request) => {
      return yfNewSWAPP.getEstimatedReward(request)
    });

    if (clientRequests && clientRequestsLP && clientRequestsSwapp) {
      await Promise.all(clientRequests).then(result => {
        result.map((item: any) => {
          summ = summ.plus(item);
          return summ
        })
      });
      await Promise.all(clientRequestsLP).then(result => {
        result.map((item: any) => {
          summ2 = summ2.plus(item);
          return summ2
        })
      });
      await Promise.all(clientRequestsSwapp).then(result => {
        result.map((item: any) => {
          summ3 = summ3.plus(item);
          return summ3
        })
      });

      result = summ.plus(summ2).plus(summ3).div(100).multipliedBy(percent);
    }
    setFunction(formatSWAPPValue(result))
    setLoaderFunction(false);
  }

  useAsyncEffect(async ()=> {
    if (staking.referrals1Level) {
      calculateRewards(staking.referrals1Level.concat(stakingSwapp.referrals1Level!), setReward1Level, 10, setLoader1Level)
    }
  }, [staking.referrals1Level, stakingSwapp.referrals1Level])

  useAsyncEffect(async ()=> {
    if (staking.referrals2Level) {
      calculateRewards(staking.referrals2Level.concat(stakingSwapp.referrals2Level!), setReward2Level, 5, setLoader2Level)
    }
  }, [staking.referrals2Level, stakingSwapp.referrals2Level])

  useEffect(() => {
    getContractEvents();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet.account]);

  const getContractEvents = async () => {
    const yf1Level = await getReferralAmount (
      process.env.REACT_APP_CONTRACT_YIELD_FARM_ADDR!,
      yfContract, 'referrer', 'ReferrerRewardCollected',
    );

    const yfLP1Level = await getReferralAmount (
      process.env.REACT_APP_CONTRACT_YIELD_FARM_LP_ADDR!,
      yfLPContract, 'referrer', 'ReferrerRewardCollected',
    );

    const stakingSwapp1Level = await getReferralAmount (
      process.env.REACT_APP_CONTRACT_YIELD_FARM_NEW_SWAPP_ADDR!,
      yfLPContract, 'referrer', 'ReferrerRewardCollected',
    );

    const yf2Level = await getReferralAmount (
      process.env.REACT_APP_CONTRACT_YIELD_FARM_ADDR!,
      yfContract, 'referrer2', 'Referrer2RewardCollected',
    );

    const yfLP2Level = await getReferralAmount (
      process.env.REACT_APP_CONTRACT_YIELD_FARM_LP_ADDR!,
      yfLPContract, 'referrer2', 'Referrer2RewardCollected',
    );

    const stakingSwapp2Level = await getReferralAmount (
      process.env.REACT_APP_CONTRACT_YIELD_FARM_NEW_SWAPP_ADDR!,
      yfLPContract, 'referrer2', 'Referrer2RewardCollected',
    );

    setCommonReward1Level(yf1Level + yfLP1Level + stakingSwapp1Level);
    setCommonReward2Level(yf2Level + yfLP2Level + stakingSwapp2Level);
  }

  const getReferralAmount = async (
    contract_address: string,
    contracts: any[],
    filterValue: string,
    event: string,
    ) => {

    const contract = new web3.eth.Contract(contracts, contract_address);
    const filter = {[filterValue]: wallet.account!};
    const fromBlock: number =  10415783;
    const results = await contract.getPastEvents(event,{ filter, fromBlock, toBlock: 'latest' });

    let value = 0;

    if (results) {
      for (let i = 0; i < results.length; i++) {
        let item = results[i];
        value += +web3.utils.fromWei(item.returnValues.rewardAmount, 'ether')
      }
    }
    return value;
  }

  const getUniqueReferrals = (contract1: any, contract2: any) => {
    if (contract1 && contract2) {
      const uniqueArray = Array.from(new Set(contract1.concat(contract2)))
      return uniqueArray.length;
    } return 0;
  }

  return (
    <div className={s.component}>
      {/*<div className={s.conteinerTitle}>*/}
      {/*  <h2>Referrals Summary</h2>*/}
      {/*</div>*/}
      <div className={s.header}>
        <div className={s.labelTitle}>Referrals Summary</div>
      </div>
      <div className={s.reservationContainer}>
        <CardWidget className={s.reservationCard}>
          <div className={s.reservationContainerText}>
            <div className={s.containerText}>
              <h4 className={s.colorText}>Referrer Level</h4>
            </div>
            <div className={s.containerText}>
              <h4 className={s.colorText}>Referral Count</h4>
            </div>
            <div className={s.containerText}>
              <h4 className={s.colorText}>REFERRED</h4>
            </div>
            <div className={s.containerText}>
              <h4 className={s.colorText}>ESTIMATED REWARD</h4>
            </div>
            <div className={s.containerText}>
              <h4 className={s.colorText1}>Reward</h4>
            </div>
          </div>
          <hr className={s.line}/>
          <TableReferral
            loader={loader1Level}
            litleText= "Bonus = 10.0% investments"
            coin={`0`}
            text="Level 1"
            eth= {`0` }
            swapp={`0.00`}
            rewardLevel={reward1Level}
            commonReward={commonReward1Level}
            referrals={getUniqueReferrals(staking.referrals1Level!, stakingSwapp.referrals1Level!)}
            lpCoinBalance={staking.lpCoinBalance}
            stableCoinBalance={staking.stableCoinBalance}
            swappCoinBalance={stakingSwapp.swappCoinBalance}
          />
          <hr className={s.line}/>
          <TableReferral
            loader={loader2Level}
            litleText= "Bonus = 5.0% investments"
            coin={`0`}
            text="Level 2"
            eth= {`0`}
            swapp={`0.00`}
            rewardLevel={reward2Level}
            commonReward={commonReward2Level}
            referrals={getUniqueReferrals(staking.referrals2Level!, stakingSwapp.referrals2Level!)}
            lpCoinBalance={staking.lpCoinBalance2Level}
            stableCoinBalance={staking.stableCoinBalance2Level}
            swappCoinBalance={stakingSwapp.swappCoinBalance2Level}
          />
          <hr className={s.line}/>
        </CardWidget>
      </div>
    </div>
  )
}

const TableReferral: FunctionComponent<ReferralsSumaryProps> = ({
  lpCoinBalance,
  stableCoinBalance,
  text,
  litleText,
  referrals,
  rewardLevel,
  loader,
  commonReward,
  swappCoinBalance,
}) => {

  const uniswapContract = useUNISWAPContract();

  const getUSDCSumm = () => {
    let res: number = 0;

    if (uniswapContract.unilpPrice && uniswapContract.swappPrice) {
      res = (lpCoinBalance! * +uniswapContract.unilpPrice) +
      stableCoinBalance! +
      (swappCoinBalance! * +uniswapContract.swappPrice!);
    }
    return `${res.toFixed(2)} USDC`;
  }

  return (
    loader ?
      <div className={s.preloader_custom}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 62 }} spin />} />
      </div>
      :

      <div className={s.reservationContainerTable}>
        <div className={s.containerCard}>
          <h4 className={s.onlyMobile}>Referrer Level</h4>
          <h3 className={s.colorWhite_}>{text}</h3>
          <p className={s.bonusText}>{litleText}</p>
        </div>
        <div className={s.containerCard}>
          <h4 className={s.onlyMobile}>Referral Count</h4>
          <h3 className={s.colorWhite_}>{referrals ? referrals : 0} Referral(s)</h3>
        </div>
        <div className={cx(s.containerCard)}>
          <h4 className={s.onlyMobile}>REFERRED</h4>
          <div className={s.coinColumn}>
            <span className={s.singleIcon}>{USDCTokenMeta.icon}</span>
            <h3 className={s.colorWhite_}>{getUSDCSumm()}</h3>
          </div>
        </div>
        <div className={s.containerCard}>
          <h4 className={s.onlyMobile}>ESTIMATED REWARD</h4>
          <div className={s.coinColumn}>
            <span className={s.singleIcon}>{SWAPPTokenMeta.icon}</span>
            <h3 className={s.colorWhite_}>{rewardLevel} SWAPP</h3>
          </div>
        </div>
        <div className={cx(s.containerCard)}>
          <h4 className={s.onlyMobile}>Reward</h4>
          <div className={cx(s.cardCenter, s.lastCard)}>
            <span className={s.singleIcon}>{SWAPPTokenMeta.icon}</span>
            <h3 className={s.colorWhite_}>{commonReward?.toFixed(2)} SWAPP</h3>
          </div>
        </div>
      </div>
  )
}

export default ReferralsSumary
