import React, { FunctionComponent, MouseEventHandler, useState, useEffect } from 'react';
import * as Antd from 'antd';
import Web3 from 'web3';
import { getHttpsRpcUrl, getEtherscanAddressUrl, getEtherscanTxUrl } from 'web3/utils';
import { useWallet } from 'wallets/wallet';
import { isEmpty } from "lodash";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from "moment";

import { ReactComponent as Refresh } from '../../../../resources/svg/icons/refresh.svg';
import { ReactComponent as List } from '../../../../resources/svg/icons/list.svg';
import CardWidget from 'components/card';
import ExternalLink from 'components/externalLink';
import IconsSet from 'components/icons-set';
import PaginationElement from 'components/pagination';
import { getPoolIcons, PoolTypes, getTokenMeta } from 'web3/utils';

import s from './styles.module.css';
import k from '../../../burn/styles.module.css';
import LinkModal from 'components/link-modal';

type SwappReferralsProps = {};

const menu = (
  <Antd.Menu className={s.listMenu}>
    <h3>Result</h3>
    <Antd.Menu.Item>5 results per page</Antd.Menu.Item>
    <Antd.Menu.Item>10 results per page</Antd.Menu.Item>
    <Antd.Menu.Item>15 results per page</Antd.Menu.Item>
    <h3>Order</h3>
    <Antd.Menu.Item>Ascending</Antd.Menu.Item>
    <Antd.Menu.Item>Descending</Antd.Menu.Item>
    <h3>Filter</h3>
    <Antd.Switch  defaultChecked={false} />
    <span>Hide closed stakes</span>
  </Antd.Menu>
);

const Contract  = require('web3/abi/staking.json');
const ContractSwapp  = require('web3/abi/swapp_staking.json');

const SwappReferrals: FunctionComponent<SwappReferralsProps> = () => {
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [referralsArray, setReferralsArray]: any[] = useState([]);
  const [loader, setLoader] = useState(false);
  const [paginationValues, setPaginationValues] = useState({ minValue: 0, maxValue: 10});
  const [poolFilter, setPoolFilter] = React.useState<PoolTypes>(PoolTypes.STABLE);

  let wallet = useWallet();

  useEffect(() => {
    if(wallet.account) {
      getContractEvents();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet.account]);

  useEffect(() => {
    if(!wallet.isActive) {
      setReferralsArray([])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet.isActive]);

  const getSecondLevelRefs = (filtered: any, results: any) => {
    let secondLeveleRefs: any= [];

    filtered.map((item: any) => {
      const address = item.returnValues[0].toLowerCase();
      const conectedAddresses: any = results.filter((result: any) => address === result.returnValues[1].toLowerCase())
      if (conectedAddresses?.length > 0) secondLeveleRefs = secondLeveleRefs.concat(conectedAddresses);
      return secondLeveleRefs;
    })

    const newData = filtered.concat(secondLeveleRefs);
    return newData;
  }

  async function getContractEvents() {
    const web3 = new Web3(new Web3.providers.HttpProvider(getHttpsRpcUrl()));
    const deployedNetworkAddress = process.env.REACT_APP_CONTRACT_STAKING_ADDR;
    const contract = new web3.eth.Contract(
      Contract,
      deployedNetworkAddress
      );
    const fromBlock: number = 12672708;
    const results = await contract.getPastEvents('RegisteredReferer',{ fromBlock, toBlock: 'latest' });
    const filtered = results.filter((item: any) => item.returnValues[1].toLowerCase() === wallet.account?.toLowerCase())

    const newData: any[] = [];
    if (filtered) {
      const allRefs: any = getSecondLevelRefs(filtered, results);
      setLoader(true);
      const promisesArray: any = [];

      for (let i = 0; i < allRefs.length; i++) {
        const res =  new Promise(async resolve => {
          try {
            let block = await web3.eth.getBlock(allRefs[i].blockNumber);
            let timestamp = await block.timestamp
            const tr = await web3.eth.getTransaction(allRefs[i].transactionHash);
            const input_data = '0x' + tr.input.slice(10);
            const decodedInput = await web3.eth.abi.decodeParameters([
              {
                "internalType": "address",
                "name": "tokenAddress",
                "type": "address"
              },
              {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
              },
              {
                "internalType": "address",
                "name": "referrer",
                "type": "address"
              }
            ], input_data)
            const res = await getTokenMeta(decodedInput.tokenAddress);

            newData.push({
              ...allRefs[i],
              date: timestamp,
              amount: decodedInput.amount,
              tokenObject: res,
            })

            return resolve(undefined);
          } catch (e) {
            return resolve(undefined);
          }
        });
        promisesArray.push(res);
      }
      await Promise.all(promisesArray);
      // setLoader(false);
    }

    const deployedNetworkAddressSwapp = process.env.REACT_APP_CONTRACT_STAKING_SWAPP_ADDR;
    const contractSwapp = new web3.eth.Contract(
      ContractSwapp,
      deployedNetworkAddressSwapp
      );
    const fromBlockSwapp: number = 12672708;
    const resultsSwapp = await contractSwapp.getPastEvents('RegisteredReferer',{ fromBlock: fromBlockSwapp, toBlock: 'latest' });
    const filteredSwapp = resultsSwapp.filter((item: any) => item.returnValues[1].toLowerCase() === wallet.account?.toLowerCase())

    if (filteredSwapp) {
      const allRefs: any = getSecondLevelRefs(filteredSwapp, resultsSwapp);
      // setLoader(true);
      const promisesArray: any = [];

      for (let i = 0; i < allRefs.length; i++) {
        const res =  new Promise(async resolve => {
          try {
            let block = await web3.eth.getBlock(allRefs[i].blockNumber);
            let timestamp = await block.timestamp
            const tr = await web3.eth.getTransaction(allRefs[i].transactionHash);
            const input_data = '0x' + tr.input.slice(10);
            const decodedInput = await web3.eth.abi.decodeParameters([
              {
                "internalType": "address",
                "name": "tokenAddress",
                "type": "address"
              },
              {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
              },
              {
                "internalType": "address",
                "name": "referrer",
                "type": "address"
              }
            ], input_data)
            const res = await getTokenMeta(decodedInput.tokenAddress);

            newData.push({
              ...allRefs[i],
              date: timestamp,
              amount: decodedInput.amount,
              tokenObject: res,
            })

            return resolve(undefined);
          } catch (e) {
            return resolve(undefined);
          }
        });
        promisesArray.push(res);
      }
      await Promise.all(promisesArray);
      setLoader(false);
    }

    newData.sort((a,b) => b.date - a.date);
    setReferralsArray(newData);
  }

  const handleOpenReferralModal: MouseEventHandler<HTMLElement> = (event) => {
    setShowLinkModal(true)
  }

  const getTokenDetails = (item: any) => {
    const formator = item?.tokenObject?.decimals === 6 ? '000000000000' : '';
    const result: any = Web3.utils.fromWei(item?.amount + formator, 'ether');
    const parser = parseFloat(result).toFixed(item?.tokenObject?.name === "WBTC_SWAPP_UNI_LP" ? 6 : 2)
    return `${parser} ${item?.tokenObject?.name === "WBTC_SWAPP_UNI_LP" ? 'LP' : item?.tokenObject?.name}`
  }

  const handlePaginationValues = (minValue: number, maxValue: number) => {
    setPaginationValues({minValue, maxValue});
  }

  return (
    <>
      <div className={s.titleBlock}>
        <div className={s.header}>
          <div className={s.labelTitle}>Swapp referrals</div>
          <div className={s.swappAnnouncements}>
            <Antd.Button
                onClick={handleOpenReferralModal}
                type="primary"
                className={s.btnSwapp}
            >
              Copy referral link
            </Antd.Button>
        </div>
        </div>
      </div>
      <div className={s.personalInfoContainer}>
        <CardWidget className={`${s.tableWrapper} ${isEmpty(referralsArray) ? s.infoBlock : ''}`}>
          {/* <Antd.Row>
            <Antd.Col className={s.percentReferral} xs={18} md={16} lg={22}>
              <span className={s.circle}>0%</span>
              <div className={s.circleText}>
                <p className={s.text1}>Status Not Achieved</p>
                <p className={s.text2}>Critical Mass Referrer</p>
              </div>
            </Antd.Col>
            <Antd.Col className={s.referralIcons} xs={6} md={8} lg={2}>
              <Antd.Dropdown overlay={menu} trigger={["click"]} >
                <List />
              </Antd.Dropdown>
              <Refresh />
            </Antd.Col>
          </Antd.Row> */}
          {loader ?
            <div className={k.preloader_custom}>
              <Spin indicator={<LoadingOutlined style={{ fontSize: 62 }} spin />} />
            </div>
            :
            isEmpty(referralsArray) ?
              <Antd.Col className={s.swappAnnouncements}>
                {/* <h1 className={s.rederrerStatus}>You don’t have referrals yet</h1> */}
                <p className={s.startPromoting}>
                  You don’t have referrals yet
                </p>
                <h3 className={s.startPromoting}>Start promoting SWAPP by creating your referral link</h3>
                {/* <Antd.Button
                  onClick={handleOpenReferralModal}
                  type="primary"
                  className={`${s.btnSwapp} ${s.withPadding}`}
                >
                  Create Swapp referral link
                </Antd.Button> */}
              </Antd.Col>
            :
            <>
              <div className={`${k.tableHeader} ${s.tableHeader}`}>
                <div><p>DATE</p></div>
                <div><p>POOL</p></div>
                <div>
                  <p>REFERRED AMOUNT</p>
                  {/* <span>(in USDC)</span> */}
                </div>
                {/* <div>
                  <p>ESTIMATED REWARD</p>
                  <span>(in SWAPP)</span>
                </div> */}
                <div><p>REFERRER WALLET</p></div>
                <div className={s.buttonContainer}>
                  <p>TRX:</p>
                </div>
              </div>
              { referralsArray.length > 0 &&
                referralsArray.slice(paginationValues.minValue, paginationValues.maxValue).map((item: any, key: number) => {
                  return (
                    <div className={`${k.tableRow} ${s.tableRow}`} key={key}>
                      <div>
                        <span>{moment.unix(item.date).format('MM/DD/YYYY')}</span>
                      </div>
                      <IconsSet className={s.iconSet} icons={getPoolIcons(item.tokenObject.name == 'WBTC_SWAPP_UNI_LP' ? PoolTypes.UNILP : item.tokenObject.name == 'SWAPP' ? PoolTypes.SWAPP : poolFilter)} />
                      <div className={`${s.cellBlock} ${s.cellFormat}`}>
                        <span className={s.singleIcon}>{item?.tokenObject?.icon}</span>
                        {getTokenDetails(item)}
                      </div>
                      {/* <div className={s.cellBlock}>
                        <span className={s.singleIcon}>{SWAPPTokenMeta.icon}</span>
                        0.00 SWAPP
                        Comming Soon
                      </div> */}
                      <div className={`${k.linkWrapper} ${s.linkWrapper}`}>
                        <ExternalLink
                          href={getEtherscanAddressUrl(item.returnValues.referral)}
                          className={k.walletPreviewHash}
                        >
                          {item.returnValues.referral.substr(0, 6) + "..." + item.returnValues.referral.substr(-4)}
                        </ExternalLink>
                      </div>
                      <div className={`${k.linkWrapper} ${s.linkWrapper}`}>
                        <ExternalLink
                          href={getEtherscanTxUrl(item.transactionHash)}
                          className={k.walletPreviewHash}
                        >
                          {item.transactionHash.substr(0, 6) + "..." + item.transactionHash.substr(-4)}
                        </ExternalLink>
                      </div>
                    </div>
                  )
                })
              }
              <PaginationElement
                arrayLength={referralsArray.length}
                handlePaginationValues={handlePaginationValues}
              />
            </>
          }
        </CardWidget>
        <LinkModal visible={showLinkModal} setShowLinkModal={setShowLinkModal} />
      </div>
    </>
  );
};

export default SwappReferrals;
