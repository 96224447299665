import React from 'react';
import BigNumber from 'bignumber.js';
import { useWeb3Contracts } from 'web3/contracts';
import { getTotalTransactions } from 'subgraphs/actions';

import { PoolTypes, getTokenMeta, getPoolAddresses } from 'web3/utils';

type StakingAction = {
  sumDeposits: string;
  sumWithdrawals: string;
};

type OriginStakingAction = {
  id: string;
  type: string;
  amount: string;
  blockTimestamp: number;
  blockNumber: number;
  token: string;
  txHash: string;
  user: string;
};

export type PoolTxChartSummary = {
  timestamp: string;
  deposits: number;
  withdrawals: number;
};

export type PoolTxChartQuery = {
  pool?: PoolTypes;
  period?: string;
  type?: string;
};

export type PoolTxChartType = {
  summaries: PoolTxChartSummary[];
  loading: boolean;
  loaded: boolean;
  load: (query?: PoolTxChartQuery) => Promise<void>;
  startPooling: () => void;
  stopPooling: () => void;
};

const PoolTxChartContext = React.createContext<PoolTxChartType>({
  summaries: [],
  loading: false,
  loaded: false,
  load: () => Promise.reject(),
  startPooling: () => undefined,
  stopPooling: () => undefined,
});

export function usePoolTxChart(): PoolTxChartType {
  return React.useContext(PoolTxChartContext);
}

const TX_API_URL = String(process.env.REACT_APP_TOKEN_API_URL);
const TX_POOL_INTERVAL = Number(process.env.REACT_APP_TOKEN_API_POOL_INTERVAL);

const PoolTxChartProvider: React.FunctionComponent = props => {
  const { staking } = useWeb3Contracts();

  const [, forceRender] = React.useState<{}>({});
  const summariesRef = React.useRef<PoolTxChartSummary[]>([]);
  const loadingRef = React.useRef<boolean>(false);
  const loadedRef = React.useRef<boolean>(false);

  const poolFilterRef = React.useRef<PoolTypes | undefined>();
  const periodFilterRef = React.useRef<string | undefined>();
  const typeFilterRef = React.useRef<string | undefined>();

  const poolingIntervalID = React.useRef<any | undefined>();
  
  const fetchData = React.useCallback(async () => {
    let epoch1Start = 1624230000000;
    let epochDuration = 2419200000;
    if (poolFilterRef.current === 'swapp') {
      epoch1Start = 1626652800000;
    }

    loadingRef.current = true;
    forceRender({});

    let start: number = 0;
    let end: number = 0;
    if (Number(periodFilterRef.current) == 0) {
      end = epoch1Start /1000;
    } else if (Number(periodFilterRef.current) > 0) {
      start = epoch1Start + ((Number(periodFilterRef.current) - 1 ) * epochDuration);
      end = start + epochDuration;

      start /= 1000;
      end /= 1000;
    }

    try {
      const result = await getTotalTransactions(
        start,
        end,
        typeFilterRef.current,
        getPoolAddresses(poolFilterRef.current)
      );
      let stakingActions: {
        [period: string]: StakingAction,
      } = {};

      if (start || end) {
        const unitPeriod = (end - start) / 7;
        result.map((action: any) => {
          const tokenMeta = getTokenMeta(action.token);
          if (!tokenMeta) {
            return;
          }

          const groupNum = (action.blockTimestamp - start) / unitPeriod;
          const timestamp = start + groupNum * unitPeriod;
          let temp: StakingAction = stakingActions[timestamp.toString()] ?
            stakingActions[timestamp.toString()] : {
              sumDeposits: "0",
              sumWithdrawals: "0"
            };

          if (action.type == "DEPOSIT") {
            stakingActions[timestamp.toString()] = {
              sumDeposits: (Number(temp.sumDeposits) +
                new BigNumber(action.amount).div(new BigNumber(10).pow(tokenMeta.decimals)).toNumber()).toString(),
              sumWithdrawals: temp.sumWithdrawals
            }
          } else if (action.type == "WITHDRAW") {
            stakingActions[timestamp.toString()] = {
              sumDeposits: temp.sumDeposits,
              sumWithdrawals: (Number(temp.sumWithdrawals) +
                new BigNumber(action.amount).div(new BigNumber(10).pow(tokenMeta.decimals)).toNumber()).toString()
            }
          }
        })
      } else {
        result.map((action: any) => {
          const tokenMeta = getTokenMeta(action.token);
          const epoch = Math.floor((action.blockTimestamp * 1000 - epoch1Start) / epochDuration + 1);

          if (epoch !== undefined && tokenMeta) {
            let temp: StakingAction = stakingActions[epoch] ?
            stakingActions[epoch] : {
              sumDeposits: "0",
              sumWithdrawals: "0"
            };

            if (action.type == "DEPOSIT") {
              stakingActions[epoch] = {
                sumDeposits: (Number(temp.sumDeposits) +
                  new BigNumber(action.amount).div(new BigNumber(10).pow(tokenMeta.decimals)).toNumber()).toString(),
                sumWithdrawals: temp.sumWithdrawals
              }
            } else if (action.type == "WITHDRAW") {
              stakingActions[epoch] = {
                sumDeposits: temp.sumDeposits,
                sumWithdrawals: (Number(temp.sumWithdrawals) +
                  new BigNumber(action.amount).div(new BigNumber(10).pow(tokenMeta.decimals)).toNumber()).toString()
              }
            }
          }
        })
      }

      const summaries: PoolTxChartSummary[] = [];

      Object.entries(stakingActions).forEach(([k, v]) => {
        let timestamp: string = k;

        if (!periodFilterRef.current) {
          timestamp = `Epoch ${k}`;
        }

        summaries.push({
          timestamp,
          deposits: Number(v.sumDeposits),
          withdrawals: Number(v.sumWithdrawals),
        });
      });

      summariesRef.current = summaries;
    } catch (e) {
      console.error(e);
    }

    loadingRef.current = false;
    loadedRef.current = true;
    forceRender({});
  }, []);

  const load = React.useCallback((query?: PoolTxChartQuery) => {
    loadedRef.current = false;
    poolFilterRef.current = query?.pool;
    periodFilterRef.current = query?.period;
    typeFilterRef.current = query?.type;
    forceRender({});

    return fetchData();
  }, [fetchData]);

  const startPooling = React.useCallback(() => {
    if (!poolingIntervalID.current) {
      poolingIntervalID.current = setInterval(() => {
        fetchData()
          .catch(x => x);
      }, TX_POOL_INTERVAL);
    }
  }, [load]); // eslint-disable-line react-hooks/exhaustive-deps

  const stopPooling = React.useCallback(() => {
    if (poolingIntervalID.current) {
      clearInterval(poolingIntervalID.current);
      poolingIntervalID.current = undefined;
    }
  }, []);

  const value = React.useMemo(() => ({
    summaries: summariesRef.current,
    loading: loadingRef.current,
    loaded: loadedRef.current,
    load,
    startPooling,
    stopPooling,
  }), [ // eslint-disable-line react-hooks/exhaustive-deps
    summariesRef.current,
    loadingRef.current,
    loadedRef.current,
    load,
    startPooling,
    stopPooling,
  ]);

  return (
    <PoolTxChartContext.Provider value={value}>
      {props.children}
    </PoolTxChartContext.Provider>
  );
};

export default PoolTxChartProvider;
