import moment from "moment";
import { numberWithCommas } from 'functions/helpers';

export const months = Array.from({ length: 12 }, (x, i) => moment().startOf('year').add(i, 'months').format('MMM-YYYY').toString())
export const days = Array.from({length: moment().daysInMonth()}, 
  (x, i) => moment().startOf('month').add(i, 'days').format('DD-MM-YYYY'));

export const burnedPercentOptions = {
  plotOptions: {
    pie: {
      donut: {
        size: '35%',
      }
    }
  },
  dataLabels: {
    enabled: false
  },
  labels: ['Burned', 'Temporary Burned (Staking)', 'Remaining'],
  colors: ['#FF6645', '#598afb' , '#EEF769'],
  legend: {
    show: true,
    offsetX: -40,
    offsetY: 10,
    markers: {
      width: 55,
      height: 18,
      strokeWidth: 0.1,
      strokeColor: '#fff',
      fillColors: undefined,
      radius: 0,
      customHTML: undefined,
      onClick: undefined,
      offsetX: 0,
      offsetY: 0
    },
    fontSize: '16px',
    labels: {
      colors: '#fff',
      useSeriesColors: false
    },
  },
  tooltip: {
    enabled: true,
    fillSeriesColor: false,
    y: {
      formatter: function(val) {
        return numberWithCommas(val.toFixed(2))
      },
    }
  },
};

export const burnRateOptions = {
  chart: {
    animations: {
      enabled: false
    },
    height: 160,
    toolbar: {
      show: false
    }
  },
  colors: ['#EEF769'],
  dataLabels: {
    enabled: false
  },
  xaxis: {
    labels: {
      show: true,
      style: {
        colors: "#7F7F94",
        fontSize: '12px'
      },
    },
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false
    },
    tooltip: {
      enabled: false
    },
    categories: months
  },
  yaxis: {
    show: true,
    labels: {
      formatter: function(val) {
        return numberWithCommas(val.toFixed(2))
      },
      show: true,
      style: {
        colors: "#7F7F94",
        fontSize: '12px'
      }
    },
  },
  tooltip: {
    shared: false
  },
  fill: {
    colors: '#a5484823',
    type: 'solid',
    gradient: {
      // shadeIntensity: 1,
      opacityFrom: 0.3,
      opacityTo: 0.9,
      stops: [0, 100]
    }
  },
  grid: {
    show: false
  },
  stroke: {
    show: true,
    curve: 'smooth',
    lineCap: 'butt',
    colors: undefined,
    width: 1.5,
    dashArray: 0
  },
  markers: {
    size: 5,
    colors: ["#EEF769"],
    strokeColor: "#00BAEC",
    strokeWidth: 0
  },
};

export const burnRateOptionsCurrentMonth = {
  chart: {
    animations: {
      enabled: false
    },
    height: 160,
    toolbar: {
      show: false
    }
  },
  colors: ['#EEF769'],
  dataLabels: {
    enabled: false
  },
  xaxis: {
    labels: {
      show: true,
      style: {
        colors: "#7F7F94",
        fontSize: '12px'
      },
    },
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false
    },
    tooltip: {
      enabled: false
    },
    categories: days
  },
  yaxis: {
    show: true,
    labels: {
      formatter: function(val) {
        return numberWithCommas(val.toFixed(2))
      },
      show: true,
      style: {
        colors: "#7F7F94",
        fontSize: '12px'
      },
    },
  },
  tooltip: {
    shared: false
  },
  fill: {
    colors: '#a5484823',
    type: 'solid',
    gradient: {
      // shadeIntensity: 1,
      opacityFrom: 0.3,
      opacityTo: 0.9,
      stops: [0, 100]
    }
  },
  grid: {
    show: false
  },
  stroke: {
    show: true,
    curve: 'smooth',
    lineCap: 'butt',
    colors: undefined,
    width: 1.5,
    dashArray: 0
  },
  markers: {
    size: 5,
    colors: ["#EEF769"],
    strokeColor: "#00BAEC",
    strokeWidth: 0
  },
};