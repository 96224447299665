import React, {useState, useEffect, useMemo} from 'react';
import cx from 'classnames';

import { numberWithCommas } from 'functions/helpers';
import { useWallet } from 'wallets/wallet';
import { useWeb3Contracts } from 'web3/contracts';
import {
  formatSWAPPValue,
  getHttpsRpcUrl,
  getHumanValue,
  getNetworkAddress
} from 'web3/utils';
import { SWAPPTokenMeta } from 'web3/contracts/swapp';
import { ReactComponent as WalletIcon } from 'resources/svg/balance/swapp.svg';
import { ReactComponent as RewardIcon } from 'resources/svg/balance/current.svg';
import { ReactComponent as CalendarIcon } from 'resources/svg/balance/Calendar.svg';
import Web3 from 'web3';

import PoolHarvestModal from 'views/pools/components/pool-harvest-modal';
import s from './styles.module.css';
import BigNumber from 'bignumber.js';

const pagesTitle = [
      {
        title: 'Dashboard',
        path: '/dashboard'
      },
      {
        title: 'Personal board',
        path: '/personal-dashboard'
      },
      {
        title: 'Pools',
        path: '/pools'
      },
      {
        title: 'Voting',
        path: '/voting'
      },
      {
        title: 'Burn',
        path: '/burn'
      },
      {
        title: 'Bridge',
        path: '/bridge'
      }
    ];


const PoolRewards: React.FunctionComponent = (props) => {
  const wallet = useWallet();
  const { swapp, aggregated } = useWeb3Contracts();
  const [harvestVisible, setHarvestVisible] = useState<boolean>(false);
  const [balance, setBalance] = useState(new BigNumber(0));
  const [totalSupply, setTotalSupply] = useState();
  const [circulatingSupply, setCirculatingSupply] = useState();

  const title = useMemo(()=>{
    return pagesTitle.find(el=>el.path===window.location.pathname)?.title;
  },[window.location.pathname])


  let web3 = new Web3(new Web3.providers.HttpProvider(getHttpsRpcUrl()));

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    web3 = new Web3(
      new Web3.providers.HttpProvider(getHttpsRpcUrl(wallet.networkId))
    );
    getBridgeEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet.networkId, wallet.provider, swapp.reload, wallet.account]);

  useEffect(() => {
    const url = process.env.REACT_APP_BASE_API_URI;
    fetch(`${url}/swapp-token/total-supply`)
      .then((result) => result.json())
      .then((result) => {
        setTotalSupply(result);
      });
    fetch(`${url}/swapp-token/circulation-supply`)
      .then((result) => result.json())
      .then((result) => {
        setCirculatingSupply(result);
      });
  }, [swapp.reload]);

  const getBridgeEvents = async () => {
    const CONTRACT_SWAPP_ADDR = getNetworkAddress(wallet.networkId);

    const contractSwappBridge = new web3.eth.Contract(
      require('web3/abi/swapp.json'),
      CONTRACT_SWAPP_ADDR
    );

    let newBalance: BigNumber | undefined = balance;

    if (wallet.account && wallet.networkId) {
      newBalance = await contractSwappBridge.methods
        .balanceOf(wallet.account)
        .call();
      newBalance = getHumanValue(
        new BigNumber(newBalance!),
        SWAPPTokenMeta.decimals
      );
      setBalance(newBalance!);
    }
  };

  function onHarvestClick() {
    setHarvestVisible(true);
  }

  function onModalClose() {
    setHarvestVisible(false);
  }

  return (
      <div className={s.container}>

          {/*Supply Info Section*/}

          <div className={s.header}>
            <div className={s.title}>{title}</div>
              {totalSupply! && circulatingSupply! && (
                  <div className={s.supplyBox}>
                      <div className={s.supplyItem}>
                          <span className={s.supplyTitle}>
                                Total Supply:
                            </span>
                          <span className={s.supplyIcon}>
                               {SWAPPTokenMeta.icon}
                            </span>
                          <span className={s.supplyAmount}>
                            {numberWithCommas((+totalSupply!).toFixed(0))}
                            </span>
                      </div>
                      <div className={s.supplyItem}>
                          <span className={s.supplyTitle}>
                                Circulating Supply:
                            </span>
                          <span className={s.supplyIcon}>
                               {SWAPPTokenMeta.icon}
                            </span>
                          <span className={s.supplyAmount}>
                            {numberWithCommas(
                                (+circulatingSupply!).toFixed(0)
                            )}
                            </span>
                      </div>
                  </div>
              )}
          </div>

          {/*Wallet Balance Info Section*/}

          {(wallet.isActive && (wallet.networkId === 1 || wallet.networkId === 3)) && (
              <div className={s.balanceContainer}>
                  <div className={s.balanceTitle}>
                      My balance
                  </div>
                  <div className={s.balanceList}>
                      <div className={s.balanceItem}>
                          <div className={cx(s.balanceIcon,s.walletIcon)}>
                              <WalletIcon />
                          </div>
                          <div className={s.balanceBody}>
                              <div className={s.balanceItemTitle}>
                                  Swapp balance
                              </div>
                              <div className={s.balanceAmount}>
                                  <span className={s.supplyIcon}>
                                    {SWAPPTokenMeta.icon}
                                  </span>
                                  <span className={s.supplyAmount}>
                                    {formatSWAPPValue(balance)}
                                  </span>
                              </div>
                          </div>
                          <div
                              className={cx(s.balanceBtn,s.receiveBtn)}
                          >
                              Receive
                          </div>
                      </div>
                      <div className={s.balanceItem}>
                          <div className={cx(s.balanceIcon,s.rewardIcon)}>
                              <RewardIcon />
                          </div>
                          <div className={s.balanceBody}>
                              <div className={s.balanceItemTitle}>
                                  Current reward
                              </div>
                              <div className={s.balanceAmount}>
                                  <span className={s.supplyIcon}>
                                    {SWAPPTokenMeta.icon}
                                  </span>
                                  <span className={s.supplyAmount}>
                                    {formatSWAPPValue(aggregated?.totalCurrentReward!)}
                                  </span>
                              </div>
                          </div>
                          <div
                              className={cx(s.balanceBtn,s.claimBtn)}
                              onClick={onHarvestClick}
                          >
                              Claim
                          </div>
                      </div>
                      <div className={s.balanceItem}>
                          <div className={cx(s.balanceIcon,s.calendarIcon)}>
                              <CalendarIcon />
                          </div>
                          <div className={s.balanceBody}>
                              <div className={s.balanceItemTitle}>
                                  Potential reward this epoch
                              </div>
                              <div className={s.balanceAmount}>
                                  <span className={s.supplyIcon}>
                                    {SWAPPTokenMeta.icon}
                                  </span>
                                  <span className={s.supplyAmount}>
                                    {formatSWAPPValue(aggregated?.totalPotentialReward!)}
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          )}
          <PoolHarvestModal visible={harvestVisible} onCancel={onModalClose} />
      </div>
  );
};

export default PoolRewards;
