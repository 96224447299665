import React, { useState, useEffect } from 'react';
import * as Antd from 'antd';
import BigNumber from 'bignumber.js';

import BonusCalculator from './index';

import s from './styles.module.css';

export type CalcModalWrapperProps = {
  visible: boolean,
  hideInput: boolean,
  setVisibility?: Function,
};

const CalcModalWrapper: React.FunctionComponent<CalcModalWrapperProps> = ({ visible, setVisibility, hideInput }) => {
  const [amount, setAmount] = useState<BigNumber | undefined>(new BigNumber(0));

  useEffect(() => {
    if (hideInput) setAmount(new BigNumber(1000000));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hahdleClose = () => {
    if (setVisibility) setVisibility(false)
    setAmount(new BigNumber(0));
  }

  return (
    <div className={s.component}>
      <Antd.Modal
        centered
        closable={false}
        onCancel={hahdleClose}
        footer={[]}
        visible={visible}
        className={s.coming}
      >
        <BonusCalculator 
          visible={visible}
          setVisibility={setVisibility}
          hideInput={hideInput}
        />
      </Antd.Modal>
    </div>
  );
};

export default CalcModalWrapper;
