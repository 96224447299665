import React from 'react';
import BigNumber from 'bignumber.js';

import { useReload } from 'hooks/useReload';
import { useAsyncEffect } from 'hooks/useAsyncEffect';
import { TokenMeta } from 'web3/types';
import { getHumanValue } from 'web3/utils';
import { useWallet } from 'wallets/wallet';
import Web3Contract from 'web3/contract';
import { CONTRACT_STAKING_ADDR } from 'web3/contracts/staking';
import { USDCTokenMeta } from 'web3/contracts/usdc';
import { SWAPPTokenMeta } from 'web3/contracts/swapp';
import { WBTCTokenMeta } from 'web3/contracts/wbtc';

import { ReactComponent as UNISWAPIcon } from 'resources/svg/tokens/exchange.svg';
// import UNISWAPImg from 'resources/svg/tokens/exchange.png';

export const CONTRACT_UNISWAP_ADDR = String(
	process.env.REACT_APP_CONTRACT_UNISWAP_V2_ADDR
).toLowerCase();
export const WBTC_USDC_UNISWAP_ADDR = String(
	process.env.REACT_APP_WBTC_USDC_UNISWAP_ADDR
).toLowerCase();

export const UNISWAPTokenMeta: TokenMeta = {
	icon: <UNISWAPIcon key='uniswap' />,
	// icon: <img src={UNISWAPImg} key="uniswap" width="24px" />,
	name: 'WBTC_SWAPP_UNI_LP',
	address: CONTRACT_UNISWAP_ADDR,
	decimals: 18,
};

type UNISWAPContractData = {
	totalSupply?: BigNumber;
	wbtcReserve?: BigNumber;
	swappReserve?: BigNumber;
	stablePrice: BigNumber;
	unilpPrice?: BigNumber;
	swappPrice?: BigNumber;
	balance?: BigNumber;
	allowance?: BigNumber;
	// wbtcPoolBalance?: BigNumber;
};

export type UNISWAPContract = UNISWAPContractData & {
	contract: Web3Contract;
	reload(): void;
	approveSend(value: BigNumber): Promise<any>;
};

const InitialData: UNISWAPContractData = {
	totalSupply: undefined,
	wbtcReserve: undefined,
	swappReserve: undefined,
	stablePrice: new BigNumber(1),
	unilpPrice: undefined,
	swappPrice: undefined,
	balance: undefined,
	allowance: undefined,
	// wbtcPoolBalance: undefined,
};

export function useUNISWAPContract(): UNISWAPContract {
	const [reload] = useReload();
	const wallet = useWallet();

	const contract = React.useMemo<Web3Contract>(() => {
		return new Web3Contract(
			require('web3/abi/uniswap_v2.json'),
			CONTRACT_UNISWAP_ADDR,
			'UNISWAP'
		);
	}, []);

	const contractWBTCUSDC = React.useMemo<Web3Contract>(() => {
		return new Web3Contract(
			require('web3/abi/uniswap_v2.json'),
			WBTC_USDC_UNISWAP_ADDR,
			'UNISWAP'
		);
	}, []);

	const [data, setData] = React.useState<UNISWAPContractData>(InitialData);

	useAsyncEffect(async () => {
		const [
			totalSupply,
			reserves,
			token0,
			token1,
		] = await contractWBTCUSDC.batch([
			{
				method: 'totalSupply',
				transform: (value: string) =>
					getHumanValue(new BigNumber(value), UNISWAPTokenMeta.decimals),
			},
			{
				method: 'getReserves',
				transform: (value: string[]) => [
					new BigNumber(value[0]),
					new BigNumber(value[1]),
				],
			},
			{
				method: 'token0',
				transform: (value: string) => value.toLowerCase(),
			},
			{
				method: 'token1',
				transform: (value: string) => value.toLowerCase(),
			},
		]);

		const [
			totalSupplySwapp,
			reservesSwapp,
			token0Swapp,
			token1Swapp,
		] = await contract.batch([
			{
				method: 'totalSupply',
				transform: (value: string) =>
					getHumanValue(new BigNumber(value), UNISWAPTokenMeta.decimals),
			},
			{
				method: 'getReserves',
				transform: (value: string[]) => [
					new BigNumber(value[0]),
					new BigNumber(value[1]),
				],
			},
			{
				method: 'token0',
				transform: (value: string) => value.toLowerCase(),
			},
			{
				method: 'token1',
				transform: (value: string) => value.toLowerCase(),
			},
		]);

		let wbtcReserve: BigNumber | undefined;
		let usdcReserve: BigNumber | undefined;

		let wbtcSwappReserve: BigNumber | undefined;
		let swappReserve: BigNumber | undefined;

		if (token0 === USDCTokenMeta.address) {
			wbtcReserve = getHumanValue(reserves[1], WBTCTokenMeta.decimals);
			usdcReserve = getHumanValue(reserves[0], USDCTokenMeta.decimals);
		} else if (token1 === USDCTokenMeta.address) {
			wbtcReserve = getHumanValue(reserves[0], WBTCTokenMeta.decimals);
			usdcReserve = getHumanValue(reserves[1], USDCTokenMeta.decimals);
		}

		if (token0Swapp === WBTCTokenMeta.address) {
			wbtcSwappReserve = getHumanValue(
				reservesSwapp[0],
				WBTCTokenMeta.decimals
			);
			swappReserve = getHumanValue(reservesSwapp[1], SWAPPTokenMeta.decimals);
		} else if (token1Swapp === WBTCTokenMeta.address) {
			wbtcSwappReserve = getHumanValue(
				reservesSwapp[1],
				WBTCTokenMeta.decimals
			);
			swappReserve = getHumanValue(reservesSwapp[0], SWAPPTokenMeta.decimals);
		}

		// const lpPrice = wbtcSwappReserve
		//     ?.div(totalSupplySwapp ?? 1)
		//     .multipliedBy(2);

		const wbtcPrice = usdcReserve?.div(wbtcReserve ?? 1);

		const lpPrice = wbtcSwappReserve
			?.multipliedBy(wbtcPrice ?? 1)
			?.div(totalSupplySwapp ?? 1)
			.multipliedBy(2);

		// console.log(wbtcSwappReserve?.multipliedBy(wbtcPrice?? 1).multipliedBy(2).toNumber());
		// console.log(totalSupplySwapp.toNumber());
		// console.log(wbtcSwappReserve?.multipliedBy(wbtcPrice?? 1).multipliedBy(2).div(totalSupplySwapp ?? 1).toNumber());

		// const wbtcPoolBalance = wbtcSwappReserve?.multipliedBy(wbtcPrice?? 1).multipliedBy(2);

		const swappPrice = wbtcSwappReserve
			?.multipliedBy(wbtcPrice ?? 1)
			?.div(swappReserve ?? 1);

		setData((prevState) => ({
			...prevState,
			totalSupply: totalSupplySwapp,
			wbtcReserve: wbtcSwappReserve,
			unilpPrice: lpPrice,
			swappReserve,
			swappPrice,
			// wbtcPoolBalance,
		}));
	}, []);

	useAsyncEffect(async () => {
		let balance: BigNumber | undefined;
		let allowance: BigNumber | undefined;

		if (wallet.account) {
			[balance, allowance] = await contract.batch([
				{
					method: 'balanceOf',
					methodArgs: [wallet.account],
					transform: (value: string) =>
						getHumanValue(new BigNumber(value), UNISWAPTokenMeta.decimals),
				},
				{
					method: 'allowance',
					methodArgs: [wallet.account, CONTRACT_STAKING_ADDR],
					transform: (value: string) => new BigNumber(value),
				},
			]);
		}

		setData((prevState) => ({
			...prevState,
			balance,
			allowance,
		}));
	}, [reload, wallet.account]);

	const approveSend = React.useCallback(
		(value: BigNumber): Promise<any> => {
			if (!wallet.account) {
				return Promise.reject();
			}

			return contract
				.send('approve', [CONTRACT_STAKING_ADDR, value], {
					from: wallet.account,
				})
				.then(reload);
		},
		[reload, contract, wallet.account]
	);

	return React.useMemo<UNISWAPContract>(
		() => ({
			...data,
			contract,
			reload,
			approveSend,
		}),
		[data, contract, reload, approveSend]
	);
}
