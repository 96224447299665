import React from 'react';
import * as Antd from 'antd';
import { ModalProps } from 'antd/lib/modal';

import s from './styles.module.css';

export type UserRejectedModalProps = ModalProps & {};

const DurationModal: React.FunctionComponent<UserRejectedModalProps> = props => {
  const { ...modalProps } = props;

  return (
    <Antd.Modal
      className={s.component}
      centered
      footer={[]}
      {...modalProps}
      zIndex={1001}
      closable={false}
    >
      <div className={s.headerLabel}>Please confirm you are sure you want to stake for this duration.</div>
      <div className={s.text}>You will not be able to change your stake duration until the maturity (end) date. You will only be able to “top up” your stake.</div>
      <div className={s.btnWrapper}>
        <Antd.Button
          type="primary"
          className={s.dismissBtn}
          onClick={props.onCancel}
        >Got it</Antd.Button>
      </div>
    </Antd.Modal>
  );
};

export default DurationModal;
