import React from 'react';
import cx from 'classnames';

import InfoTooltip from 'components/info-tooltip';
import s from './styles.module.css';

export type CardProps = {
  className?: string;
  onClick?: VoidFunction;
  help?: React.ReactNode;
};

const CardWidget: React.FunctionComponent<CardProps> = props => {
  return (
    <div className={cx(s.component, props.className)} onClick={props.onClick}>
      {props.help && (
        <div className={s.customTooltip}>
          <InfoTooltip className={s.tooltip} title={props.help} />
        </div>
      )}
      {props.children}
    </div>
  );
};

export default CardWidget;
