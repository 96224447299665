/* eslint-disable no-loop-func */
import React from 'react';
import BigNumber from 'bignumber.js';

import { useReload } from 'hooks/useReload';
import { useAsyncEffect } from 'hooks/useAsyncEffect';
import { useRefState } from 'hooks/useRefState';
import { TokenMeta } from 'web3/types';
import { getHumanValue, getNonHumanValue } from 'web3/utils';
import { useWallet } from 'wallets/wallet';
import Web3Contract, { BatchContractMethod } from 'web3/contract';
import { SWAPPTokenMeta } from 'web3/contracts/swapp';

export const CONTRACT_STAKING_ADDR_SWAPP = String(process.env.REACT_APP_CONTRACT_STAKING_SWAPP_ADDR);

type StakingTokenData = {
  epochPoolSize?: BigNumber;
  nextEpochPoolSize?: BigNumber;
  balance?: BigNumber;
  epochUserBalance?: BigNumber;
  nextEpochUserBalance?: BigNumber;
};

type StakingContractData = {
  referrers?: number;
  referrers2Level?: number;
  swappCoinBalance?: number;
  swappCoinBalance2Level?: number;
  currentEpoch?: number;
  totalEpochs?: number,
  epoch1Start?: number;
  epochDuration?: number;
  currentEpochEnd?: number;
  hasReferrer?: boolean; 
  referrals?: string;
  referrals1Level?: string[];
  referrals2Level?: string[];
  stakes?: any;
  swapp: StakingTokenData;
};

export type StakingSwappContract = StakingContractData & {
  contract: Web3Contract;
  reload(): void;
  depositSend: (tokenMeta: TokenMeta, amount: BigNumber, endEpoch: number, gasPrice: number) => void;
  withdrawSend: (tokenMeta: TokenMeta, amount: BigNumber, gasPrice: number) => void;
  getEpochAt: (timestamp: number) => number | undefined;
  getEpochPeriod: (epoch: number) => [number, number] | undefined;
  getPenalty: any;
}

const InitialData: StakingContractData = {
  referrers: undefined,
  referrers2Level: undefined,
  swappCoinBalance: undefined,
  swappCoinBalance2Level: undefined,
  currentEpoch: undefined,
  totalEpochs: undefined,
  epoch1Start: undefined,
  epochDuration: undefined,
  currentEpochEnd: undefined,
  hasReferrer: undefined,
  referrals: undefined,
  referrals1Level: undefined,
  referrals2Level: undefined,
  stakes: undefined,
  swapp: {
    epochPoolSize: undefined,
    nextEpochPoolSize: undefined,
    balance: undefined,
    epochUserBalance: undefined,
    nextEpochUserBalance: undefined,
  },
};

export function useStakingSwappContract(): StakingSwappContract {
  const [reload] = useReload();
  const wallet = useWallet();

  const contract = React.useMemo<Web3Contract>(() => {
    return new Web3Contract(
      require('web3/abi/swapp_staking.json'),
      CONTRACT_STAKING_ADDR_SWAPP,
      'STAKING_SWAPP',
    );
  }, []);

  const [data, setData, dataRef] = useRefState<StakingContractData>(InitialData);

  const getReferralByIdFunction = async (address: string | undefined, counter: number) => {
    return await contract.batch([
      {
        method: 'getReferralById',
        methodArgs: [address, counter],
      },
    ]);
  }

  const getPenalty = React.useCallback(async (tokenMeta, amount) => {
    let penalty: BigNumber | undefined;

    [penalty] = await contract.batch([
      {
        method: 'calcPenalty',
        callArgs: { from: wallet.account },
        methodArgs: [getNonHumanValue(amount, tokenMeta.decimals)],
      },
    ]);

    return penalty;
  }, [contract, wallet.account])

  const getBalanceOf = async (getReferralById: string) => {
    return await contract.batch([
      ...[
        SWAPPTokenMeta,
      ].reduce((ac: BatchContractMethod[], token) => {
        return [
          ...ac,
          {
            method: 'balanceOf',
            methodArgs: [getReferralById, token.address],
            transform: (value: string) => getHumanValue(new BigNumber(value), token.decimals),
          },
        ];
      }, []),
    ])
  }

  useAsyncEffect(async () => {
    const [
      referrers,
      hasReferrer,
      referrals,
      currentEpoch,
      epoch1Start,
      epochDuration,
      totalEpochs,
      stakes,
    ] = await contract.batch([
      {
        method: 'referrers',
        methodArgs: [wallet.account],
        transform: (value: string) => Number(value),
      },
      {
        method: 'hasReferrer',
        methodArgs: [wallet.account],
        transform: (value: boolean) => value,
      },
      {
        method: 'referrals',
        methodArgs: [wallet.account],
        transform: (value: string) => value,
      },
      {
        method: 'getCurrentEpoch',
        transform: (value: string) => Number(value),
      },
      {
        method: 'epoch1Start',
        transform: (value: string) => Number(value) * 1000,
      },
      {
        method: 'epochDuration',
        transform: (value: string) => Number(value) * 1000,
      },
      {
        method: 'NR_OF_EPOCHS',
        transform: (value: string) => Number(value),
      },
      {
        method: 'stakes',
        methodArgs: [wallet.account],
        transform: (value: any) => value,
      },
    ]);

    let referrals1Level: string[] = [];
    let swappCoinBalance = 0;
    const promisesArray: any = [];

    for (let i = 1; i <= referrers; i++) {
      const res =  new Promise(async resolve => {
        try {
          const [getReferralById] = await getReferralByIdFunction(wallet.account, i)
          referrals1Level.push(getReferralById);

          const [
            swappBalance
          ] = await getBalanceOf(getReferralById);

          swappCoinBalance += +swappBalance;
          
          return resolve(undefined);
        } catch (e) {
          return resolve(undefined);
        }
      });
      promisesArray.push(res);
    }
    await Promise.all(promisesArray);

    setData(prevState => ({
      ...prevState,
      referrers,
      swappCoinBalance,
      currentEpoch,
      totalEpochs,
      epoch1Start,
      epochDuration,
      hasReferrer,
      referrals,
      referrals1Level,
      stakes,
      currentEpochEnd: epoch1Start + (currentEpoch * epochDuration),
    }));

    let referrers2Level = 0;
    let swappCoinBalance2Level = 0;
    let referrals2Level: string[] = [];

    for (let i = 0; i < referrals1Level.length; i++) {
      const [referrers] = await contract.batch([
        {
          method: 'referrers',
          methodArgs: [referrals1Level[i]],
          transform: (value: string) => Number(value),
        },
      ]);
      referrers2Level += referrers;
      const promisesArray3: any = [];

      for (let y = 0; y <= referrers; y++) {
        const res =  new Promise(async resolve => {
          try {
            const [getReferralById] = await getReferralByIdFunction(referrals1Level[i], y)

            if (getReferralById !== "0x0000000000000000000000000000000000000000") {
              referrals2Level.push(getReferralById);
              const [
                swappBalance
              ] = await getBalanceOf(getReferralById);
      
              swappCoinBalance2Level += +swappBalance;
            }
            return resolve(undefined);
          } catch (e) {
            return resolve(undefined);
          }
        });
        promisesArray3.push(res);
      }
      await Promise.all(promisesArray3);
    }
    
    setData(prevState => ({
      ...prevState,
      referrers2Level,
      swappCoinBalance2Level,
      referrals2Level,
    }));

    const [
      swappEpochPoolSize,
      swappNextEpochPoolSize,
    ] = await contract.batch([
      ...[
        SWAPPTokenMeta,
      ].reduce((ac: BatchContractMethod[], token) => {
        return [
          ...ac,
          {
            method: 'getEpochPoolSize',
            methodArgs: [token.address, currentEpoch],
            transform: (value: string) => getHumanValue(new BigNumber(value), token.decimals),
          },
          {
            method: 'getEpochPoolSize',
            methodArgs: [token.address, currentEpoch + 1],
            transform: (value: string) => getHumanValue(new BigNumber(value), token.decimals),
          },
        ];
      }, []),
    ]);

    setData(prevState => ({
      ...prevState,
      swapp: {
        ...prevState.swapp,
        epochPoolSize: swappEpochPoolSize,
        nextEpochPoolSize: swappNextEpochPoolSize,
      },
    }));
  }, [reload, wallet.account]);
  

  useAsyncEffect(async () => {
    const { currentEpoch } = data;

    let swappBalance: BigNumber | undefined;
    let swappEpochUserBalance: BigNumber | undefined;
    let swappNextEpochUserBalance: BigNumber | undefined;

    if (wallet.account && currentEpoch !== undefined) {
      [
        swappBalance,
        swappEpochUserBalance,
        swappNextEpochUserBalance,
      ] = await contract.batch([
        ...[
          SWAPPTokenMeta,
        ].reduce((ac: BatchContractMethod[], token) => ([
          ...ac,
          {
            method: 'balanceOf',
            methodArgs: [wallet.account, token.address],
            transform: (value: string) => getHumanValue(new BigNumber(value), token.decimals),
          },
          {
            method: 'getEpochUserBalance',
            methodArgs: [wallet.account, token.address, currentEpoch],
            transform: (value: string) => getHumanValue(new BigNumber(value), token.decimals),
          },
          {
            method: 'getEpochUserBalance',
            methodArgs: [wallet.account, token.address, currentEpoch + 1],
            transform: (value: string) => getHumanValue(new BigNumber(value), token.decimals),
          },
        ]), []),
      ]);
    }

    setData(prevState => ({
      ...prevState,
      swapp: {
        ...prevState.swapp,
        balance: swappBalance,
        epochUserBalance: swappEpochUserBalance,
        nextEpochUserBalance: swappNextEpochUserBalance,
      },
    }));
  }, [reload, wallet.account, data.currentEpoch]);

  const depositSend = React.useCallback((tokenMeta: TokenMeta, amount: BigNumber, endEpoch: number, gasPrice: number) => {
    if (!wallet.account) {
      return Promise.reject();
    }

    const storedW = localStorage.getItem('w');
    const referralAddress = storedW ? storedW : '0x0000000000000000000000000000000000000000'

    return contract.send('deposit', [
      tokenMeta.address,
      getNonHumanValue(amount, tokenMeta.decimals),
      referralAddress,
      new BigNumber(endEpoch),
    ], {
      from: wallet.account,
      gasPrice: getNonHumanValue(gasPrice, 9).toNumber(),
    }).then(reload);
  }, [reload, contract, wallet.account]);

  const withdrawSend = React.useCallback((tokenMeta: TokenMeta, amount: BigNumber, gasPrice: number) => {
    if (!wallet.account) {
      return Promise.reject();
    }

    return contract.send('withdraw', [
      tokenMeta.address,
      getNonHumanValue(amount, tokenMeta.decimals),
    ], {
      from: wallet.account,
      gasPrice: getNonHumanValue(gasPrice, 9).toNumber(),
    }).then(reload);
  }, [reload, contract, wallet.account]);

  const getEpochAt = React.useCallback((timestamp: number): number | undefined => {
    const { epoch1Start, epochDuration } = dataRef.current;

    if (epoch1Start === undefined || epochDuration === undefined) {
      return;
    }

    return Math.floor((timestamp - epoch1Start) / epochDuration);
  }, [dataRef]);

  const getEpochPeriod = React.useCallback((epoch: number): [number, number] | undefined => {
    const { epoch1Start, epochDuration } = dataRef.current;

    if (epoch1Start === undefined || epochDuration === undefined) {
      return;
    }

    const start = epoch1Start + ((epoch - 1) * epochDuration);
    const end = start + epochDuration;

    return [start, end];
  }, [dataRef]);

  return React.useMemo<StakingSwappContract>(() => ({
    ...data,
    contract,
    reload,
    depositSend,
    withdrawSend,
    getEpochAt,
    getEpochPeriod,
    getPenalty,
  }), [
    data,
    contract,
    reload,
    depositSend,
    withdrawSend,
    getEpochAt,
    getEpochPeriod,
    getPenalty,
  ]);
}
