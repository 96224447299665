import React, { useEffect, useState } from 'react';
import * as Antd from 'antd';

import { useTheme } from 'components/theme-provider';
import { ReactComponent as SwapSvg } from '../../../../resources/svg/icons/swap-icon.svg';
import s from './styles.module.css';

export type SwapModalProps = {
	networkId: number;
};

const SwapModal: React.FunctionComponent<SwapModalProps> = ({ networkId }) => {
	const [modalVisibility, setModalVisibility] = useState(false);
	const [link, setLink] = useState('');
	const { toggleDarkTheme, isDarkTheme } = useTheme();

	useEffect(() => {
		let url = `https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x8CB924583681cbFE487A62140a994A49F833c244&use=v2&theme=${
			isDarkTheme ? 'dark' : 'light'
		}`;
		if (networkId === 56 || networkId === 97) {
			url = `https://pancakeswap.finance/swap?outputCurrency=0x0efe961c733ff46ce34c56a73eba0c6a0e18e0f5`;
		}
		setLink(url);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [networkId]);

	return (
		<>
			{/*<Antd.Button*/}
			{/*	className={s.swapButton}*/}
			{/*	onClick={() => setModalVisibility(true)}>*/}
			{/*	<SwapSvg />*/}
			{/*	Swap*/}
			{/*</Antd.Button>*/}
			<Antd.Modal
				centered
				closable
				onCancel={() => setModalVisibility(false)}
				footer={[]}
				visible={modalVisibility}>
				<iframe
					title='widget'
					src={link}
					height='600px'
					width='100%'
					style={{
						border: 0,
						margin: '40px auto 0',
						display: 'block',
						borderRadius: 10,
						maxWidth: 600,
						minWidth: 300,
					}}
					id='widget'
				/>
			</Antd.Modal>
		</>
	);
};

export default SwapModal;
