import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import * as Antd from 'antd';
import Web3 from 'web3';
import BigNumber from 'bignumber.js';
import { cloneDeep } from 'lodash';
import { useWeb3Contracts } from 'web3/contracts';
import { getHumanValue, formatBigValue } from 'web3/utils';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { SWAPPTokenMeta } from 'web3/contracts/swapp';

import CardWidget from 'components/card';
import { series, options } from './constants';

import s from './styles.module.css';

const StakingChart: React.FunctionComponent = () => {
	const { yfNewSWAPP } = useWeb3Contracts();

	const [chartSeries, setChartSeries]: any[] = useState([...series]);
	const [chartOptions, setChartOptions]: any = useState({});
	const [altChartOptions, setAltChartOptions]: any = useState({});
	const [chartSeriesValues, setChartSeriesValues]: any[] = useState([
		...series,
	]);
	const [loader, setLoader] = useState(true);
	const [switcher, setSwitcher] = useState(true);
	const [persentValue, setPersentValue] = useState(0);
	const [counterValue, setCounterValue] = useState(0);
	const [loadChart, setLoadChart] = useState(false);

	useEffect(() => {
		setPersentValue(+yfNewSWAPP.nextPoolSize!);
	}, [yfNewSWAPP.nextPoolSize]);

	useEffect(() => {
		if (counterValue && persentValue) {
			const newOptions = cloneDeep(options);
			newOptions.dataLabels.formatter = (val) => {
				return ((val * 100) / counterValue).toFixed(2) + '%';
			};
			newOptions.xaxis.categories = chartOptions.xaxis.categories;
			setAltChartOptions(newOptions);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [counterValue, chartOptions]);

	useEffect(() => {
		if (persentValue) {
			const newOptions = cloneDeep(options);
			newOptions.dataLabels.formatter = (val) => {
				return ((val * 100) / persentValue).toFixed(2) + '%';
			};
			setChartOptions(newOptions);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [persentValue]);

	useEffect(() => {
		if (!loadChart) getContractEvents();
	}, [chartOptions, altChartOptions]); // eslint-disable-line react-hooks/exhaustive-deps

	async function getContractEvents() {
		const web3 = new Web3(
			new Web3.providers.HttpProvider(
				'https://mainnet.infura.io/v3/a5ea2e897f1b4a6a817060c48a9f0283'
			)
		);
		// const web3 = new Web3(new Web3.providers.HttpProvider(getHttpsRpcUrl()));

		const contract = new web3.eth.Contract(
			require('web3/abi/swapp_staking.json'),
			// process.env.REACT_APP_CONTRACT_STAKING_SWAPP_ADDR
			'0x60F4D3e409Ad2Bb6BF5edFBCC85691eE1977cf35'
		);

		const fromBlock: number = 12846350;
		// TODO 0 only for Robsten !!!!
		// const fromBlock: number =  0;
		let data: any = {};
		let values: any = {};
		const results = await contract.getPastEvents('Deposit', {
			fromBlock,
			toBlock: 'latest',
		});

		for (let i = 0; i < results.length; i++) {
			let element = results[i];
			let duration = element.returnValues.endEpoch - 1;
			let value = getHumanValue(
				new BigNumber(element.returnValues.amount!),
				SWAPPTokenMeta.decimals
			)!.toFixed(2);

			if (data[duration.toString()]) {
				data[duration.toString()] = data[duration.toString()] + 1;
				values[duration.toString()] += +value!;
			} else {
				data[duration.toString()] = 1;
				values[duration.toString()] = +value!;
			}
		}

		buildChartData(data, false);
		buildChartData(values, true);
	}

	const buildChartData = (data: any, flag: boolean) => {
		if (chartOptions.xaxis) {
			let options: any[] = [];
			const newOptions = { ...chartOptions };

			Object.keys(data).map((item) => options.push(item));
			newOptions.xaxis.categories = options;

			setChartOptions(newOptions);
			setLoadChart(true);
		}

		let series: any[] = [];
		let counter: number = 0;

		const newSeries: any[] = cloneDeep(flag ? chartSeries : chartSeriesValues);

		Object.values(data).map((item: any) => {
			if (!flag) counter += item;
			return series.push(item);
		});
		newSeries[0].data = series;

		if (flag) setChartSeriesValues(newSeries);
		else {
			setChartSeries(newSeries);
			setCounterValue(counter);
		}
		setLoader(false);
	};

	return (
		<div>
			<div className={s.main}>
				<CardWidget className={s.burnRate}>
					{loader || !loadChart ? (
						<div className={s.preloader_custom}>
							<Spin
								indicator={<LoadingOutlined style={{ fontSize: 62 }} spin />}
							/>
						</div>
					) : (
						<>
							<div className={s.wrapper}>
								<div className={s.cardDescription}>Stakes matured on epoch</div>
								<div className={s.cardDescription}>
									<span>Total stakes &nbsp;</span>
									<span className={s.icon}>{SWAPPTokenMeta.icon}</span>
									<span>{formatBigValue(yfNewSWAPP.nextPoolSize, 2)}</span>
								</div>
							</div>
							<div className={s.switcherWrapper}>
								<Antd.Switch
									className={s.alignIconToggle}
									defaultChecked={switcher}
									onChange={() => {
										setSwitcher(!switcher);
									}}
								/>
								{switcher ? <p>Stakes value</p> : <p>Stakes count</p>}
							</div>
							<div className={s.burnRateChartWrapper1}>
								<div className={s.innerWrapper}>
									{loadChart && (
										<Chart
											options={switcher ? chartOptions : altChartOptions}
											series={!switcher ? chartSeries : chartSeriesValues}
											type='bar'
											height={350}
										/>
									)}
								</div>
							</div>
						</>
					)}
				</CardWidget>
			</div>
		</div>
	);
};

export default StakingChart;
