import React from 'react';
import * as Antd from 'antd';
import { ModalProps } from 'antd/lib/modal';

import LedgerDerivationPathModal from 'components/ledger-deriviation-path-modal';

import s from './styles.module.css';
declare let window: any;

export type SourceChaineModalProps = ModalProps & {
	chains: any;
	setVisibility: Function;
};

const SourceChaineModal: React.FunctionComponent<SourceChaineModalProps> = (
	props
) => {
	const { chains, setVisibility, ...modalProps } = props;

	const [ledgerModal, setLedgerModal] = React.useState<boolean>(false);

	const addNewChain = (chain: any) => {
		let params = [];
		let method = 'wallet_addEthereumChain';
		
		if (chain.chainIdHex === '0x1') {
			params = [
				{
					chainId: chain.chainIdHex,
				},
			];
			method = 'wallet_switchEthereumChain';
		} else {
			params = [
				{
					chainId: chain.chainIdHex,
					chainName: chain.label,
					nativeCurrency: {
						name: chain.symbol,
						symbol: chain.symbol,
						decimals: chain.decimals,
					},
					rpcUrls: [chain.rpcUrls],
					blockExplorerUrls: [chain.blockExplorerUrls],
				},
			];
		}
		window.ethereum
			.request({ method, params })
			.then(() => setVisibility(false))
			.catch((error: Error) => console.log('Error', error.message));
	};

	return (
		<>
			<Antd.Modal
				className={s.component}
				centered
				closable
				footer={[]}
				{...modalProps}>
				<div className={s.headerLabel}>Select Chain</div>
				<div className={s.headerNote}>Please select available chain</div>
				<div className={s.connectorList}>
					{chains!.map(
						(chain: any) =>
							!chain.current && (
								<Antd.Button
									key={chain.id}
									type='ghost'
									disabled={
										chain.label === 'Polygon'
									}
									className={s.connectorBtn}
									onClick={() => addNewChain(chain)}>
									{chain.icon}
									<span className={s.connectorName}>{chain.label}</span>
								</Antd.Button>
							)
					)}
				</div>
			</Antd.Modal>
			<LedgerDerivationPathModal
				visible={ledgerModal}
				onCancel={() => setLedgerModal(false)}
			/>
		</>
	);
};

export default SourceChaineModal;
