import React from 'react';
import BigNumber from 'bignumber.js';

import { useReload } from 'hooks/useReload';
import { useAsyncEffect } from 'hooks/useAsyncEffect';
import { TokenMeta } from 'web3/types';
import { getHumanValue } from 'web3/utils';
import { useWallet } from 'wallets/wallet';
import Web3Contract from 'web3/contract';
import { CONTRACT_STAKING_ADDR } from 'web3/contracts/staking';
import { CONTRACT_STAKING_ADDR_SWAPP } from 'web3/contracts/stakingSwapp';

import { swappWallets } from '../../constants';

import { ReactComponent as SWAPPIcon } from 'resources/svg/swappLogo.svg';

const CONTRACT_SWAPP_ADDR = String(process.env.REACT_APP_CONTRACT_SWAPP_ADDR).toLowerCase();

export const SWAPPTokenMeta: TokenMeta = {
  icon: <SWAPPIcon key="swapp" />,
  name: 'SWAPP',
  address: CONTRACT_SWAPP_ADDR,
  decimals: 18,
};

type SWAPPContractData = {
  balance?: BigNumber;
  allowance?: BigNumber;
  allowanceSwapp?: BigNumber;
  circulatingSupply?: BigNumber;
  totalSupply?: BigNumber;
  launchTime?: number;
};

export type SWAPPContract = SWAPPContractData & {
  contract: Web3Contract;
  reload(): void;
  approveSend(value: BigNumber): Promise<any>;
  approveSendSwapp(value: BigNumber, address?: string | undefined): Promise<any>;
};

const InitialData: SWAPPContractData = {
  balance: undefined,
  allowance: undefined,
  allowanceSwapp: undefined,
  circulatingSupply: undefined,
  totalSupply: undefined,
  launchTime: undefined,
};

export type BatchContractMethod = {
  method: string;
  methodArgs?: any[];
  callArgs?: Record<string, any>;
  transform?: (value: any) => any;
};

export function useSWAPPContract(): SWAPPContract {
  const [reload] = useReload();
  const wallet = useWallet();

  const contract = React.useMemo<Web3Contract>(() => {
    return new Web3Contract(
      require('web3/abi/swapp.json'),
      CONTRACT_SWAPP_ADDR,
      'SWAPP',
    );
  }, []);

  const [data, setData] = React.useState<SWAPPContractData>(InitialData);

  useAsyncEffect(async () => {
    let launchTime: number | undefined = undefined;

    [launchTime] = await contract.batch([
      {
        method: 'LAUNCH_TIME',
      },
    ]);

    setData(prevState => ({
      ...prevState,
      launchTime
    }));
  }, [reload]);

  useAsyncEffect(async () => {
    let balance: BigNumber | undefined = undefined;
    let allowance: BigNumber | undefined;
    let allowanceSwapp: BigNumber | undefined;
    let totalSupply: BigNumber | undefined;
    let circulatingSupply = new BigNumber(0);
    const promisesArray: any = [];

    if (wallet.account) {
      [balance, allowance, totalSupply, allowanceSwapp] = await contract.batch([
        {
          method: 'balanceOf',
          methodArgs: [wallet.account],
          transform: (value: string) => getHumanValue(new BigNumber(value), SWAPPTokenMeta.decimals),
        },
        {
          method: 'allowance',
          methodArgs: [wallet.account, CONTRACT_STAKING_ADDR],
          transform: (value: string) => new BigNumber(value),
        },
        {
          method: 'totalSupply',
          transform: (value: string) => getHumanValue(new BigNumber(value), SWAPPTokenMeta.decimals)?.toFixed(3),
        },
        {
          method: 'allowance',
          methodArgs: [wallet.account, CONTRACT_STAKING_ADDR_SWAPP],
          transform: (value: string) => new BigNumber(value),
        },
      ]);
    }

    swappWallets.forEach(token => {
      const res = new Promise(async resolve => {
        try {
          const [result] = await contract.batch([
            {
              method: 'balanceOf',
              methodArgs: [token],
              transform: (value: string) => getHumanValue(new BigNumber(value), SWAPPTokenMeta.decimals),
            },
          ]);
          circulatingSupply = circulatingSupply!.plus(result);
          return resolve(undefined);
        } catch (e) {
          return resolve(undefined);
        }
      });
      promisesArray.push(res);
    })

    await Promise.all(promisesArray);

    setData(prevState => ({
      ...prevState,
      balance,
      allowance,
      allowanceSwapp,
      totalSupply,
      circulatingSupply,
    }));
  }, [reload, contract, wallet.account]);

  const approveSend = React.useCallback((value: BigNumber): Promise<any> => {
    if (!wallet.account) {
      return Promise.reject();
    }

    return contract.send('approve', [
      CONTRACT_STAKING_ADDR,
      value,
    ], {
      from: wallet.account,
    }).then(reload);
  }, [reload, contract, wallet.account]);

  const approveSendSwapp = React.useCallback((value: BigNumber, address: string): Promise<any> => {
    if (!wallet.account) {
      return Promise.reject();
    }

    return contract.send('approve', [
      address ? address : CONTRACT_STAKING_ADDR_SWAPP,
      value,
    ], {
      from: wallet.account,
    }).then(reload);
  }, [reload, contract, wallet.account]);

  return React.useMemo(() => ({
    ...data,
    contract,
    reload,
    approveSend,
    approveSendSwapp,
  }), [
    data,
    contract,
    reload,
    approveSend,
    approveSendSwapp,
  ]);
}
