import { gql } from '@apollo/client';
import client from './apollo';

export const getTransactions = async (
    count: string = "10", 
    condition?: any, 
    paginationValue: number = 0, 
    excludeAddress?: any,
    ): Promise<any[]> => {
      
  let result: any;
  let whereStr: string = "";

  if (condition?.type) {
    let typeName = condition.type === "deposits" ?
      "DEPOSIT" : "WITHDRAW";
    if (whereStr) {
      whereStr += `, type: ${typeName}`
    } else {
      whereStr += `type: ${typeName}`
    }
  }

  if (condition?.token) {
    if (whereStr) {
      whereStr += `, token: "${condition.token}"`
    } else {
      whereStr += `token: "${condition.token}"`
    }
  }

  if (excludeAddress?.length > 0) {
    if (whereStr) {
      whereStr += `, token_not_in: [${excludeAddress.map((address: any) => `"${address}"`)}]`
    } else {
      whereStr += `token_not_in: [${excludeAddress.map((address: any) => `"${address}"`)}]`
    }
  }

  try {
    const query = `
      query stakingActions {
        stakingActions(
          first: ${count}
          skip: ${paginationValue ? paginationValue : 0}
          orderBy: blockTimestamp
          orderDirection: desc
          ${whereStr ? `where: {${whereStr}}` : ""}) {
            id
            type
            user
            token
            amount
            blockNumber
            blockTimestamp
            txHash
          }
        }
      `;

      result = await client.query({
        query: gql(query)
      });
  } catch (err) {
    console.log(err);
    return [];
  }

  if (!result.loading) {
    return result.data.stakingActions;
  }

  return [];
}

export const getTotalTransactions = async (start?: number, end?: number, type?: string, addresses?: string[]): Promise<any[]> => {
  let result: any;
  let whereStr: string = "";

  if (start) {
    whereStr += `blockTimestamp_gt: ${start}`;
  }

  if (end) {
    if (whereStr) {
      whereStr += `, blockTimestamp_lt: ${end}`;
    } else {
      whereStr += `blockTimestamp_lt: ${end}`;
    }
  }

  if (type) {
    let typeName = type == "deposits" ?
      "DEPOSIT" : "WITHDRAW";
    if (whereStr) {
      whereStr += `, type: ${typeName}`;
    } else {
      whereStr += `type: ${typeName}`;
    }
  }

  if (addresses) {
    if (whereStr) {
      whereStr += `, token_in: [${addresses.map((address) => `"${address}"`)}]`;
    } else {
      whereStr += `token_in: [${addresses.map((address) => `"${address}"`)}]`;
    }
  }

  try {
    const query = `
      query stakingActions {
        stakingActions
          ${whereStr ? `(first: 1000, where: {${whereStr}})` : "(first: 1000)"} {
          id
          type
          user
          token
          amount
          blockNumber
          blockTimestamp
          txHash
        }
      }
    `;

      result = await client.query({
        query: gql(query)
      });

  } catch (err) {
    console.log(err);
    return [];
  }

  if (!result.loading) {
    return result.data.stakingActions;
  }

  return [];
}

export const getTotalLength = async (
    type?: string | number, 
    addresses?: string[],
    excludeAddress?: any,
  ): Promise<any[]> => {
    
  let result: any;
  let whereStr: string = "";
  
  if (type) {
    let typeName = '';

    if(type === 'deposits') typeName = 'DEPOSIT'
    else typeName = 'WITHDRAW'

    if (type !== 'all') {
      if (whereStr) whereStr += `, type: ${typeName}`;
      else  whereStr += `type: ${typeName}`;
    }
  }

  if (addresses && addresses.length > 0) {
    if (whereStr) {
      whereStr += `, token_in: [${addresses.map((address) => `"${address}"`)}]`;
    } else {
      whereStr += `token_in: [${addresses.map((address) => `"${address}"`)}]`;
    }
  }

  if (excludeAddress?.length > 0) {
    if (whereStr) {
      whereStr += `, token_not_in: [${excludeAddress.map((address: any) => `"${address}"`)}]`
    } else {
      whereStr += `token_not_in: [${excludeAddress.map((address: any) => `"${address}"`)}]`
    }
  }

  try {
    const query = `
      query stakingActions {
        stakingActions
        ${whereStr ? `(first: 1000, where: {${whereStr}})` : "(first: 1000)"} {
          id
        }
      }
    `;

    result = await client.query({
      query: gql(query)
    });
  } catch (err) {
    console.log(err);
    return [];
  }

  if (!result.loading) {
    return result.data.stakingActions;
  }

  return [];
}
