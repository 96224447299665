import React from 'react';
import BigNumber from 'bignumber.js';

import { useReload } from 'hooks/useReload';
import { useAsyncEffect } from 'hooks/useAsyncEffect';
import { getHumanValue } from 'web3/utils';
import { useWallet } from 'wallets/wallet';
import Web3Contract from 'web3/contract';

export const CONTRACT_LIQUIDITY_TRANSFORMER = String(process.env.REACT_APP_CONTRACT_LIQUIDITY_TRANSFORMER);

type TransformerContractData = {
  investmentsOnAllDays?: number[];
  investorsOnAllDays?: number[];
  userInvestmentAmountAllDays?: number[];
  currentSwappDay?: number;
  investorTotalBalance?: number;
  totalInvestors?: number;
  totalInvestmentAmount?: number;
  totalInvestorCount?: number;
  referralAccountCount?: number;
  supplyOnAllDays?: number[];
};

export type TransformerContract = TransformerContractData & {
  contract: Web3Contract;
  reload(): void;
  reserveSwapp: (_investmentDays: number[], address: string, amount: number) => void;
  reserveSwappWithToken: (_tokenAddress: string, _tokenAmount: number, _investmentDays: number[], address: string) => void;
  investorBalances: (day: number) => Promise<any[]>;
  getMyTokens: () => void;
  dailyTotalSupply: (day: number) => Promise<any>;
};

const InitialData: TransformerContractData = {
  investmentsOnAllDays: undefined,
  investorsOnAllDays: undefined,
  userInvestmentAmountAllDays: undefined,
  currentSwappDay: undefined,
  investorTotalBalance: undefined,
  totalInvestors: undefined,
  totalInvestmentAmount: undefined,
  totalInvestorCount: undefined,
};

export function useTransformerContract(): TransformerContract {
  const [reload] = useReload();
  const wallet = useWallet();

  const contract = React.useMemo<Web3Contract>(() => {
    return new Web3Contract(
      require('web3/abi/liquidity_transformer.json'),
      CONTRACT_LIQUIDITY_TRANSFORMER,
      'LIQUIDITY_TRANSFORMER',
    );
  }, []);

  const [data, setData] = React.useState<TransformerContractData>(InitialData);

  useAsyncEffect(async () => {
    // let [investmentsOnAllDays, investorsOnAllDays, currentSwappDay, totalInvestorCount, referralAccountCount, supplyOnAllDays] = await contract.batch([
    let currentSwappDay = 0;
    let [investmentsOnAllDays, investorsOnAllDays, totalInvestorCount, referralAccountCount, supplyOnAllDays] = await contract.batch([
      {
        method: 'investmentsOnAllDays',
        transform: (values: BigNumber[]) => values.map((value, key) => getHumanValue(new BigNumber(value), 18)?.decimalPlaces(2)),
      },
      {
        method: 'investorsOnAllDays',
      },
      // {
      //   method: '_currentSwappDay'
      // },
      {
        method: 'totalInvestorCount'
      },
      {
        method: 'referralAccountCount'
      },
      {
        method: 'supplyOnAllDays',
        transform: (values: BigNumber[]) => values.map((value, key) => getHumanValue(new BigNumber(value), 18)?.decimalPlaces(2)),
      },
    ]);

    let totalInvestmentAmount = 0;
    if (investmentsOnAllDays) {
      investmentsOnAllDays.forEach((item: any) => {
        totalInvestmentAmount += parseFloat(item);
      })
    }

    let totalInvestors = 0;
    if (investmentsOnAllDays) {
      investorsOnAllDays.forEach((item: any) => {
        totalInvestors += parseInt(item);
      })
    }

    setData(prevState => ({
      ...prevState,
      investmentsOnAllDays,
      investorsOnAllDays,
      currentSwappDay,
      totalInvestmentAmount,
      totalInvestors,
      totalInvestorCount,
      referralAccountCount,
      supplyOnAllDays,
    }));
  }, [reload]);

  useAsyncEffect(async () => {
    let [investorTotalBalance, userInvestmentAmountAllDays] = await contract.batch([
      {
        method: 'investorTotalBalance',
        methodArgs: [wallet.account],
        transform: (value: string) => getHumanValue(new BigNumber(value), 18)?.toString(),
      },
      {
        method: 'userInvestmentAmountAllDays',
        methodArgs: [wallet.account],
        transform: (values: BigNumber[]) => values.map((value, key) => getHumanValue(new BigNumber(value), 18)?.toString()),
      },
    ]);

    setData(prevState => ({
      ...prevState,
      investorTotalBalance,
      userInvestmentAmountAllDays,
    }));
  }, [reload, wallet]);

  const reserveSwapp = React.useCallback((_investmentDays: number[], address: string,  amount: number) => {
    if (!wallet.account) {
      return Promise.reject();
    }

    return contract.send('reserveSwapp', [
      _investmentDays,
      address,
    ], {
      from: wallet.account,
      value: new BigNumber(amount).times(new BigNumber(10).pow(18))
    }).then(reload);
  }, [reload, contract, wallet.account]);

  const reserveSwappWithToken = React.useCallback(
    (_tokenAddress: string, _tokenAmount: number, _investmentDays: number[], address: string) => {
      if (!wallet.account) {
        return Promise.reject();
      }

      return contract.send('reserveSwappWithToken', [
        _tokenAddress,
        new BigNumber(_tokenAmount).times(new BigNumber(10).pow(18)),
        _investmentDays,
        address,
      ], {
        from: wallet.account,
      }).then(reload);
    },
    [reload, contract, wallet.account]
  );

  const investorBalances = React.useCallback(
    (day: number) => {
      if (!wallet.account) {
        return Promise.reject();
      }

      return contract.batch([
        {
          method: 'investorBalances',
          methodArgs: [wallet.account, day],
          transform: (value: string) => getHumanValue(new BigNumber(value), 18)?.toString(),
        },
      ]);
    },
    [reload, contract, wallet.account]
  );

  const getMyTokens = React.useCallback(
    () => {
      if (!wallet.account) {
        return Promise.reject();
      }

      return contract.send('getMyTokens', [], {
        from: wallet.account,
      }).then(reload);
    },
    [reload, contract, wallet.account]
  );

  const dailyTotalSupply = React.useCallback(
    async (day: number) => {
      const [result] = await contract.batch([
        {
          method: 'dailyTotalSupply',
          methodArgs: [day],
          transform: (value: string) => getHumanValue(new BigNumber(value), 18)?.toString(),
        },
      ]);

      return result;
    },
    [reload, contract]
  );

  return React.useMemo<TransformerContract>(() => ({
    ...data,
    contract,
    reload,
    reserveSwapp,
    reserveSwappWithToken,
    investorBalances,
    getMyTokens,
    dailyTotalSupply,
  }), [
    data,
    contract,
    reload,
    reserveSwapp,
    reserveSwappWithToken,
    investorBalances,
    getMyTokens,
    dailyTotalSupply,
  ]);
}
