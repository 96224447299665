import React from 'react';
import * as Antd from 'antd';
import { ModalProps } from 'antd/lib/modal';

import s from './styles.module.css';

export type TransitionErrorModalProps = ModalProps & {
  message: React.ReactNode
};

const TransitionErrorModal: React.FunctionComponent<TransitionErrorModalProps> = props => {
  const { message, ...modalProps } = props;

  return (
    <Antd.Modal
      className={s.component}
      centered
      closable={true}
      footer={[]}
      {...modalProps}
    >
      <div className={s.headerLabel}>Attention</div>
      <div className={s.text}>
        {message}
      </div>
      <div className={s.footer}>
        <Antd.Button
          type="ghost"
          className={s.backBtn}
          onClick={props.onCancel}
        >Close</Antd.Button>
      </div>
    </Antd.Modal>
  );
};

export default TransitionErrorModal;
