import React, { FunctionComponent } from 'react';
import { useHistory } from "react-router";

import PersonalInfo from './components/personal-info';
import ReferralsSumary from './components/referrals-summary';
import ReservationSumary from './components/reservation-sumary';
import SwappReferrals from './components/swapp-referrals';
import ComingSoonModal from 'components/coming-soon-modal';

import s from './styles.module.css'

interface PersonalDashboardProps {

}

const PersonalDashboard: FunctionComponent<PersonalDashboardProps> = () => {
  const history = useHistory();

  const goBack = () => {
    history.replace("/pools");
  }

  return (
    <div className={s.personalInfoView}>
      <PersonalInfo />
      {/* <ReservationSumary /> */}
      <ReferralsSumary />
      <SwappReferrals />
      <ComingSoonModal
        visible={false}
        message={<span>Personal Dashboard is coming soon</span>}
        onCancel={() => {goBack()}}
      />
    </div>
  )
}

export default PersonalDashboard
