import React from 'react';
import { useHistory } from "react-router";
import * as Antd from "antd";
import ComingSoonModal from "components/coming-soon-modal";

import s from './styles.module.css';

const VotingView: React.FunctionComponent = () => {
  const history = useHistory();

  function goBack() {
    history.replace("/pools");
  }

  return (
    <div className={s.container}>
      <div className={s.titleHeader}>
        <Antd.Button
          type="link"
          className={s.headerLabel}
          onClick={goBack}
        >
          <div>Voting</div>
          <div className={s.chooseReservation}>Choose reservation day by 31 of March 2021..</div>
        </Antd.Button>
      </div>
      <ComingSoonModal
        visible={true}
        message="Voting is coming soon."
        onCancel={() => {goBack()}} />
      {/* <div className={s.main}>
        <div className={s.header}>
          <div className={s.proposalTitle}>
            COMP Speed Recalibration
          </div>
          <div className={s.proposalDate}>
            <span className={s.passed}>Passed</span> 035 . Executed January 13th, 2021
          </div>
        </div>
        <div className={s.body}>

        </div>
        <div className={s.board}>
          <div className={s.row1}>
            <div className={s.for}>
              <div className={s.forProgressRow1}>
                <span>For</span>
                <span>631,909</span>
              </div>
              <div className={s.address}>
                <span>37 Addresses</span>
                <span>Votes</span>
              </div>
              <div className={s.info}>
                <span>Gauntlet</span>
                <span>126,090.3534</span>
              </div>
              <div className={s.info}>
                <span>Robert Leshner</span>
                <span>105,125.7157</span>
              </div>
              <div className={s.info}>
                <span>Blck</span>
                <span>100,105.8223</span>
              </div>
            </div>
            <div className={s.for}>
              <div className={s.forProgress}>
                <div className={s.forProgressRow1}>
                  <span>Against</span>
                  <span>631,909</span>
                </div>
              </div>
              <div className={s.address}>
                <span>37 Addresses</span>
                <span>Votes</span>
              </div>
              <div className={s.info}>
                <span>Gauntlet</span>
                <span>126,090.3534</span>
              </div>
              <div className={s.info}>
                <span>Robert Leshner</span>
                <span>105,125.7157</span>
              </div>
              <div className={s.info}>
                <span>Blck</span>
                <span>100,105.8223</span>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default VotingView;
