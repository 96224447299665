import React from 'react';
import * as ReCharts from 'recharts';
import { IWidgetLineChart } from 'views/pools/components/pool-stats';

interface IProps {
    data: IWidgetLineChart[];
    color: string;
}

export const LineChartWidget: React.FC<IProps> = ({data,color}) =>{
    return (
        <ReCharts.ResponsiveContainer width="100%" height="100%">
            <ReCharts.LineChart
                data={data}
            >
                <defs>
                    <filter id={`filter${color}`}>
                        <feDropShadow dx="0" dy="1" stdDeviation="3" floodColor={color} />
                        <feDropShadow dx="0" dy="-1" stdDeviation="3" floodColor={color} />
                    </filter>
                </defs>
                <ReCharts.CartesianGrid
                    horizontal={false}
                    stroke="#666" />
                <ReCharts.Line
                    type="monotone"
                    dataKey="value"
                    dot={false}
                    strokeWidth={2}
                    filter={`url(#filter${color})`}
                    stroke={color}
                />
            </ReCharts.LineChart>
        </ReCharts.ResponsiveContainer>
    )
}
