import React, { useState, useContext } from 'react';
import * as Antd from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import BigNumber from 'bignumber.js';
import { formatDistance } from 'date-fns';
import cx from 'classnames';
import moment from 'moment';
import { getTotalLength } from 'subgraphs/actions';
import { useAsyncEffect } from 'hooks/useAsyncEffect';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import Dropdown, { DropdownOption } from 'components/dropdown';
import ExternalLink from 'components/externalLink';
import PaginationElement from 'components/pagination';
import PoolTxListProvider, {
  PoolTxListItem,
  usePoolTxList
} from 'views/pools/components/pool-tx-list-provider';
import {
  formatBigValue,
  getHumanValue,
  formatUSDValue,
  getEtherscanTxUrl,
  getEtherscanAddressUrl,
  getTokenMeta,
  shortenAddr
} from 'web3/utils';
import { useWallet } from 'wallets/wallet';
import { useWeb3Contracts } from 'web3/contracts';
import { USDCTokenMeta } from 'web3/contracts/usdc';
import { USDTTokenMeta } from 'web3/contracts/usdt';
import { DAITokenMeta } from 'web3/contracts/dai';
import { UNISWAPTokenMeta } from 'web3/contracts/uniswap';
import { SWAPPTokenMeta } from 'web3/contracts/swapp';
import { PoolTxListContext } from './../pool-tx-list-provider';

import { ReactComponent as EmptyBoxSvg } from 'resources/svg/empty-box.svg';

import s from './styles.module.css';

const DEPOSITS_KEY = 'deposits';
const WITHDRAWALS_KEY = 'withdrawals';

const TypeFilters: DropdownOption[] = [
  { value: 'all', label: 'All transactions' },
  { value: DEPOSITS_KEY, label: 'Staked' },
  { value: WITHDRAWALS_KEY, label: 'Unstaked' }
];

const Columns: ColumnsType<any> = [
  {
    title: 'Date',
    dataIndex: 'blockTimestamp',
    render: (value: number) => (
      <>
        <p className={s.firstLine}>{moment(value).format('ll')}</p>
      </>
    )
  },
  {
    title: 'Time',
    dataIndex: 'blockTimestamp',
    render: (value: number) => (
        <>
          <p className={s.firstLine}>
            {formatDistance(new Date(value), new Date(), {
              addSuffix: true
            })}
          </p>
        </>
    )
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    render: (value: BigNumber, record: PoolTxListItem) => {
      const tokenMeta = getTokenMeta(record.token);

      return (
        <Antd.Tooltip
          title={
            <span>
              <strong>
                {record.type === 'WITHDRAW' ? '-' : ''}
                {formatBigValue(
                  getHumanValue(record.amount, tokenMeta?.decimals)
                )}
              </strong>
              &nbsp;
              {tokenMeta?.name}
            </span>
          }
        >
          <div className={s.amountWrapper}>
            <span className={s.icon}>{tokenMeta?.icon}</span>
            <span className={record.type === 'WITHDRAW' ? s.redLine : ''}>
              {record.type === 'WITHDRAW' ? '-' : ''}
              {formatBigValue(
                getHumanValue(record.amount, tokenMeta?.decimals),
                tokenMeta?.name === 'WBTC_SWAPP_UNI_LP' ? 6 : 2,
                '-',
                tokenMeta?.name === 'WBTC_SWAPP_UNI_LP' ? 6 : 2
              )}
            </span>
          </div>
        </Antd.Tooltip>
      );
    }
  },
  {
    title: 'TRX',
    dataIndex: 'txHash',
    render: (value: string) => (
      <ExternalLink href={getEtherscanTxUrl(value)}>
        {shortenAddr(value)}
      </ExternalLink>
    )
  },
  {
    title: 'Type',
    dataIndex: 'type',
    render: (value: string) => (
      <span className={cx(s.type,{[s.red]:value === 'DEPOSIT'})}>{value === 'DEPOSIT' ? 'Staked' : 'Unstaked'}</span>
    )
  },
  {
    title: 'From',
    dataIndex: 'user',
    render: (value: string) => (
      <ExternalLink href={getEtherscanAddressUrl(value)}>
        {shortenAddr(value)}
      </ExternalLink>
    )
  }
];

export type PoolTransactionTableProps = {
  className?: string;
  label: string;
  ownTransactions?: boolean;
  deposits?: boolean;
  withdrawals?: boolean;
  stableToken?: boolean;
  unilpToken?: boolean;
  swappToken?: boolean;
  paginationValues?: any;
  setExclude?: any;
};

const PoolTransactionTableInner: React.FunctionComponent<PoolTransactionTableProps> = (
  props
) => {
  const { ownTransactions } = props;

  const { setPagination, setExclude } = useContext(PoolTxListContext);

  const [paginationArray, setPaginationArray]: any[] = useState([]);
  const [paginationValues, setPaginationValues] = useState({
    minValue: 0,
    maxValue: 10
  });

  const web3c = useWeb3Contracts();
  const wallet = useWallet();
  const poolTxList = usePoolTxList();

  const [, forceRender] = React.useState<{}>({});

  const tokenFilterOptions = React.useMemo<DropdownOption[]>(() => {
    const options: DropdownOption[] = [];

    if (props.stableToken) {
      options.push(
        { value: USDCTokenMeta.address, label: USDCTokenMeta.name },
        { value: DAITokenMeta.address, label: DAITokenMeta.name },
        { value: USDTTokenMeta.address, label: USDTTokenMeta.name }
      );
    }

    if (props.unilpToken) {
      options.push({
        value: UNISWAPTokenMeta.address,
        label: UNISWAPTokenMeta.name
      });
    }

    if (props.swappToken) {
      options.push({
        value: SWAPPTokenMeta.address,
        label: SWAPPTokenMeta.name
      });
    }

    if (options.length !== 1) {
      options.unshift({ value: 'all', label: 'All tokens' });
    }

    return options;
  }, [props.stableToken, props.unilpToken, props.swappToken]);

  let tokenDefaultOptions: string = String(tokenFilterOptions[0].value);
  let typeDefaultOption: string = 'all';

  if (props.deposits) {
    typeDefaultOption = DEPOSITS_KEY;
  } else if (props.withdrawals) {
    typeDefaultOption = WITHDRAWALS_KEY;
  }

  const tokenFilterRef = React.useRef<string | number>(tokenDefaultOptions);
  const typeFilterRef = React.useRef<string | number>(typeDefaultOption);

  React.useEffect(() => {
    poolTxList
      .load({
        user: ownTransactions ? wallet.account?.toLowerCase() : undefined,
        token:
          tokenFilterRef.current !== 'all'
            ? String(tokenFilterRef.current)
            : undefined,
        type:
          typeFilterRef.current !== 'all'
            ? String(typeFilterRef.current)
            : undefined
      })
      .catch((x) => x);

    // Exclude token addreses from graphQl query
    if (props.stableToken && !props.unilpToken) {
      setExclude([process.env.REACT_APP_CONTRACT_UNISWAP_V2_ADDR]);
    }
  }, [
    // eslint-disable-line react-hooks/exhaustive-deps
    ownTransactions,
    tokenFilterRef.current,
    typeFilterRef.current
  ]);

  useAsyncEffect(async () => {
    const result = await getTotalLength(
      // type
      typeFilterRef.current,
      // include token address
      tokenFilterRef.current === 'all' ? [] : [`${tokenFilterRef.current}`],
      // exclude token address
      props.stableToken && !props.unilpToken
        ? [process.env.REACT_APP_CONTRACT_UNISWAP_V2_ADDR]
        : []
    );
    setPaginationArray(result);
  }, [typeFilterRef.current, tokenFilterRef.current]);

  // React.useEffect(() => {
  //   poolTxList.startPooling();

  //   return () => {
  //     poolTxList.stopPooling();
  //   };
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const data = React.useMemo<
    (PoolTxListItem & { usdAmount: BigNumber | undefined })[]
  >(() => {
    return poolTxList.transactions.map((tx) => {
      const price = web3c.getTokenUsdPrice(tx.token);

      return {
        ...tx,
        usdAmount: price ? tx.amount.multipliedBy(price) : undefined,
        tokenMeta: getTokenMeta(tx.token)
      };
    });
  }, [web3c, poolTxList.transactions]);

  const handlePaginationValues = (minValue: number, maxValue: number) => {
    setPaginationValues({ minValue, maxValue });
    setPagination(minValue);
  };

  return (
    <div className={cx(s.component, props.className)}>
      <div className={s.header}>
        {/*<div className={s.headerLabel}>{props.label}</div>*/}
        <div className={s.filters}>
          {tokenFilterOptions.length > 1 && (
              <div className={s.filtersItem}>
                <span className={s.dropDownLabel}>Tokens</span>
                <Dropdown
                    button
                    items={tokenFilterOptions}
                    selected={tokenFilterRef.current}
                    disabled={poolTxList.loading}
                    onSelect={(value: string | number) => {
                      tokenFilterRef.current = String(value);
                      forceRender({});
                    }}
                    className={s.filterDropDown}
                />
              </div>
          )}
          <div className={s.filtersItem}>
            <span className={s.dropDownLabel}>Show</span>
            <Dropdown
                button
                items={TypeFilters}
                selected={typeFilterRef.current}
                disabled={poolTxList.loading}
                onSelect={(value: string | number) => {
                  typeFilterRef.current = String(value);
                  forceRender({});
                }}
                className={s.filterDropDown}
            />
          </div>
        </div>
      </div>
      {!poolTxList.loaded && poolTxList.loading ? (
        <div className={s.preloader_custom}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 62 }} spin />} />
        </div>
      ) : (<>
            <Antd.Table
              className={s.table}
              columns={Columns}
              rowKey="txHash"
              dataSource={data}
              scroll={{ x: true }}
              locale={{
                emptyText: (
                  <div className={s.emptyBlock}>
                    <div className={s.emptyLabel}>
                      There are no transactions to show
                    </div>
                    <div className={s.startEarning}>
                      Start earning iterest by creating your first stake
                    </div>
                    {/* <div className={s.createStakeBtn}>Create stake</div> */}
                  </div>
                )
              }}
              showSorterTooltip={false}
              pagination={false}
            />
            <PaginationElement
            arrayLength={paginationArray.length}
            handlePaginationValues={handlePaginationValues}
            />
          </>
      )}

    </div>
  );
};

const PoolTransactionTable: React.FunctionComponent<PoolTransactionTableProps> = (
  props
) => {
  return (
    <PoolTxListProvider>
      <PoolTransactionTableInner {...props} />
    </PoolTxListProvider>
  );
};

export default PoolTransactionTable;
