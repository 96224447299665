import React, { FunctionComponent } from 'react';

import s from './styles.module.css';
import * as Antd from 'antd';
import CardWidget from 'components/card';

import { ReactComponent as ErrorSvg } from '../../../../resources/svg/icons/alert-triangle.svg';

type ReferralContributionsProps = {}

const ReferralContributions: FunctionComponent<ReferralContributionsProps> = () => {

  return (
    <>
      <CardWidget>
        <Antd.Row>
          <Antd.Col 
            span={24}
            className={s.container}
          >
            <ErrorSvg />
            <h2 className={s.titleReferral}>No Referrals</h2>
            <h3 className={s.information}>Your wallet address <strong>0x..34</strong> has <strong>0</strong> referrals.</h3>
            <h3 className={s.information}>Start promoting SWAPP to earn rewards.</h3>
          </Antd.Col>
        </Antd.Row>
      </CardWidget>
    </>
  )    


}

export default ReferralContributions;