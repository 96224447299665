import { priceArray, rewardArray, monthlyRewardArray } from '../constants';

export const numberWithCommas = (value) => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const getYearDurationBonus = (yearCounter, amount, stakingSwapp, yfNewSWAPP, uniswapPrice) => {
  if (amount) {
    const prices = priceArray;
    if (uniswapPrice) prices[0] = +uniswapPrice.toFixed(3);

    const epochId = yearCounter * 12;
    const currentEpoch = stakingSwapp?.currentEpoch;
    const multiplier = (epochId - currentEpoch) / epochId;
    const epochSize = currentEpoch === 0 ? +yfNewSWAPP.nextPoolSize : +yfNewSWAPP.poolSize;
    const partInPool = +amount / (epochSize + +amount)
    const durationReward = rewardArray[yearCounter - 1] * partInPool * prices[yearCounter - 1] * multiplier;
    const durationAPY = durationReward / (+amount * prices[0]) * 100;
    
    return durationAPY ? durationAPY : 0;
  } return 0;
}

export const getMonthlyReward = (yearCounter, amount, stakingSwapp, yfNewSWAPP, uniswapPrice) => {
  if (amount) {
    const prices = priceArray;
    if (uniswapPrice) prices[0] = +uniswapPrice.toFixed(3);

    const currentEpoch = stakingSwapp?.currentEpoch
    const epochSize = currentEpoch === 0 ? +yfNewSWAPP.nextPoolSize : +yfNewSWAPP.poolSize;
    const partInPool = +amount / (epochSize + +amount)
    const monthlyReward = monthlyRewardArray[yearCounter - 1] * partInPool * prices[yearCounter - 1];
    const monthlyAPY = monthlyReward / (+amount * prices[0]) * 100;

    return monthlyAPY ? monthlyAPY : 0;
  } return 0;
}

export const checkWalletNetwork = (wallet) => {
  return wallet.account && (wallet.networkId === 1 || wallet.networkId === 3)
}