import React, {useState, useContext, useEffect} from 'react';
import * as Antd from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import BigNumber from 'bignumber.js';
import { formatDistance } from 'date-fns';
import cx from 'classnames';
import moment from 'moment';
import { getTotalLength } from 'subgraphs/actions';
import { useAsyncEffect } from 'hooks/useAsyncEffect';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import Dropdown, { DropdownOption } from 'components/dropdown';
import ExternalLink from 'components/externalLink';
import PaginationElement from 'components/pagination';
import PoolTxListProvider, {
  PoolTxListItem,
  usePoolTxList
} from 'views/pools/components/pool-tx-list-provider';
import {
  formatBigValue,
  getHumanValue,
  formatUSDValue,
  getEtherscanTxUrl,
  getEtherscanAddressUrl,
  getTokenMeta,
  shortenAddr
} from 'web3/utils';
import { useWallet } from 'wallets/wallet';
import { useWeb3Contracts } from 'web3/contracts';
import { USDCTokenMeta } from 'web3/contracts/usdc';
import { USDTTokenMeta } from 'web3/contracts/usdt';
import { DAITokenMeta } from 'web3/contracts/dai';
import { UNISWAPTokenMeta } from 'web3/contracts/uniswap';
import { SWAPPTokenMeta } from 'web3/contracts/swapp';

import s from './styles.module.css';
import {numberWithCommas} from "../../../../functions/helpers";
import Web3 from "web3";

const DEPOSITS_KEY = 'deposits';
const WITHDRAWALS_KEY = 'withdrawals';

// const TypeFilters: DropdownOption[] = [
//   { value: 'all', label: 'All transactions' },
//   { value: DEPOSITS_KEY, label: 'Staked' },
//   { value: WITHDRAWALS_KEY, label: 'Unstaked' }
// ];

const Columns: ColumnsType<any> = [
  {
    title: 'Date',
    dataIndex: 'date',
    render: (value: number) => (
      <>
        <p className={s.firstLine}>{moment.unix(value).format('ll')}</p>
      </>
    )
  },
  {
    title: 'Time',
    dataIndex: 'date',
    render: (value: number) => (
        <>
          <p className={s.firstLine}>
            {formatDistance(
                new Date(value * 1000),
                new Date(),
                {
                  addSuffix: true,
                })
            }
          </p>
        </>
    )
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    render: (value: string) => {
      return (
        <div className={s.amountWrapper}>
          <span className={s.icon}>{SWAPPTokenMeta.icon}</span>
          {numberWithCommas(
              (+Web3.utils.fromWei(
                  value,
                  'ether'
              )).toFixed(2)
          )}
        </div>
      );
    }
  },
  {
    title: 'TRX',
    dataIndex: 'transactionHash',
    render: (value: string) => (
      <ExternalLink href={getEtherscanTxUrl(value)}>
        {shortenAddr(value)}
      </ExternalLink>
    )
  },
  {
    title: 'From',
    dataIndex: 'from',
    render: (value: string) => (
      <ExternalLink href={getEtherscanAddressUrl(value)}>
        {shortenAddr(value)}
      </ExternalLink>
    )
  }
];

const BurnTransactionTableInner: React.FunctionComponent = (
  props
) => {

  const [result, setResult]: any[] = useState([]);
  const [paginationValues, setPaginationValues] = useState({
    minValue: 0,
    maxValue: 10
  });

  useEffect(() => {
    const url = process.env.REACT_APP_BASE_API_URI;
    fetch(`${url}/burn?page=${paginationValues.maxValue / 10}`)
        .then((result) => result.json())
        .then((result) => {
          setResult(result);

        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationValues.maxValue]);

  // const web3c = useWeb3Contracts();
  // const wallet = useWallet();
  // const poolTxList = usePoolTxList();
  //
  // const [, forceRender] = React.useState<{}>({});
  //
  // const tokenFilterOptions = React.useMemo<DropdownOption[]>(() => {
  //   const options: DropdownOption[] = [];
  //
  //   if (props.stableToken) {
  //     options.push(
  //       { value: USDCTokenMeta.address, label: USDCTokenMeta.name },
  //       { value: DAITokenMeta.address, label: DAITokenMeta.name },
  //       { value: USDTTokenMeta.address, label: USDTTokenMeta.name }
  //     );
  //   }
  //
  //   if (props.unilpToken) {
  //     options.push({
  //       value: UNISWAPTokenMeta.address,
  //       label: UNISWAPTokenMeta.name
  //     });
  //   }
  //
  //   if (props.swappToken) {
  //     options.push({
  //       value: SWAPPTokenMeta.address,
  //       label: SWAPPTokenMeta.name
  //     });
  //   }
  //
  //   if (options.length !== 1) {
  //     options.unshift({ value: 'all', label: 'All tokens' });
  //   }
  //
  //   return options;
  // }, [props.stableToken, props.unilpToken, props.swappToken]);
  //
  // let tokenDefaultOptions: string = String(tokenFilterOptions[0].value);
  // let typeDefaultOption: string = 'all';
  //
  // if (props.deposits) {
  //   typeDefaultOption = DEPOSITS_KEY;
  // } else if (props.withdrawals) {
  //   typeDefaultOption = WITHDRAWALS_KEY;
  // }
  //
  // const tokenFilterRef = React.useRef<string | number>(tokenDefaultOptions);
  // const typeFilterRef = React.useRef<string | number>(typeDefaultOption);
  //
  // React.useEffect(() => {
  //   poolTxList
  //     .load({
  //       user: ownTransactions ? wallet.account?.toLowerCase() : undefined,
  //       token:
  //         tokenFilterRef.current !== 'all'
  //           ? String(tokenFilterRef.current)
  //           : undefined,
  //       type:
  //         typeFilterRef.current !== 'all'
  //           ? String(typeFilterRef.current)
  //           : undefined
  //     })
  //     .catch((x) => x);
  //
  //   // Exclude token addreses from graphQl query
  //   if (props.stableToken && !props.unilpToken) {
  //     setExclude([process.env.REACT_APP_CONTRACT_UNISWAP_V2_ADDR]);
  //   }
  // }, [
  //   // eslint-disable-line react-hooks/exhaustive-deps
  //   ownTransactions,
  //   tokenFilterRef.current,
  //   typeFilterRef.current
  // ]);
  //
  // useAsyncEffect(async () => {
  //   const result = data.length;
  //   setPaginationArray(result);
  // }, [data]);
  //
  // // React.useEffect(() => {
  // //   poolTxList.startPooling();
  //
  // //   return () => {
  // //     poolTxList.stopPooling();
  // //   };
  // // }, []); // eslint-disable-line react-hooks/exhaustive-deps
  //
  // const data = React.useMemo<
  //   (PoolTxListItem & { usdAmount: BigNumber | undefined })[]
  // >(() => {
  //   return poolTxList.transactions.map((tx) => {
  //     const price = web3c.getTokenUsdPrice(tx.token);
  //
  //     return {
  //       ...tx,
  //       usdAmount: price ? tx.amount.multipliedBy(price) : undefined,
  //       tokenMeta: getTokenMeta(tx.token)
  //     };
  //   });
  // }, [web3c, poolTxList.transactions]);

  const handlePaginationValues = (minValue: number, maxValue: number) => {
    setPaginationValues({ minValue, maxValue });
    // setPagination(minValue);
  };

  return (
    <div className={cx(s.component)}>
      {!(result.rows?.length>0) ? (
        <div className={s.preloader_custom}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 62 }} spin />} />
        </div>
      ) : (<>
            <Antd.Table
              className={s.table}
              columns={Columns}
              rowKey="id"
              dataSource={result.rows}
              scroll={{ x: true }}
              locale={{
                emptyText: (
                  <div className={s.emptyBlock}>
                    <div className={s.emptyLabel}>
                      There are no transactions to show
                    </div>
                    <div className={s.startEarning}>
                      Start earning iterest by creating your first stake
                    </div>
                    {/* <div className={s.createStakeBtn}>Create stake</div> */}
                  </div>
                )
              }}
              showSorterTooltip={false}
              pagination={false}
            />
            <PaginationElement
            arrayLength={result.count}
            handlePaginationValues={handlePaginationValues}
            />
          </>
      )}

    </div>
  );
};

// const PoolTransactionTable: React.FunctionComponent<PoolTransactionTableProps> = (
//   props
// ) => {
//   return (
//     <PoolTxListProvider>
//       <PoolTransactionTableInner {...props} />
//     </PoolTxListProvider>
//   );
// };

export default BurnTransactionTableInner;
