import React, { useState, useEffect } from "react";
import moment from "moment";
import * as Antd from 'antd';
import cx from 'classnames';

import s from "./styles.module.css";

export type AdditionalPanelProps = {
  stakingSwapp: any,
  progress: number,
  yfNewSWAPP: any,
  name: string
};

const AdditionalPanel: React.FunctionComponent<AdditionalPanelProps> = (props) => {
  const { stakingSwapp, progress, yfNewSWAPP, name } = props;
  const [persents, setPersents] = useState(0);

  useEffect(() => {
    const matturedTime = stakingSwapp.epoch1Start! + (stakingSwapp.epochDuration! * (+stakingSwapp?.stakes?.endEpoch - 1));
    const persentLeft = (moment(matturedTime).diff(moment(), 'milliseconds')) * 100 / (stakingSwapp.epochDuration! * stakingSwapp?.stakes?.duration!);

    setPersents(100 - persentLeft);
  }, [stakingSwapp])

  const getDaysLeft = () => {
    let matturedTime: any = 0;
    if (stakingSwapp.stakes) {
      matturedTime = stakingSwapp.epoch1Start! + (stakingSwapp.epochDuration! * (+stakingSwapp?.stakes?.endEpoch - 1));
  
      const resultInDays = moment(matturedTime).diff(moment(), 'days');
      if (resultInDays > 0) return `${resultInDays} days left`;
      const resultInHours = moment(matturedTime).diff(moment(), 'hours');
      if (resultInHours > 0) return `${resultInHours} hours left`;
      const resultInMinutes = moment(matturedTime).diff(moment(), 'minutes');
      if (resultInMinutes > 0) return `${resultInMinutes} minutes left`;
    } return null;
  }

  return (
    name === 'SWAPP' && stakingSwapp?.stakes?.active ? 
      <Antd.Row className={cx(s.balanceRow, s.additional)}>
        <Antd.Col flex="1" className={cx(s.walletBlnc, s.card)}>
          <div className={cx(s.balanceLabel, s.striked)}>
            Current Epoch: &nbsp;
            <span className={s.balanceValue}>{stakingSwapp.currentEpoch}</span>
          </div>
          <div className={cx(s.balanceLabel, s.striked)}>
            You staked at epoch: &nbsp;
            <span className={s.balanceValue}>{stakingSwapp.stakes && stakingSwapp.stakes.startEpoch}</span>
          </div>
          <div className={cx(s.balanceLabel, s.striked)}>
            { stakingSwapp?.currentEpoch! < stakingSwapp.stakes?.endEpoch ?
              <span>Your stake will mature at the end of epoch: &nbsp;</span>  
              :
              <span>Your stake already matured on epoch: &nbsp;</span>  
            }
            <span className={s.balanceValue}>{stakingSwapp.stakes && stakingSwapp.stakes.endEpoch - 1}</span>
          </div>
        </Antd.Col>
        <Antd.Col flex="1" className={s.card}>
          <div className={cx(s.balanceLabel, s.striked)}>Stake progress:</div>
          <div className={s.progress}>
            <div className={s.progressBar}>
              <Antd.Progress
                percent={persents}
                status={progress < 100 ? "normal" : "active"}
                strokeColor={progress < 100 ? "#EEF769" : "#1bc943"}
              />
            </div>
            <div className={s.progressDesc}>
              <div>{persents.toFixed(2)}% Matured</div>
              <div>{getDaysLeft()}</div>
            </div>
          </div>
        </Antd.Col>
        <Antd.Col flex="1" className={s.card}>
          <div className={cx(s.balanceLabel, s.striked)}>
            Accrued Duration Bonus: &nbsp;
            <span className={s.balanceValue}>{(+yfNewSWAPP.collectedDurationBonus!).toFixed(2)} SWAPP</span>
          </div>
          <div className={cx(s.balanceLabel, s.striked)}>
            Estimated Duration Bonus for Current Epoch: &nbsp;
            <span className={s.balanceValue}>{(+yfNewSWAPP.estimateDurationBonus!).toFixed(2)} SWAPP</span>
          </div>
        </Antd.Col>
      </Antd.Row>
      :
    null 
  );
};

export default AdditionalPanel;
