import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import * as Antd from 'antd';
import Chart from 'react-apexcharts';
import moment from 'moment';
import Web3 from 'web3';
import {
	getEtherscanTxUrl,
	shortenAddr,
	getEtherscanAddressUrl,
} from 'web3/utils';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import { formatDistance } from 'date-fns';

import { SWAPPTokenMeta } from 'web3/contracts/swapp';

import PoolStats from 'views/pools/components/pool-stats';
import CardWidget from 'components/card';
import InfoTooltip from 'components/info-tooltip';
import ComingSoonModal from 'components/coming-soon-modal';
import ExternalLink from 'components/externalLink';
import PaginationElement from 'components/pagination';

import { numberWithCommas } from 'functions/helpers';
import {
	days,
	months,
	burnRateOptions,
	burnedPercentOptions,
	burnRateOptionsCurrentMonth,
} from './constants';

import s from './styles.module.css';
import PoolTransactionTable from "../pools/components/pool-transaction-table";
import BurnTransactionTableInner from "./components/burn-transaction-table";

export type Props = {};

const Burn: React.FunctionComponent<Props> = (props) => {
	const history = useHistory();

	const [result, setResult]: any[] = useState([]);
	const [loader, setLoader] = useState(true);
	const [totalSupply, setTotalSupply] = useState();
	const [paginationValues, setPaginationValues] = useState({
		minValue: 0,
		maxValue: 10,
	});
	const [burnedPercentSeries, setBurnedPercentSeries]: any[] = useState([
		0,
		totalSupply ? +totalSupply! : 0,
	]);
	const [burnRateSeries, setBurnRateSeries]: any[] = useState([{ data: [] }]);
	const [
		burnRateSeriesCurrentMonths,
		setBurnRateSeriesCurrentMonths,
	]: any[] = useState([{ data: [] }]);
	const [burnRateCurrentMonths, setBurnRateCurrentMonths] = useState(0);

	const url = process.env.REACT_APP_BASE_API_URI;

	useEffect(() => {
		fetch(`${url}/burn?page=${paginationValues.maxValue / 10}`)
			.then((result) => result.json())
			.then((result) => {
				setResult(result);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationValues.maxValue]);

	useEffect(() => {
		fetch(`${url}/swapp-token/total-supply`)
			.then((result) => result.json())
			.then((result) => {
				setTotalSupply(result);
			});
	}, []);

	useEffect(() => {
		fetch(`${url}/burn/burned`)
			.then((result) => result.json())
			.then((result) => {
				calculateBurnRateMonth(result);
				calculatePersentBurned(result.burnedAmount!, result.tempBurnedAmount);
				calculateBurnRateYear(result.burnedYear?.items!);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [totalSupply]);

	const calculatePersentBurned = (
		burnedAmount: number,
		tempBurnedAmount: number
	) => {
		const newBurned = [...burnedPercentSeries];
		newBurned[0] = burnedAmount!;
		newBurned[1] = tempBurnedAmount;
		newBurned[2] = +totalSupply!;
		setBurnedPercentSeries(newBurned);
	};

	const calculateBurnRateYear = (newData: any) => {
		const burnRateSeries: number[] = [];

		months.map((month: string) => {
			let counter: number = 0;
			const monthValues: AnimationPlaybackEvent[] = newData.filter(
				(item: { date: number }) => {
					return moment.unix(item.date).format('MMM-YYYY') === month;
				}
			);

			if (isEmpty(monthValues)) burnRateSeries.push(0);
			else {
				monthValues.map((item: any) => (counter += +item.amount));
				burnRateSeries.push(counter);
			}
			return null;
		});
		setBurnRateSeries([{ data: burnRateSeries }]);
		setLoader(false);
	};

	const calculateBurnRateMonth = (newData: any) => {
		const burnRateSeriesCurrentMonths: number[] = [];
		const monthValues: any[] = newData.burnedMonth.items.filter(
			(item: { date: number }) => {
				return (
					moment.unix(item.date).format('MMM-YYYY') ===
					moment().format('MMM-YYYY')
				);
			}
		);

		let counter: number = 0;
		monthValues.map((item: { amount: string }) => (counter += +item.amount));
		setBurnRateCurrentMonths(counter);

		days.map((day: string) => {
			let counter: number = 0;
			const dayValues: any[] = monthValues.filter((item: { date: number }) => {
				return moment.unix(item.date).format('DD-MM-YYYY') === day;
			});

			if (isEmpty(dayValues)) burnRateSeriesCurrentMonths.push(0);
			else {
				dayValues.map((item: any) => (counter += +item.amount));
				burnRateSeriesCurrentMonths.push(counter);
			}
			return null;
		});

		setBurnRateSeriesCurrentMonths([{ data: burnRateSeriesCurrentMonths }]);
	};

	const handlePaginationValues = (minValue: number, maxValue: number) => {
		setPaginationValues({ minValue, maxValue });
	};

	const goBack = () => {
		history.replace('/pools');
	};

	const percent = (
		((burnedPercentSeries[0] + burnedPercentSeries[1]) * 100) /
		burnedPercentSeries[2]
	).toFixed(3);

	return (
		<div>
			<PoolStats />
			<div className={s.titleHeader}>
				<Antd.Button type='link' className={s.headerLabel} onClick={goBack}>
					Burned Tokens
				</Antd.Button>
			</div>
			<div className={s.main}>
				<div className={s.row1}>
					<CardWidget>
						<div className={s.cardHeader}>
							<div className={s.cardTitle}>Percent Burned</div>
							<InfoTooltip title='?' />
						</div>
						{loader ? (
							<div className={s.preloader_custom}>
								<Spin
									indicator={<LoadingOutlined style={{ fontSize: 62 }} spin />}
								/>
							</div>
						) : (
							<>
								{!isNaN(+percent) && (
									<>
										<div className={s.cardDescription}>
											{percent} % -
											{numberWithCommas(
												(
													burnedPercentSeries[0] + burnedPercentSeries[1]
												).toFixed(2)
											)}{' '}
											Tokens
										</div>
										<div className={s.percentChartWrapper}>
											<div className={s.innerWrapperDonut}>
												<Chart
													options={burnedPercentOptions}
													series={burnedPercentSeries}
													type='donut'
													height='100%'
												/>
											</div>
										</div>
									</>
								)}
							</>
						)}
					</CardWidget>
					<CardWidget>
						<div className={s.cardHeader}>
							<div className={s.cardTitle}>Burn Rate (12 months)</div>
							<InfoTooltip title='?' />
						</div>
						{loader ? (
							<div className={s.preloader_custom}>
								<Spin
									indicator={<LoadingOutlined style={{ fontSize: 62 }} spin />}
								/>
							</div>
						) : (
							<>
								<div className={s.cardDescription}>
									{numberWithCommas(
										(burnedPercentSeries[0] + burnedPercentSeries[1]).toFixed(2)
									)}{' '}
									Tokens Burned
								</div>
								<div className={s.burnRateChartWrapper}>
									<div className={s.innerWrapper}>
										<Chart
											options={burnRateOptions}
											series={burnRateSeries}
											type='area'
											height='100%'
										/>
									</div>
								</div>
							</>
						)}
					</CardWidget>
				</div>
				<CardWidget className={s.burnRate}>
					<div className={s.cardHeader}>
						<div className={s.cardTitle}>Burn Rate (Current month)</div>
						<InfoTooltip title='?' />
					</div>
					{loader ? (
						<div className={s.preloader_custom}>
							<Spin
								indicator={<LoadingOutlined style={{ fontSize: 62 }} spin />}
							/>
						</div>
					) : (
						<>
							<div className={s.cardDescription}>
								{numberWithCommas(burnRateCurrentMonths.toFixed(2))} Tokens
								Burned
							</div>
							<div className={s.burnRateChartWrapper1}>
								<div className={s.innerWrapper}>
									<Chart
										options={burnRateOptionsCurrentMonth}
										series={burnRateSeriesCurrentMonths}
										type='area'
										height='100%'
									/>
								</div>
							</div>
						</>
					)}
				</CardWidget>
				<div className={s.labelTitle}>Transactions</div>
				<div className={s.tableInner}>
					{loader ? (
						<div className={s.preloader_custom}>
							<Spin
								indicator={<LoadingOutlined style={{ fontSize: 62 }} spin />}
							/>
						</div>
					) : (
						<>
							<BurnTransactionTableInner />
						</>
					)}
				</div>
			</div>

			<ComingSoonModal
				visible={false}
				message={
					<span>
						Burning Dashboard will be activated after{' '}
						<a rel='noopener noreferrer' target='_self' href='/reserve'>
							30 DAY Auction
						</a>{' '}
						is completed
					</span>
				}
				onCancel={() => {
					goBack();
				}}
			/>
		</div>
	);
};

export default Burn;
