import React, { FunctionComponent, useEffect, useState } from 'react';

import * as Antd from 'antd';
import s from './styles.module.css';
import moment from "moment";
import { useWeb3Contracts } from 'web3/contracts';

import { ModalProps } from 'antd/lib/modal';
import InfoContributions from './info-contributions';
import InfoReferrals from '../info-referrals/';
import EmptyReferralWarning from '../referrals-contributions';

export type modalTabsKey = 'contribution' | 'referral'
export type ContributionsProps = ModalProps & { modalKey: modalTabsKey };

interface IContribution {
  id: number,
  date: string,
  amount: string,
  swapp: string,
  info: string,
}

interface IReferral {
  id: number,
  date: string,
  amount: string,
  swapp: string,
}

const Contribution: FunctionComponent<ContributionsProps> = props => {
  const { swapp, transformer } = useWeb3Contracts();
  const [modalTab, setModalTab] = useState({ title: '', subHeading: '', key: '' });

  const tabs = {
    contribution: {
      title: 'Your contributions',
      subHeading: 'Historical data of your contributions into reservation days',
      key: 'contribution'
    },
    referral: {
      title: 'Your referrals',
      subHeading: 'Historical data of reservations made with your referral link.',
      key: 'referral'
    }
  }

  useEffect(() => {
    setModalTab(tabs[props.modalKey]);
  }, [props.modalKey])

  const handleOnTabClick = (key: modalTabsKey) => {
    setModalTab(tabs[key])
  }

  const renderDate = (index: number) => {
    let date: string = '';
    if (swapp && swapp.launchTime) {
      date = `${moment.unix(+swapp.launchTime).add(index, 'days').format('DD/MM/yy')} - ${index + 1} Day`;
    } 
    return date
  }

  // const dataContribution: any = 
  //   transformer && transformer?.userInvestmentAmountAllDays?.map((item: any, index: number) => {
  //     if (+item) {
  //       return {
  //         id: index,
  //         date: renderDate(index),
  //         amount: +item,
  //         swapp: '0.00 SWAPP',
  //         info: 'view transaction',
  //       }
  //     } return null
  //   }
  // )

  const dataReferral: IReferral[] = [
    // {
    //   id: 1,
    //   date: '11/11/2020 - Day 1',
    //   amount: '0.00 ETH',
    //   swapp: '0x3bab..37ac'
    // },
    // {
    //   id: 2,
    //   date: '11/11/2020 - Day 1',
    //   amount: '0.00 ETH',
    //   swapp: '0x3bab..37ac'
    // }
  ];

  return (
    <Antd.Modal
      className={s.component}
      centered
      closable
      destroyOnClose={true}
      footer={[]}
      {...props}
    >
      <div className={s.header}>
        <h2>{modalTab.title}</h2>
        <h3>{modalTab.subHeading}</h3>
      </div>
      <Antd.Tabs
        className={s.tabs}
        defaultActiveKey={modalTab.key}
        onTabClick={(key, event) => handleOnTabClick(key as any)}
        centered
      >
        <Antd.Tabs.TabPane
          key="contribution"
          tab={
            <span>Your contributions</span>
          }
        >
          <div className={s.sorting}>
            <Antd.Switch defaultChecked={false} onChange={() => { }} />
            <p>ETH sorting</p>
          </div>
          {/* <InfoContributions data={dataContribution} /> */}
        </Antd.Tabs.TabPane>
        <Antd.Tabs.TabPane
          key="referral"
          tab={
            <span >Your referrals</span>
          }
        >
          {
            dataReferral.length > 0 ?
              <InfoReferrals data={dataReferral} />
              :
              <EmptyReferralWarning />
          }
          <Antd.Row justify="center" >
            <Antd.Col
              span={8}
              className={s.loading}
            >
              <Antd.Button className={s.btnCopy}>Copy referral link</Antd.Button>
            </Antd.Col>
          </Antd.Row>
        </Antd.Tabs.TabPane>
      </Antd.Tabs>
    </Antd.Modal>

  );
};

export default Contribution;