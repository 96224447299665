import React, { useState } from "react";
import { useAsyncEffect } from 'hooks/useAsyncEffect';
import { useWeb3Contracts } from 'web3/contracts';
// import BigNumber from 'bignumber.js';
import { useParams } from "react-router-dom";


// import s from '../../views/pools/components/pool-bonus-calculator/styles.module.css';
import BonusCalculator from '../../views/pools/components/pool-bonus-calculator/index';

export type WidgetsViewProps = {};

const WidgetsView: React.FunctionComponent<WidgetsViewProps> = (props) => {
  const { staking } = useWeb3Contracts();
  const [values, setValues] = useState([]);
  const [modalVisibility, setModalVisibility] = useState(false);

  const params: any = useParams()

  useAsyncEffect(async() => {
    const array: any = await staking.getPotentialInterestFromCompoundFunction();
    setValues(array);
  }, [staking])

  const renderWidgets = () => {
    switch (params.widgetName) {
      case 'calculator':
        return (
          <div id='calculator'>
            <BonusCalculator
              visible={modalVisibility}
              setVisibility={() => setModalVisibility(false)}
              hideInput={false}
            />
          </div>
        )
      default:
        break;
      }
  }

  return (
    <div>
      {renderWidgets()}
    </div>
  );
};

export default WidgetsView;
