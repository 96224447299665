import React from 'react';
import * as Antd from 'antd';
import BigNumber from 'bignumber.js';

import {
  fixArrayLength,
  fixBNArrayLength,
  getHumanValue,
  getWSRpcUrl,
  PoolTypes, ZERO_BIG_NUMBER } from 'web3/utils';
import { useWallet } from 'wallets/wallet';
import Web3Contract from 'web3/contract';
import { SWAPPContract, SWAPPTokenMeta, useSWAPPContract } from 'web3/contracts/swapp';
import { USDCContract, USDCTokenMeta, useUSDCContract } from 'web3/contracts/usdc';
import { USDTContract, USDTTokenMeta, useUSDTContract } from 'web3/contracts/usdt';
import { DAIContract, DAITokenMeta, useDAIContract } from 'web3/contracts/dai';
import { UNISWAPContract, UNISWAPTokenMeta, useUNISWAPContract } from 'web3/contracts/uniswap';
import { useYieldFarmContract, YieldFarmContract } from 'web3/contracts/yieldFarm';
import { useYieldFarmLPContract, YieldFarmLPContract } from 'web3/contracts/yieldFarmLP';
import { useYieldFarmSWAPPContract, YieldFarmSWAPPContract } from 'web3/contracts/yieldFarmSWAPP';
import { useYieldFarmNewSWAPPContract, YieldFarmNewSWAPPContract } from 'web3/contracts/yieldFarmNewSWAPP';
import { StakingContract, useStakingContract } from 'web3/contracts/staking';
import { StakingSwappContract, useStakingSwappContract } from 'web3/contracts/stakingSwapp';
import { TransformerContract, useTransformerContract } from 'web3/contracts/transformer';

import UserRejectedModal from 'components/user-rejected-modal';
import Web3 from 'web3';

export type Web3ContractsData = {
  swapp: SWAPPContract;
  usdc: USDCContract;
  usdt: USDTContract;
  dai: DAIContract;
  uniswap: UNISWAPContract;
  yf: YieldFarmContract;
  yfLP: YieldFarmLPContract;
  yfSWAPP: YieldFarmSWAPPContract;
  yfNewSWAPP: YieldFarmNewSWAPPContract;
  staking: StakingContract;
  stakingSwapp: StakingSwappContract;
  transformer: TransformerContract;
  aggregated: {
    yfStakedValue?: BigNumber;
    yfEffectiveStakedValue?: BigNumber;
    yfLPStakedValue?: BigNumber;
    yfNewSWAPPStaked?: BigNumber;
    myLPStakedValue?: BigNumber;
    myNewSWAPPStakedValue?: BigNumber;
    yfLPEffectiveStakedValue?: BigNumber;
    yfNewSWAPPEffectiveStakedValue?: BigNumber;
    myLPEffectiveStakedValue?: BigNumber;
    myLPNewSWAPPEffectiveStakedValue?: BigNumber;
    yfSWAPPStakedValue?: BigNumber;
    yfNewSWAPPStakedValue?: BigNumber;
    mySWAPPStakedValue?: BigNumber;
    yfSWAPPEffectiveStakedValue?: BigNumber;
    mySwappEffectiveStakedValue?: BigNumber;
    totalStaked?: BigNumber;
    totalEffectiveStaked?: BigNumber;
    totalCurrentReward?: BigNumber;
    totalPotentialReward?: BigNumber;
    totalSwappReward?: BigNumber;
    swappReward?: BigNumber;
    lockedByEpoch?: BigNumber[];
    rewardByEpoch?: number[];
  };
};

export type Web3Contracts = Web3ContractsData & {
  getPoolUsdPrice(poolType: PoolTypes): BigNumber | undefined;
  getTokenUsdPrice(tokenAddress: string): BigNumber | undefined;
};

const Web3ContractsContext = React.createContext<Web3Contracts>({} as any);

export function useWeb3Contracts(): Web3Contracts {
  return React.useContext(Web3ContractsContext);
}

const Web3ContractsProvider: React.FunctionComponent = props => {
  const wallet = useWallet();
  const swappContract = useSWAPPContract();
  const daiContract = useDAIContract();
  const usdcContract = useUSDCContract();
  const usdtContract = useUSDTContract();
  const uniswapContract = useUNISWAPContract();
  const yfContract = useYieldFarmContract();
  const yfLPContract = useYieldFarmLPContract();
  const yfSWAPPContract = useYieldFarmSWAPPContract();
  const yfNewSWAPPContract = useYieldFarmNewSWAPPContract();
  const stakingContract = useStakingContract();
  const stakingSwappContract = useStakingSwappContract();
  const transformerContract = useTransformerContract();

  const [userRejectedVisible, setUserRejectedVisible] = React.useState<boolean>(false);

  React.useEffect(() => {
    const contracts = [
      swappContract.contract,
      daiContract.contract,
      usdcContract.contract,
      usdtContract.contract,
      uniswapContract.contract,
      yfContract.contract,
      yfLPContract.contract,
      yfSWAPPContract.contract,
      yfNewSWAPPContract.contract,
      stakingContract.contract,
      stakingSwappContract.contract,
      transformerContract.contract,
    ];

    function handleError(err: Error & { code: number }, contract: Web3Contract, { method }: any) {
      console.error(`${contract.name}:${method}`, { error: err });

      if (err.code === 4001) {
        setUserRejectedVisible(true);
      } else {
        Antd.notification.error({
          message: err.message,
        });
      }
    }

    contracts.forEach((contract: Web3Contract) => {
      contract.on('error', handleError);
    });

    return () => {
      contracts.forEach((contract: Web3Contract) => {
        contract.off('error', handleError);
      });
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const contracts = [
      swappContract.contract,
      daiContract.contract,
      usdcContract.contract,
      usdtContract.contract,
      uniswapContract.contract,
      yfContract.contract,
      yfLPContract.contract,
      yfSWAPPContract.contract,
      yfNewSWAPPContract.contract,
      stakingContract.contract,
      stakingSwappContract.contract,
      transformerContract.contract,
    ];

    if ((wallet.provider && (wallet.networkId === Number(process.env.REACT_APP_WEB3_CHAIN_ID)))) {
      contracts.forEach(contract => {
        contract.setProvider(wallet.provider);
      });
    } else {
      const web3 = new Web3(new Web3.providers.WebsocketProvider(getWSRpcUrl()));
      contracts.forEach(contract => {
        contract.setProvider(web3);
      });
    }

  }, [wallet.provider, wallet.networkId]); // eslint-disable-line react-hooks/exhaustive-deps

  function getPoolUsdPrice(poolType: PoolTypes): BigNumber | undefined {
    switch (poolType) {
      case PoolTypes.STABLE:
        return uniswapContract.stablePrice;
      case PoolTypes.UNILP:
        return uniswapContract.unilpPrice;
      case PoolTypes.SWAPP:
        return uniswapContract.swappPrice;
      default:
        return undefined;
    }
  }

  function getTokenUsdPrice(tokenAddress: string): BigNumber | undefined {
    switch (tokenAddress) {
      case USDCTokenMeta.address:
      case USDTTokenMeta.address:
      case DAITokenMeta.address:
      case UNISWAPTokenMeta.address:
        return getPoolUsdPrice(PoolTypes.UNILP);
      case SWAPPTokenMeta.address:
        return getPoolUsdPrice(PoolTypes.SWAPP);
      default:
        return undefined;
    }
  }

  function yfStakedValue() {
    const poolSize = yfContract.nextPoolSize;
    const price = uniswapContract.stablePrice;

    if (poolSize === undefined || price === undefined) {
      return undefined;
    }

    return poolSize.multipliedBy(price);
  }

  function yfEffectiveStakedValue() {
    if (yfContract.currentEpoch == 0) {
      return undefined
    }
    const poolSize = yfContract.poolSize;
    const price = uniswapContract.stablePrice;
    if (poolSize === undefined || price === undefined) {
      return undefined;
    }

    return poolSize.multipliedBy(price);
  }

  //Pool balance common
  function yfLPStakedValue() {
    const poolSize = yfLPContract.nextPoolSize;
    const price = uniswapContract.unilpPrice;
    if (poolSize === undefined || price === undefined) {
      return undefined;
    }

    return poolSize.multipliedBy(price);
  }

  //Pool balance common New
  function yfNewSWAPPStakedValue() {
    const poolSize = yfNewSWAPPContract.nextPoolSize;
    const price = uniswapContract.swappPrice;

    if (poolSize === undefined || price === undefined) {
      return undefined;
    }

    return poolSize.multipliedBy(price);
  }

  //My pool balance
  function myLPStakedValue() {
    const epochStake = yfLPContract.nextEpochStake;
    const price = uniswapContract.unilpPrice;

    if (epochStake === undefined || price === undefined) {
      return undefined;
    }

    return epochStake.multipliedBy(price);
  }

  //My pool balance New
  function myNewSWAPPStakedValue() {
    const epochStake = yfNewSWAPPContract.nextEpochStake;
    const price = uniswapContract.swappPrice;

    if (epochStake === undefined || price === undefined) {
      return undefined;
    }

    return epochStake.multipliedBy(price);
  }

  //Pool effective balance
  function yfLPEffectiveStakedValue() {
    if (yfLPContract.currentEpoch == 0) {
      return undefined
    }
    const poolSize = yfLPContract.poolSize;
    const price = uniswapContract.unilpPrice;

    if (poolSize === undefined || price === undefined) {
      return undefined;
    }

    return poolSize.multipliedBy(price);
  }

  //My effective balance New
  function yfNewSWAPPEffectiveStakedValue() {
	  if (yfNewSWAPPContract.currentEpoch == 0) {
      return undefined
    }
    const poolSize = yfNewSWAPPContract.poolSize;
    const price = uniswapContract.swappPrice;

    if (poolSize === undefined || price === undefined) {
      return undefined;
    }

    return poolSize.multipliedBy(price);
  }

  //My pool effective balance
  function myLPEffectiveStakedValue() {
    if (yfLPContract.currentEpoch == 0) {
      return undefined
    }
    const epochStake = yfLPContract.epochStake;
    const price = uniswapContract.unilpPrice;

    if (epochStake === undefined || price === undefined) {
      return undefined;
    }

    return epochStake.multipliedBy(price);
  }

  //My pool effective balance New
  function myLPNewSWAPPEffectiveStakedValue() {
    if (yfNewSWAPPContract.currentEpoch == 0) {
      return undefined
    }
    const epochStake = yfNewSWAPPContract.epochStake;
    const price = uniswapContract.swappPrice;

    if (epochStake === undefined || price === undefined) {
      return undefined;
    }

    return epochStake.multipliedBy(price);
  }

  function yfSWAPPStakedValue() {
	  const globals = yfSWAPPContract?.globals?? [];
    let poolSize = globals[0];
    const price = uniswapContract.swappPrice;

    if (poolSize === undefined || price === undefined) {
      return undefined;
    }

	  poolSize = getHumanValue(new BigNumber(poolSize), 18)?? new BigNumber(0)

    return new BigNumber(poolSize)?.multipliedBy(price);
  }

  function mySWAPPStakedValue() {
    const amount = yfSWAPPContract.userStakedAmount;
    const price = uniswapContract.swappPrice;

    if (amount === undefined || price === undefined) {
      return undefined;
    }

    return amount.multipliedBy(price);;
  }

  function yfSWAPPEffectiveStakedValue() {
    if (yfNewSWAPPContract.currentEpoch == 0) {
      return undefined
    }
    const poolSize = yfNewSWAPPContract.poolSize;
    const price = uniswapContract.swappPrice;

    if (poolSize === undefined || price === undefined) {
      return undefined;
    }

    return poolSize.multipliedBy(price);
  }

  function mySwappEffectiveStakedValue() {
    if (yfNewSWAPPContract.currentEpoch == 0) {
      return undefined
    }
    const epochStake = yfNewSWAPPContract.epochStake;
    const price = uniswapContract.swappPrice;

    if (epochStake === undefined || price === undefined) {
      return undefined;
    }

    return epochStake.multipliedBy(price);
  }

  function totalStaked(): BigNumber | undefined {
    const yfStaked = yfStakedValue();
    const yfLPStaked = yfLPStakedValue();
    const yfNewSWAPPStaked = yfNewSWAPPStakedValue();
    // const yfSWAPPStaked = yfSWAPPStakedValue();

    // if (
    //   yfStaked === undefined ||
    //   yfLPStaked === undefined ||
    //   yfSWAPPStaked === undefined
    // ) {
    //   return undefined;
    // }

    return (yfStaked?? ZERO_BIG_NUMBER)
      .plus(yfLPStaked?? ZERO_BIG_NUMBER)
      .plus(yfNewSWAPPStaked?? ZERO_BIG_NUMBER)
      // .plus(yfSWAPPStaked?? ZERO_BIG_NUMBER);
  }

  function lockedByEpoch() : BigNumber[] | undefined{
    const stablePrice = uniswapContract.stablePrice;
    const unilpPrice = uniswapContract.unilpPrice;
    const swappPrice = uniswapContract.swappPrice;
    if(
        yfContract.epochPoolSize
        && yfLPContract.epochPoolSize
        && yfNewSWAPPContract.epochPoolSize
        && stablePrice
        && unilpPrice
        && swappPrice
    ){
      const pools = [
        yfContract.epochPoolSize,
        yfLPContract.epochPoolSize,
        yfNewSWAPPContract.epochPoolSize]
      const maxItems = Math.max(...pools.map(el=>el.length));
      const fixedPoolsLength = fixBNArrayLength(pools,maxItems);

      let resultTotalLocked: BigNumber[] = [];
      for (let i = 0; i <= maxItems-1; i++) {
        resultTotalLocked.push((fixedPoolsLength[0][i].multipliedBy(stablePrice))
            .plus(fixedPoolsLength[1][i].multipliedBy(unilpPrice))
            .plus(fixedPoolsLength[2][i].multipliedBy(swappPrice)))
      }
      return resultTotalLocked;
    } else return undefined;
  }

  function totalEffectiveStaked(): BigNumber | undefined {
    const yfStaked = yfEffectiveStakedValue();
    const yfLPStaked = yfLPEffectiveStakedValue();
    const yfNewSWAPPStaked = yfNewSWAPPEffectiveStakedValue();
    // const yfSWAPPStaked = yfSWAPPEffectiveStakedValue();

    // if (
    //   yfStaked === undefined ||
    //   yfLPStaked === undefined ||
    //   yfSWAPPStaked === undefined
    // ) {
    //   return undefined;
    // }

    return (yfStaked?? ZERO_BIG_NUMBER)
      .plus(yfLPStaked?? ZERO_BIG_NUMBER)
      .plus(yfNewSWAPPStaked?? ZERO_BIG_NUMBER);
      // .plus(yfSWAPPStaked?? ZERO_BIG_NUMBER);
  }

  function totalCurrentReward(): BigNumber | undefined {
    const yfReward = yfContract.currentEpoch === 0 ? ZERO_BIG_NUMBER : yfContract.currentReward;
    const yfLPReward = yfLPContract.currentEpoch === 0 ? ZERO_BIG_NUMBER : yfLPContract.currentReward;
    const yfSwappReward = yfNewSWAPPContract.currentEpoch === 0 ? ZERO_BIG_NUMBER : yfNewSWAPPContract.currentReward;

    // if (
    //   yfReward === undefined ||
    //   yfLPReward === undefined ||
    //   yfSwappReward === undefined
    // ) return undefined;

    return (yfReward ?? ZERO_BIG_NUMBER)
      .plus(yfLPReward ?? ZERO_BIG_NUMBER)
      .plus(yfSwappReward ?? ZERO_BIG_NUMBER);
  }

  function totalPotentialReward(): BigNumber | undefined {
    const yfReward = yfContract.potentialReward;
    const yfLPReward = yfLPContract.potentialReward;
    const yfSwappReward =
    yfNewSWAPPContract.currentEpoch! >= stakingSwappContract?.stakes?.endEpoch ?
    ZERO_BIG_NUMBER :
    yfNewSWAPPContract.potentialReward;

    if (
      yfReward === undefined ||
      yfLPReward === undefined ||
      yfSwappReward === undefined
    ) return undefined;

    return yfReward
      .plus(yfLPReward)
      .plus(yfSwappReward);
  }

  function totalSwappReward(): BigNumber | undefined {
    const yfTotalReward = yfContract.totalReward;
    const yfLPTotalReward = yfLPContract.totalReward;
    const yfSwappTotalReward = yfNewSWAPPContract.totalReward;

    if (
      yfTotalReward === undefined ||
      yfLPTotalReward === undefined ||
      yfSwappTotalReward === undefined
    ) return undefined;

    return yfTotalReward
      .plus(yfLPTotalReward)
      .plus(yfSwappTotalReward);
  }

  function swappReward(): BigNumber | undefined {
    const yfReward = yfContract.swappReward;
    const yfLPReward = yfLPContract.swappReward;
    const yfSwappReward = yfNewSWAPPContract.swappReward;

    if (
      yfReward === undefined ||
      yfLPReward === undefined ||
      yfSwappReward === undefined
    ) return undefined;

    return yfReward
      .plus(yfLPReward)
      .plus(yfSwappReward);
  }

  function rewardByEpoch(): number[] | undefined{
    const yfLPRewardByEpoch = yfLPContract.epochAmountByEpoch;
    const yfRewardByEpoch = yfContract.epochAmountByEpoch;
    const yfSwappRewardByEpoch = yfNewSWAPPContract.epochAmountByEpoch;
    const durationBonusByEpoch = yfNewSWAPPContract.durationBonusByEpoch;
    if(
        yfLPRewardByEpoch
        && yfRewardByEpoch
        && yfSwappRewardByEpoch
        && durationBonusByEpoch
    ) {
      const rewards = [
        yfLPRewardByEpoch,
        yfRewardByEpoch,
        durationBonusByEpoch,
        yfSwappRewardByEpoch,
        ]

      const maxItems = Math.max(...rewards.map(el => el.length));
      const fixedPoolsLength = fixArrayLength(rewards, maxItems);

      let resultTotalRewards: number[] = [];

      for (let i = 0; i <= maxItems-1; i++) {
        resultTotalRewards.push(
            fixedPoolsLength[0][i] +
            fixedPoolsLength[1][i] +
            fixedPoolsLength[2][i] +
            fixedPoolsLength[3][i]
        )
      }
      return resultTotalRewards;
    }
  }

  const value = {
    swapp: swappContract,
    usdc: usdcContract,
    usdt: usdtContract,
    dai: daiContract,
    uniswap: uniswapContract,
    yf: yfContract,
    yfLP: yfLPContract,
    yfSWAPP: yfSWAPPContract,
    yfNewSWAPP: yfNewSWAPPContract,
    staking: stakingContract,
    stakingSwapp: stakingSwappContract,
    transformer: transformerContract,
    aggregated: {
      get yfStakedValue(): BigNumber | undefined {
        return yfStakedValue();
      },
      get yfEffectiveStakedValue(): BigNumber | undefined {
        return yfEffectiveStakedValue();
      },
      get yfLPStakedValue(): BigNumber | undefined {
        return yfLPStakedValue();
      },
      get yfNewSWAPPStakedValue(): BigNumber | undefined {
        return yfNewSWAPPStakedValue();
      },
      get myLPStakedValue(): BigNumber | undefined {
        return myLPStakedValue();
      },
      get myNewSWAPPStakedValue(): BigNumber | undefined {
        return myNewSWAPPStakedValue();
      },
      get yfLPEffectiveStakedValue(): BigNumber | undefined {
        return yfLPEffectiveStakedValue();
      },
      get yfNewSWAPPEffectiveStakedValue(): BigNumber | undefined {
        return yfNewSWAPPEffectiveStakedValue();
      },
      get myLPEffectiveStakedValue(): BigNumber | undefined {
        return myLPEffectiveStakedValue();
      },
      get myLPNewSWAPPEffectiveStakedValue(): BigNumber | undefined {
        return myLPNewSWAPPEffectiveStakedValue();
      },
      get yfSWAPPStakedValue(): BigNumber | undefined {
        return yfSWAPPStakedValue();
      },
      get mySWAPPStakedValue(): BigNumber | undefined {
        return mySWAPPStakedValue();
      },
      get yfSWAPPEffectiveStakedValue(): BigNumber | undefined {
        return yfSWAPPEffectiveStakedValue();
      },
      get mySwappEffectiveStakedValue(): BigNumber | undefined {
        return mySwappEffectiveStakedValue();
      },
      get totalStaked(): BigNumber | undefined {
        return totalStaked();
      },
      get totalEffectiveStaked(): BigNumber | undefined {
        return totalEffectiveStaked();
      },
      get totalCurrentReward(): BigNumber | undefined {
        return totalCurrentReward();
      },
      get totalPotentialReward(): BigNumber | undefined {
        return totalPotentialReward();
      },
      get totalSwappReward(): BigNumber | undefined {
        return totalSwappReward();
      },
      get swappReward(): BigNumber | undefined {
        return swappReward();
      },
      get lockedByEpoch(): BigNumber[] | undefined{
        return lockedByEpoch();
      },
      get rewardByEpoch(): number[] | undefined{
        return rewardByEpoch();
      }
    },
    getPoolUsdPrice,
    getTokenUsdPrice,
  };

  return (
    <Web3ContractsContext.Provider value={value}>
      <UserRejectedModal
        visible={userRejectedVisible}
        onCancel={() => setUserRejectedVisible(false)}
      />
      {props.children}
    </Web3ContractsContext.Provider>
  );
};

export default Web3ContractsProvider;
