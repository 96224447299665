/* eslint-disable no-loop-func */
import React from 'react';
import BigNumber from 'bignumber.js';

import { useReload } from 'hooks/useReload';
import { useAsyncEffect } from 'hooks/useAsyncEffect';
import { useRefState } from 'hooks/useRefState';
import { TokenMeta } from 'web3/types';
import { getHumanValue, getNonHumanValue } from 'web3/utils';
import { useWallet } from 'wallets/wallet';
import Web3Contract, { BatchContractMethod } from 'web3/contract';
import { USDCTokenMeta } from 'web3/contracts/usdc';
import { USDTTokenMeta } from 'web3/contracts/usdt';
import { DAITokenMeta } from 'web3/contracts/dai';
import { UNISWAPTokenMeta } from 'web3/contracts/uniswap';
import { SWAPPTokenMeta } from 'web3/contracts/swapp';

export const CONTRACT_STAKING_ADDR = String(process.env.REACT_APP_CONTRACT_STAKING_ADDR);

type StakingTokenData = {
  epochPoolSize?: BigNumber;
  nextEpochPoolSize?: BigNumber;
  balance?: BigNumber;
  epochUserBalance?: BigNumber;
  nextEpochUserBalance?: BigNumber;
};

type StakingContractData = {
  referrers?: number;
  referrers2Level?: number;
  stableCoinBalance?: number;
  lpCoinBalance?: number;
  stableCoinBalance2Level?: number;
  lpCoinBalance2Level?: number;
  currentEpoch?: number;
  epoch1Start?: number;
  epochDuration?: number;
  currentEpochEnd?: number;
  hasReferrer?: boolean; 
  referrals?: string;
  referrals1Level?: string[];
  referrals2Level?: string[];
  usdc: StakingTokenData;
  usdt: StakingTokenData;
  dai: StakingTokenData;
  uniswap: StakingTokenData;
  swapp: StakingTokenData;
};

export type StakingContract = StakingContractData & {
  contract: Web3Contract;
  reload(): void;
  depositSend: (tokenMeta: TokenMeta, amount: BigNumber, gasPrice: number) => void;
  withdrawSend: (tokenMeta: TokenMeta, amount: BigNumber, gasPrice: number) => void;
  getEpochAt: (timestamp: number) => number | undefined;
  getEpochPeriod: (epoch: number) => [number, number] | undefined;
  getPotentialInterestFromCompoundFunction(): void;
}

const InitialData: StakingContractData = {
  referrers: undefined,
  referrers2Level: undefined,
  stableCoinBalance: undefined,
  lpCoinBalance: undefined,
  stableCoinBalance2Level: undefined,
  lpCoinBalance2Level: undefined,
  currentEpoch: undefined,
  epoch1Start: undefined,
  epochDuration: undefined,
  currentEpochEnd: undefined,
  hasReferrer: undefined,
  referrals: undefined,
  referrals1Level: undefined,
  referrals2Level: undefined,
  usdc: {
    epochPoolSize: undefined,
    nextEpochPoolSize: undefined,
    balance: undefined,
    epochUserBalance: undefined,
    nextEpochUserBalance: undefined,
  },
  usdt: {
    epochPoolSize: undefined,
    nextEpochPoolSize: undefined,
    balance: undefined,
    epochUserBalance: undefined,
    nextEpochUserBalance: undefined,
  },
  dai: {
    epochPoolSize: undefined,
    nextEpochPoolSize: undefined,
    balance: undefined,
    epochUserBalance: undefined,
    nextEpochUserBalance: undefined,
  },
  uniswap: {
    epochPoolSize: undefined,
    nextEpochPoolSize: undefined,
    balance: undefined,
    epochUserBalance: undefined,
    nextEpochUserBalance: undefined,
  },
  swapp: {
    epochPoolSize: undefined,
    nextEpochPoolSize: undefined,
    balance: undefined,
    epochUserBalance: undefined,
    nextEpochUserBalance: undefined,
  },
};

export function useStakingContract(): StakingContract {
  const [reload] = useReload();
  const wallet = useWallet();

  const contract = React.useMemo<Web3Contract>(() => {
    return new Web3Contract(
      require('web3/abi/staking.json'),
      CONTRACT_STAKING_ADDR,
      'STAKING',
    );
  }, []);

  const [data, setData, dataRef] = useRefState<StakingContractData>(InitialData);

  const getReferralByIdFunction = async (address: string | undefined, counter: number) => {
    return await contract.batch([
      {
        method: 'getReferralById',
        methodArgs: [address, counter],
      },
    ]);
  }

  const getPotentialInterestFromCompoundFunction = React.useCallback(async () => {
    return await contract.batch([
      {
        method: 'checkInterestFromCompound',
        methodArgs: [USDCTokenMeta.address],
        transform: (value: string) => getHumanValue(new BigNumber(value), USDCTokenMeta.decimals),
      },
            {
        method: 'checkInterestFromCompound',
        methodArgs: [USDTTokenMeta.address],
        transform: (value: string) => getHumanValue(new BigNumber(value), USDTTokenMeta.decimals),
      },
            {
        method: 'checkInterestFromCompound',
        methodArgs: [DAITokenMeta.address],
        transform: (value: string) => getHumanValue(new BigNumber(value), DAITokenMeta.decimals),
      },
    ]);
  }, [])

  const getBalanceOf = async (getReferralById: string) => {
    return await contract.batch([
      ...[
        USDCTokenMeta,
        USDTTokenMeta,
        DAITokenMeta,
        UNISWAPTokenMeta,
      ].reduce((ac: BatchContractMethod[], token) => {
        return [
          ...ac,
          {
            method: 'balanceOf',
            methodArgs: [getReferralById, token.address],
            transform: (value: string) => getHumanValue(new BigNumber(value), token.decimals),
          },
        ];
      }, []),
    ])
  }

  useAsyncEffect(async () => {
    const [referrers, hasReferrer, referrals, currentEpoch, epoch1Start, epochDuration] = await contract.batch([
      {
        method: 'referrers',
        methodArgs: [wallet.account],
        transform: (value: string) => Number(value),
      },
      {
        method: 'hasReferrer',
        methodArgs: [wallet.account],
        transform: (value: boolean) => value,
      },
      {
        method: 'referrals',
        methodArgs: [wallet.account],
        transform: (value: string) => value,
      },
      {
        method: 'getCurrentEpoch',
        transform: (value: string) => Number(value),
      },
      {
        method: 'epoch1Start',
        transform: (value: string) => Number(value) * 1000,
      },
      {
        method: 'epochDuration',
        transform: (value: string) => Number(value) * 1000,
      },
      // {
      //   method: 'checkInterestFromCompound',
      //   methodArgs: ['0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'],
      //   transform: (value: string) => getHumanValue(new BigNumber(value), USDCTokenMeta.decimals),
      // },
    ]);

    let referrals1Level: string[] = [];
    let stableCoinBalance = 0;
    let lpCoinBalance = 0;
    const promisesArray: any = [];

    for (let i = 1; i <= referrers; i++) {
      const res =  new Promise(async resolve => {
        try {
          const [getReferralById] = await getReferralByIdFunction(wallet.account, i)
          referrals1Level.push(getReferralById);

          const [
            usdcBalance,
            usdtBalance,
            daiBalance,
            lpBalance,
          ] = await getBalanceOf(getReferralById);

          stableCoinBalance += +usdcBalance + +usdtBalance + +daiBalance;
          lpCoinBalance += +lpBalance;
          
          return resolve(undefined);
        } catch (e) {
          return resolve(undefined);
        }
      });
      promisesArray.push(res);
    }
    await Promise.all(promisesArray);

    setData(prevState => ({
      ...prevState,
      referrers,
      stableCoinBalance,
      lpCoinBalance,
      currentEpoch,
      epoch1Start,
      epochDuration,
      hasReferrer,
      referrals,
      referrals1Level,
      currentEpochEnd: epoch1Start + (currentEpoch * epochDuration),
    }));

    let referrers2Level = 0;
    let stableCoinBalance2Level = 0;
    let lpCoinBalance2Level = 0;
    let referrals2Level: string[] = [];

    for (let i = 0; i < referrals1Level.length; i++) {
      const [referrers] = await contract.batch([
        {
          method: 'referrers',
          methodArgs: [referrals1Level[i]],
          transform: (value: string) => Number(value),
        },
      ]);
      referrers2Level += referrers;
      const promisesArray3: any = [];

      for (let y = 0; y <= referrers; y++) {
        const res =  new Promise(async resolve => {
          try {
            const [getReferralById] = await getReferralByIdFunction(referrals1Level[i], y)

            if (getReferralById !== "0x0000000000000000000000000000000000000000") {
              referrals2Level.push(getReferralById);
              const [
                usdcBalance,
                usdtBalance,
                daiBalance,
                lpBalance,
              ] = await getBalanceOf(getReferralById);
      
              stableCoinBalance2Level += +usdcBalance + +usdtBalance + +daiBalance;
              lpCoinBalance2Level += +lpBalance
            }
            return resolve(undefined);
          } catch (e) {
            return resolve(undefined);
          }
        });
        promisesArray3.push(res);
      }
      await Promise.all(promisesArray3);
    }
    
    setData(prevState => ({
      ...prevState,
      referrers2Level,
      stableCoinBalance2Level,
      lpCoinBalance2Level,
      referrals2Level,
    }));

    const [
      usdcEpochPoolSize,
      usdcNextEpochPoolSize,
      usdtEpochPoolSize,
      usdtNextEpochPoolSize,
      daiEpochPoolSize,
      daiNextEpochPoolSize,
      uniEpochPoolSize,
      uniNextEpochPoolSize,
      swappEpochPoolSize,
      swappNextEpochPoolSize,
    ] = await contract.batch([
      ...[
        USDCTokenMeta,
        USDTTokenMeta,
        DAITokenMeta,
        UNISWAPTokenMeta,
        SWAPPTokenMeta,
      ].reduce((ac: BatchContractMethod[], token) => {
        return [
          ...ac,
          {
            method: 'getEpochPoolSize',
            methodArgs: [token.address, currentEpoch],
            transform: (value: string) => getHumanValue(new BigNumber(value), token.decimals),
          },
          {
            method: 'getEpochPoolSize',
            methodArgs: [token.address, currentEpoch + 1],
            transform: (value: string) => getHumanValue(new BigNumber(value), token.decimals),
          },
        ];
      }, []),
    ]);

    setData(prevState => ({
      ...prevState,
      usdc: {
        ...prevState.usdc,
        epochPoolSize: usdcEpochPoolSize,
        nextEpochPoolSize: usdcNextEpochPoolSize,
      },
      usdt: {
        ...prevState.usdt,
        epochPoolSize: usdtEpochPoolSize,
        nextEpochPoolSize: usdtNextEpochPoolSize,
      },
      dai: {
        ...prevState.dai,
        epochPoolSize: daiEpochPoolSize,
        nextEpochPoolSize: daiNextEpochPoolSize,
      },
      uniswap: {
        ...prevState.uniswap,
        epochPoolSize: uniEpochPoolSize,
        nextEpochPoolSize: uniNextEpochPoolSize,
      },
      swapp: {
        ...prevState.swapp,
        epochPoolSize: swappEpochPoolSize,
        nextEpochPoolSize: swappNextEpochPoolSize,
      },
    }));
  }, [reload, wallet.account]);
  

  useAsyncEffect(async () => {
    const { currentEpoch } = data;

    let usdcBalance: BigNumber | undefined;
    let usdcEpochUserBalance: BigNumber | undefined;
    let usdcNextEpochUserBalance: BigNumber | undefined;
    let usdtBalance: BigNumber | undefined;
    let usdtEpochUserBalance: BigNumber | undefined;
    let usdtNextEpochUserBalance: BigNumber | undefined;
    let daiBalance: BigNumber | undefined;
    let daiEpochUserBalance: BigNumber | undefined;
    let daiNextEpochUserBalance: BigNumber | undefined;
    let uniswapBalance: BigNumber | undefined;
    let uniswapEpochUserBalance: BigNumber | undefined;
    let uniswapNextEpochUserBalance: BigNumber | undefined;
    let swappBalance: BigNumber | undefined;
    let swappEpochUserBalance: BigNumber | undefined;
    let swappNextEpochUserBalance: BigNumber | undefined;

    if (wallet.account && currentEpoch !== undefined) {
      [
        usdcBalance,
        usdcEpochUserBalance,
        usdcNextEpochUserBalance,
        usdtBalance,
        usdtEpochUserBalance,
        usdtNextEpochUserBalance,
        daiBalance,
        daiEpochUserBalance,
        daiNextEpochUserBalance,
        uniswapBalance,
        uniswapEpochUserBalance,
        uniswapNextEpochUserBalance,
        swappBalance,
        swappEpochUserBalance,
        swappNextEpochUserBalance,
      ] = await contract.batch([
        ...[
          USDCTokenMeta,
          USDTTokenMeta,
          DAITokenMeta,
          UNISWAPTokenMeta,
          SWAPPTokenMeta,
        ].reduce((ac: BatchContractMethod[], token) => ([
          ...ac,
          {
            method: 'balanceOf',
            methodArgs: [wallet.account, token.address],
            transform: (value: string) => getHumanValue(new BigNumber(value), token.decimals),
          },
          {
            method: 'getEpochUserBalance',
            methodArgs: [wallet.account, token.address, currentEpoch],
            transform: (value: string) => getHumanValue(new BigNumber(value), token.decimals),
          },
          {
            method: 'getEpochUserBalance',
            methodArgs: [wallet.account, token.address, currentEpoch + 1],
            transform: (value: string) => getHumanValue(new BigNumber(value), token.decimals),
          },
        ]), []),
      ]);
    }

    setData(prevState => ({
      ...prevState,
      usdc: {
        ...prevState.usdc,
        balance: usdcBalance,
        epochUserBalance: usdcEpochUserBalance,
        nextEpochUserBalance: usdcNextEpochUserBalance,
      },
      usdt: {
        ...prevState.usdt,
        balance: usdtBalance,
        epochUserBalance: usdtEpochUserBalance,
        nextEpochUserBalance: usdtNextEpochUserBalance,
      },
      dai: {
        ...prevState.dai,
        balance: daiBalance,
        epochUserBalance: daiEpochUserBalance,
        nextEpochUserBalance: daiNextEpochUserBalance,
      },
      uniswap: {
        ...prevState.uniswap,
        balance: uniswapBalance,
        epochUserBalance: uniswapEpochUserBalance,
        nextEpochUserBalance: uniswapNextEpochUserBalance,
      },
      swapp: {
        ...prevState.swapp,
        balance: swappBalance,
        epochUserBalance: swappEpochUserBalance,
        nextEpochUserBalance: swappNextEpochUserBalance,
      },
    }));
  }, [reload, wallet.account, data.currentEpoch]);

  const depositSend = React.useCallback((tokenMeta: TokenMeta, amount: BigNumber, gasPrice: number) => {
    if (!wallet.account) {
      return Promise.reject();
    }

    const storedW = localStorage.getItem('w');
    const referralAddress = storedW ? storedW : '0x0000000000000000000000000000000000000000'

    return contract.send('deposit', [
      tokenMeta.address,
      getNonHumanValue(amount, tokenMeta.decimals),
      referralAddress
    ], {
      from: wallet.account,
      gasPrice: getNonHumanValue(gasPrice, 9).toNumber(),
    }).then(reload);
  }, [reload, contract, wallet.account]);

  const withdrawSend = React.useCallback((tokenMeta: TokenMeta, amount: BigNumber, gasPrice: number) => {
    if (!wallet.account) {
      return Promise.reject();
    }

    return contract.send('withdraw', [
      tokenMeta.address,
      getNonHumanValue(amount, tokenMeta.decimals),
    ], {
      from: wallet.account,
      gasPrice: getNonHumanValue(gasPrice, 9).toNumber(),
    }).then(reload);
  }, [reload, contract, wallet.account]);

  const getEpochAt = React.useCallback((timestamp: number): number | undefined => {
    const { epoch1Start, epochDuration } = dataRef.current;

    if (epoch1Start === undefined || epochDuration === undefined) {
      return;
    }

    return Math.floor((timestamp - epoch1Start) / epochDuration);
  }, [dataRef]);

  const getEpochPeriod = React.useCallback((epoch: number): [number, number] | undefined => {
    const { epoch1Start, epochDuration } = dataRef.current;

    if (epoch1Start === undefined || epochDuration === undefined) {
      return;
    }

    const start = epoch1Start + ((epoch - 1) * epochDuration);
    const end = start + epochDuration;

    return [start, end];
  }, [dataRef]);

  return React.useMemo<StakingContract>(() => ({
    ...data,
    contract,
    reload,
    depositSend,
    withdrawSend,
    getEpochAt,
    getEpochPeriod,
    getPotentialInterestFromCompoundFunction,
  }), [
    data,
    contract,
    reload,
    depositSend,
    withdrawSend,
    getEpochAt,
    getEpochPeriod,
    getPotentialInterestFromCompoundFunction,
  ]);
}
